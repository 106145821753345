import { ShippingMethod } from '@vue-storefront/commercetools-api';

type ShippingMethodType = 'shop' | 'home' | 'packstation';

/* eslint-disable no-unused-vars, no-shadow */
export type ShippingMethodInfo = ShippingMethod & {
    amount: number,
    description: string,
    isDefault: boolean;
    id: string;
    orderHint: number;
    type: ShippingMethodType,
    displayName: string,
    thresholdToActivate?: number,
    freeAbove: number,
    currency: number,
};

export enum ShippingType {
    SHIPPING_SAME = 'shipping_same',
    SHIPPING_ALTERNATE = 'shipping_alternate',
    CLICK_COLLECT = 'click_collect',
    CLICK_COLLECT_POPUP = 'click_collect_popup',
    PACKSTATION = 'packstation',
}
