import { useContext } from '@nuxtjs/composition-api';

interface Content {
  pageLink?: any,
  showCtaAsPdf?: boolean,
  pdfLinkInDam?: any,
  slides?: Content[],
}

const useTeaser = () => {
  const { $config } = useContext();

  const transformTeaserData = <T extends Content>(data: T) => {
    const newData = { ...data };
    newData.slides = newData.slides?.map((slide) => {
      if (!slide.showCtaAsPdf || !slide.pdfLinkInDam) {
        return slide;
      }

      return {
        ...slide,
        pdfLinkInDam: {
          ...slide.pdfLinkInDam,
          '@path': `${$config.mgnlImagesPath}${slide.pdfLinkInDam['@link']}`,
        },
        pageLink: slide.pdfLinkInDam,
      };
    });

    if (newData.showCtaAsPdf && newData.pdfLinkInDam) {
      newData.pdfLinkInDam['@path'] = `${$config.mgnlImagesPath}${newData.pdfLinkInDam['@link']}`;
      newData.pageLink = newData.pdfLinkInDam;
    }

    return newData;
  };

  return {
    transformTeaserData,
  };
};

export default useTeaser;
