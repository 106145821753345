
import Vue from 'vue';
import { computed, toRef } from '@nuxtjs/composition-api';
import { WrapperProps, FormBuilder } from './Form.model';
import KikForm from './Form.vue';

export default Vue.extend({
  name: 'FormWrapper',
  components: {
    KikForm,
  },
  props: WrapperProps,
  setup(props) {
    const textArea = toRef(props, 'form1');
    const titleArea = toRef(props, 'form2');
    const termsAndConditonsArea = toRef(props, 'form3');

    const mgnlBuild = FormBuilder.build(props);

    return {
      titleArea: computed(() => titleArea.value),
      textArea: computed(() => textArea.value),
      termsAndConditonsArea: computed(() => termsAndConditonsArea.value),
      mgnlBuild,
    };
  },
});
