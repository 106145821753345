/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';

import { State } from './state';

export const SET_FLAGS = 'setFeatureFlags';

const mutations: MutationTree<State> = {
  [SET_FLAGS]: (state, featureFlags: string[]) => {
    state.flags = featureFlags;
    state.lastFetchAt = Date.now();
  },
};

export default mutations;
