import { Ref, computed } from '@nuxtjs/composition-api';
import debounce from 'lodash-es/debounce';
import { sharedRef } from '@vue-storefront/core';

interface State {
  isWishlistSidebarOpen: boolean;
  isLoginModalOpen: boolean;
  isResetPasswordModalOpen: boolean;
  isCategoryGridView: boolean;
  isFilterSidebarOpen: boolean;
  isMobileMenuOpen: boolean;
  isEfficiencySidebarOpen: boolean;
  isForgotPasswordView: boolean;
  isRegisterCustomerView: boolean;
  isDesktopNavigationOpen: boolean;
  isRedirectDialogOpen: boolean;
  showAddToCartNotification: boolean;
}

const useUiState = () => {
  const state: Ref<State> = sharedRef({
    isWishlistSidebarOpen: false,
    isLoginModalOpen: false,
    isResetPasswordModalOpen: false,
    isCategoryGridView: true,
    isFilterSidebarOpen: false,
    isMobileMenuOpen: false,
    isEfficiencySidebarOpen: false,
    isForgotPasswordView: false,
    isRegisterCustomerView: false,
    isDesktopNavigationOpen: false,
    isRedirectDialogOpen: false,
    showAddToCartNotification: false,
  }, 'ui-state');

  const showRedirectModal = () => {
    state.value.isRedirectDialogOpen = true;
  };
  const hideRedirectModal = () => {
    state.value.isRedirectDialogOpen = false;
  };

  const toggleMobileMenu = () => {
    state.value.isMobileMenuOpen = !state.value.isMobileMenuOpen;
  };

  const showMobileMenu = () => {
    state.value.isMobileMenuOpen = true;
  };

  const toggleWishlistSidebar = () => {
    if (state.value.isMobileMenuOpen) {
      toggleMobileMenu();
    }

    state.value.isWishlistSidebarOpen = !state.value.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.value.isMobileMenuOpen) {
      toggleMobileMenu();
    }

    state.value.isLoginModalOpen = !state.value.isLoginModalOpen;
  };

  const showLoginModal = () => {
    if (state.value.isMobileMenuOpen) {
      showMobileMenu();
    }

    state.value.isLoginModalOpen = true;
  };

  const toggleResetPasswordModal = () => {
    if (state.value.isMobileMenuOpen) {
      toggleMobileMenu();
    }

    state.value.isResetPasswordModalOpen = !state.value.isResetPasswordModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.value.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.value.isCategoryGridView = false;
  };

  const toggleFilterSidebar = () => {
    state.value.isFilterSidebarOpen = !state.value.isFilterSidebarOpen;
  };

  const toggleEfficiencySidebar = () => {
    if (state.value.isMobileMenuOpen) {
      toggleMobileMenu();
    }

    state.value.isEfficiencySidebarOpen = !state.value.isEfficiencySidebarOpen;
  };

  const setForgotPasswordView = (val: boolean) => {
    state.value.isForgotPasswordView = val;
  };

  const setDesktopNavigationOpen = debounce((status) => {
    state.value.isDesktopNavigationOpen = status;
  }, 100);

  return {
    isWishlistSidebarOpen: computed(() => state.value.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.value.isLoginModalOpen),
    isResetPasswordModalOpen: computed(() => state.value.isResetPasswordModalOpen),
    isCategoryGridView: computed(() => state.value.isCategoryGridView),
    isFilterSidebarOpen: computed(() => state.value.isFilterSidebarOpen),
    isMobileMenuOpen: computed(() => state.value.isMobileMenuOpen),
    isEfficiencySidebarOpen: computed(() => state.value.isEfficiencySidebarOpen),
    isForgotPasswordView: computed(() => state.value.isForgotPasswordView),
    isDesktopNavigationOpen: computed(() => state.value.isDesktopNavigationOpen),
    isRedirectDialogOpen: computed(() => state.value.isRedirectDialogOpen),
    showAddToCartNotification: computed(() => state.value.showAddToCartNotification),

    showRedirectModal,
    hideRedirectModal,
    toggleWishlistSidebar,
    showLoginModal,
    toggleLoginModal,
    toggleResetPasswordModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    toggleFilterSidebar,
    toggleMobileMenu,
    toggleEfficiencySidebar,
    setForgotPasswordView,
    setDesktopNavigationOpen,
  };
};

export default useUiState;
