import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { MetaInfo } from 'vue-meta/types';

const CUX_ID = '77265177.0.20953900.1700486303.655b5c9f332a0';

export default defineNuxtPlugin(async ({ app, $flags }) => {
  if (!$flags.has('cux')) {
    return;
  }

  window._cuxSettings = window._cuxSettings || { id: CUX_ID };

  (app.head as MetaInfo).script.push({
    type: 'text/javascript',
    src: 'https://dc.cux.io/analyzer.js',
    async: true,
  });

  window._cux_q = [];
  window._cux = window._cux || { send(n, p) { window._cux_q.push({ n, p, t: new Date() }); } };
});
