import { extend } from 'vee-validate';
import PhoneNumber from 'awesome-phonenumber';
import {
  required,
  digits,
  confirmed,
  email,
  numeric,
} from 'vee-validate/dist/rules';
import zipCodes from './zipCodesValidationRules.json';
import { calculateAge } from '~/helpers/utils';

export default (i18n, config) => {
  extend('phone', {
    message: i18n.t('This is not a valid phone number'),
    validate(value) {
      const phone = new PhoneNumber(value);
      return phone.isValid();
    },
  });
  extend('required', {
    ...required,
    message: i18n.t('This field is required.'),
  });
  extend('length', {
    params: ['length'],
    validate: (value, { length }) => value.length === parseInt(length, 10),
    message: (_, { length }) => i18n.t('The field should have exactly X characters.', { length }),
  });
  extend('minmax', {
    params: ['min', 'max'],
    validate: (value, { min, max }) => value.toString().length >= parseInt(min, 10) && value.toString().length <= parseInt(max, 10),
    message: (_, { min, max }) => i18n.t('The field should have between {min} and {max} characters.', { min, max }),
  });
  extend('max', {
    params: ['max'],
    validate: (value, { max }) => value.length <= parseInt(max, 10),
    message: (_, { max }) => i18n.t('The field should not contain more than {max} characters.', { max }),
  });
  extend('digits', {
    ...digits,
    message: i18n.t('Please provide a valid phone number.'),
  });
  extend('confirmed', {
    ...confirmed,
    message: i18n.t('The field does not match.'),
  });
  extend('email', {
    ...email,
    validate: (value) => {
      const hasSpecialChars = /[^\w\d@!#$%&'*+-/=?^_`.{|}~]/.test(value);
      return email.validate(value) && !hasSpecialChars;
    },
    message: i18n.t('The e-mail address is incorrect.'),
  });
  extend('numeric', {
    ...numeric,
    message: i18n.t('This field should only contain numbers.'),
  });
  extend('password', {
    validate: (value) => {
      const numbersInPassword = value.match(/\d/g);
      const doesntHaveAtLeastOneNumber = !numbersInPassword || numbersInPassword.length === 0;

      if (value.length < 8 || doesntHaveAtLeastOneNumber) {
        return false;
      }

      return true;
    },
    message: i18n.t('passwordNotValid'),
  });
  extend('dateUntilToday', {
    validate: (value) => {
      const selectedDate = new Date(value);
      const now = new Date();

      return selectedDate.valueOf() < now.valueOf();
    },
    message: i18n.t('Date should not be in the future.'),
  });
  extend('dateFormat', {
    validate: (value) => /\d{4}-\d{2}-\d{2}/.test(value),
    message: i18n.t('Date should be in the format YYYY-MM-DD.'),
  });
  extend('validBirthDate', {
    validate: (value) => {
      const selectedDate = new Date(value);
      const now = new Date();
      const lessThen100Y = selectedDate.getFullYear() >= now.getFullYear() - 100;
      return selectedDate.valueOf() < now.valueOf() && lessThen100Y;
    },
    message: i18n.t('Please provide a valid birth date'),
  });
  extend('atAgeLimit', {
    params: ['ageLimit'],
    validate: (value, { ageLimit }) => {
      const max = parseInt(ageLimit, 10);
      const selectedDate = new Date(value);
      return calculateAge(selectedDate) >= max;
    },
    message: (_, { ageLimit }) => i18n.t('Sorry! You should have at least {ageLimit} years to continue with this operation', { ageLimit }),
  });

  extend('validZipCode', {
    params: ['country'],
    validate: (value, { country = '' }) => {
      if (!country || !zipCodes[country.toUpperCase()]) {
        return false;
      }

      const zipCodeRegex = RegExp(zipCodes[country.toUpperCase()]);

      return zipCodeRegex.test(value);
    },
    message(_, { country = '' }) {
      const countryName = config.i18n.countries.find(({ name }) => name === country)?.label;

      if (!countryName) {
        return i18n.t('Postal code format is not correct');
      }

      return i18n.t('Postal code format is not correct for {country}', { country: i18n.t(countryName) });
    },
  });

  extend('noEmojis', {
    validate: (value) => !(/\p{Extended_Pictographic}/u.test(value)),
    message: i18n.t('Emojis are not allowed'),
  });

  const vatCountryConfigs = {
    AT: {
      amountMinDigits: 9,
      allowedSeparators: ['-'],
    },
    BG: {
      amountMinDigits: 9,
      allowedSeparators: ['-'],
    },
    CZ: {
      amountMinDigits: 8,
      allowedSeparators: ['-'],
    },
    DE: {
      amountMinDigits: 9,
      allowedSeparators: ['-'],
    },
    ES: {
      amountMinDigits: 9,
      allowedSeparators: ['-'],
    },
    HR: {
      amountMinDigits: 11,
      allowedSeparators: ['-'],
    },
    HU: {
      amountMinDigits: 8,
      allowedSeparators: ['-'],
    },
    IT: {
      amountMinDigits: 11,
      allowedSeparators: ['-'],
    },
    NL: {
      amountMinDigits: 12,
      allowedSeparators: ['-'],
    },
    PL: {
      amountMinDigits: 10,
      allowedSeparators: ['-'],
    },
    PT: {
      amountMinDigits: 9,
      allowedSeparators: ['-'],
    },
    RO: {
      amountMinDigits: 9,
      allowedSeparators: ['-'],
    },
    SI: {
      amountMinDigits: 8,
      allowedSeparators: ['-'],
    },
    SK: {
      amountMinDigits: 10,
      allowedSeparators: ['-'],
    },
  };
  extend('vatFormat', {
    params: ['country'],
    validate: (insertedVAT, { country }) => {
      const { allowedSeparators, amountMinDigits } = vatCountryConfigs[country.toUpperCase()];
      // check for non digits or non allowed separators
      const regex = new RegExp(`[^\\d${allowedSeparators.join('')}]`, 'g');

      const insertedDigits = insertedVAT.replaceAll(/[^\d]/g, '');
      return regex.exec(insertedVAT) === null && amountMinDigits === insertedDigits.length;
    },
    message: (_, { country, _value_: insertedVAT }) => {
      const { allowedSeparators, amountMinDigits } = vatCountryConfigs[country.toUpperCase()];

      const insertedDigits = insertedVAT.replaceAll(/[^\d]/g, '').length;
      return i18n.t('VAT format is invalid', { digits: amountMinDigits, insertedDigits, nonDigitsAllowed: allowedSeparators.join('') });
    },
  });
};
