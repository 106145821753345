import { Ref } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';

export const useLastVisitedPage = () => {
  const lastVisitedPage: Ref<string> = sharedRef(null, 'last-visited-page');

  const addPage = (path: string) => {
    lastVisitedPage.value = path;
  };

  return {
    addPage,
    lastVisitedPage,
  };
};
