import { ref, useRoute } from '@nuxtjs/composition-api';

import { useAppLocale } from '~/composables/custom/useAppLocale';
import { useCart } from '~/composables/custom/useCart/useCart';
import { useUsercentrics } from '~/composables/custom/useUsercentrics/useUsercentrics';

import { cartGetters } from '~/composables/getters';

type TrackEvent =
  | 'page'
  | 'add_to_cart'
  | 'productView'
  | 'basket'
  | 'sale'
  | 'currentBasket'
  | 'open_login_layer'
  | 'forgot_password'
  | 'forgot_password_resend'
  | 'login'
  | 'start_registration'
  | 'start_checkout'
  | 'select_shipping'
  | 'select_payment'
  | 'error'
  | 'open_basket';

interface TrackingProduct {
  item_id: string;
  currency: string;
  index: number;
  item_category1?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_color: string;
  item_group: string;
  item_ekb: string;
  item_name: string;
  item_product: string; // product key
  price: number;
  quantity?: number;
}

const pageTypeMap = {
  category: 'category',
  home: 'home',
  product: 'detail',
  custom: 'static',
  payment: 'checkout',
  billing: 'checkout',
  Checkout: 'checkout',
  'Checkout-ThankYou': 'confirmation',
  'thank-you': 'confirmation',
  'my-account': 'account',
};

// returns trbo tracking page-category according to matching route name
const pageType = ({ name = '', query }): string => {
  const [baseName] = name.split('-');

  const type = pageTypeMap[name] || pageTypeMap[baseName];

  if (name === 'category' && query?.search) {
    return 'search';
  }

  return type || 'other';
};

export const useTrbo = () => {
  const route = useRoute();
  const { cart, refresh } = useCart();
  const { currencyCode: currency } = useAppLocale();
  const { isTrboConsentGiven } = useUsercentrics();

  const trboInitialized = ref(false);

  const delayedTrboTracking = (action: 'trboPageView' | 'trboProductView' | 'trboCurrentBasket', data?: string & TrackingProduct) => {
    if (trboInitialized.value) {
      trboTrackingFn[action](data);
    } else {
      setTimeout(() => {
        trboInitialized.value = true;
        trboTrackingFn[action](data);
      }, 500);
    }
  };

  // Called after the consent for trbo-tracking was given
  const initTrbo = () => {
    if (!isTrboConsentGiven() || route.value.name === 'product') {
      return;
    }
    delayedTrboTracking('trboCurrentBasket');
    delayedTrboTracking('trboPageView');
  };

  const trboEnabled = () => {
    if (!isTrboConsentGiven()) {
      return false;
    }

    const isTrboEnabled = typeof window._trbo !== 'undefined' && typeof window._trboq !== 'undefined' && window._trbo?.app?.reloadApi && isTrboConsentGiven();

    return isTrboEnabled;
  };

  const trboTracking = (event: TrackEvent, data: any): boolean => {
    if (!trboEnabled()) {
      return false;
    }

    window._trboq.push([event, {
      ...data,
      ...(data.items ? { items: data.items.map(trboTrackingItem) } : {}),
    }]);

    if (data?._type === 'basket') {
      trboCurrentBasket();
    }

    return true;
  };

  const trboTrackingItem = (product: TrackingProduct) => ({
    product_id: product?.item_id,
    name: product?.item_name,
    quantity: product?.quantity,
    price: Number.parseFloat((product?.price || 0).toFixed(2)),
  });

  const trboPageView = (forcePageType?: string) => {
    const type = forcePageType || pageType({ name: route.value?.name ?? '', query: route.value?.query });
    if (trboTracking('page', { type })) {
      window._trbo.app.reloadApi(true, true);
    }
  };

  const trboProductView = (product: TrackingProduct) => {
    const products = [trboTrackingItem(product)];
    trboTracking('productView', { products });
  };

  const trboCurrentBasket = () => {
    const productKeys = cart.value?.lineItems.map(({ productKey }: any) => productKey);

    trboTracking('currentBasket', {
      product_ids: productKeys,
      voucher: cartGetters.getCoupons(cart.value)?.length > 0,
      value: cartGetters.getTotals(cart.value)?.subtotal || 0,
    });
  };

  const trboAddToCart = async (product: TrackingProduct, quantity: number) => {
    const products = [
      trboTrackingItem({
        ...product,
        quantity,
      }),
    ];

    await refresh();

    trboTracking('basket', {
      value: products.reduce((acc, { price, quantity: qty = 0 }) => acc + (qty * price), 0),
      currency,
      products,
    });
  };

  const trboTrackingFn = {
    trboPageView,
    trboProductView,
    trboCurrentBasket,
  };

  return {
    initTrbo,
    trboEnabled,
    trboPageView,
    trboAddToCart,
    trboTracking,
    trboCurrentBasket,
    delayedTrboTracking,
  };
};
