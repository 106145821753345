/* eslint-disable import/no-extraneous-dependencies */
import { GetterTree } from 'vuex';

import { State } from './state';

const getters: GetterTree<State, State> = {
  getCategory: ({ category }) => category,
};

export default getters;
