/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';

import { type Category } from '~/composables/custom/useCategory';
import { State } from './state';

export const SET_CATEGORY = 'setCategory';

const mutations: MutationTree<State> = {
  [SET_CATEGORY]: (state, category: Category) => {
    state.category = category;
  },
};

export default mutations;
