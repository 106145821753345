import { sharedRef } from '@vue-storefront/core';
import { computed, Ref } from '@nuxtjs/composition-api';
import { useUser } from '@vue-storefront/commercetools';
import { useWishlist } from '~/composables/useWishlist';

const useMe = () => {
  const { user, isAuthenticated } = useUser();
  const { load: loadWishlist, clearWishlist } = useWishlist();

  const customerGroup: Ref<string | undefined> = sharedRef('', `useMe-customerGroup-${user.value?.id}`);
  const customerGroupKey: Ref<string | undefined> = sharedRef('', `useMe-customerGroupKey-${user.value?.id}`);
  const newRegistration: Ref<boolean> = sharedRef(false, `useMe-newRegistration-${user.value?.id}`);
  const loading: Ref<boolean> = sharedRef(false, `useMe-loading-${user.value?.id}`);
  const error = sharedRef(
    {
      load: undefined,
      save: undefined,
    },
    `useMe-error-${user.value?.id}`,
  );

  /**
   * Fetch current user customer group
   *
   * @returns customer group
   */
  const load = async () => {
    if (!isAuthenticated.value) {
      resetCustomerGroup();
      return undefined;
    }

    const { customerGroup: userCustomerGroup } = user.value || {};

    customerGroup.value = userCustomerGroup?.name;
    customerGroupKey.value = userCustomerGroup?.key;
    newRegistration.value = user.value?.createdAt ? new Date(user.value.createdAt).toLocaleDateString() === new Date().toLocaleDateString() : false;
    await loadWishlist();
    return customerGroup.value;
  };

  const resetCustomerGroup = () => {
    newRegistration.value = false;
    customerGroup.value = undefined;
    customerGroupKey.value = undefined;
    clearWishlist();
  };

  return {
    customerGroup: computed<string | undefined>(() => customerGroup.value),
    customerGroupKey: computed<string | undefined>(() => customerGroupKey.value),
    newRegistration,
    loading,
    error,
    resetCustomerGroup,
    load,
  };
};

export {
  useMe,
};
