import('vue2-datepicker/locale/de.js');
import('vue2-datepicker/locale/pl.js');
import('vue2-datepicker/locale/pt.js');
import('vue2-datepicker/locale/it.js');
import('vue2-datepicker/locale/es.js');
import('vue2-datepicker/locale/it.js');
import('vue2-datepicker/locale/bg.js');
import('vue2-datepicker/locale/hu.js');
import('vue2-datepicker/locale/hr.js');
import('vue2-datepicker/locale/sl.js');
import('vue2-datepicker/locale/sv.js');
import('vue2-datepicker/locale/nl.js');
import('vue2-datepicker/locale/ro.js');
