import { computed, ref } from '@nuxtjs/composition-api';
import { ProductProjectionSearchResult } from '@vue-storefront/commercetools-api';

import { Attributes } from '~/components/Product/types/product';
import useUiNotification from '~/composables/useUiNotification';
import { useBFF } from '~/composables/useBFF/useBFF';

export const useProductCard = (productId: string) => {
  const { $ct } = useBFF();
  const { send } = useUiNotification();

  const loading = ref(false);
  const error = ref<string>();
  const result = ref();

  const getProduct = async () => {
    loading.value = true;
    try {
      const { data, errors } = await $ct.getProducts<{ items: ProductProjectionSearchResult['results'] }>({
        products: [productId],
        limit: 1,
        attributeFilterNames: [
          Attributes.COLOR_CODE,
          Attributes.DETAIL_NAME,
          Attributes.COLOR,
          Attributes.SIZE,
          Attributes.SIZE_ORDER,
          Attributes.CAPACITY_QUANTITY,
          Attributes.CAPACITY_UOM,
          Attributes.DISCOUNT_SALE_VALUE,
        ],
      });

      if (errors?.length) {
        error.value = errors.join('. ');

        send({
          message: 'An error occured',
          type: 'danger',
        });
      }

      if (data?.items) {
        [result.value] = data.items;
      }
    } catch (e) {
      error.value = e.message;
    } finally {
      loading.value = false;
    }
  };

  return {
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    result: computed(() => result.value),

    getProduct,
  };
};
