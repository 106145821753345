/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { ActionTree } from 'vuex';
import { Logger } from '~/apiExtensions/utils';

import { State } from './state';
import { SET_FLAGS } from './mutations';

const actions: ActionTree<State, State> = {
  async fetch({ commit }) {
    const { $domain, $vsf } = this.app;

    try {
      const featureFlags = await $vsf.$ct.api.getFeatureFlags();

      if (featureFlags?.errors.length) {
        throw new Error();
      }

      const enabledDomainFeatureFlags: string[] = [];
      if ($domain.isKioskEnabled) {
        enabledDomainFeatureFlags.push('enabledKiosk');
      }

      if ($domain.isCatalogEnabled) {
        enabledDomainFeatureFlags.push('shop.catalog.enabled');
        featureFlags.data = featureFlags?.data.filter((ff) => ff !== 'shoppinglist.wishlist');
      }
      const flags = [...enabledDomainFeatureFlags, ...Array.isArray(featureFlags?.data) ? featureFlags.data : []];

      commit(SET_FLAGS, flags);
    } catch (err) {
      Logger.error('Failed loading feature flags', err);
    }
  },
};

export default actions;
