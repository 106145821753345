import { defineNuxtMiddleware } from '@nuxtjs/composition-api';

export default defineNuxtMiddleware(({ res, $config }) => {
  if (!process.server) {
    return;
  }

  if ($config.cache.noCache) {
    res.setHeader('Cache-Control', 'no-cache');
    return;
  }

  const defaultValues = {
    'max-age': $config.cache.maxAge,
    'max-s-age': $config.cache.maxSAge,
    'stale-when-revalidate': $config.cache.staleWhenRevalidate,
  };

  const cacheControlValue = Object.entries<string>({ ...defaultValues })
    .map(([key, value]) => `${key}=${value}`)
    .join(',');

  res.setHeader('Cache-Control', cacheControlValue);
});
