import { computed, ref } from '@nuxtjs/composition-api';

import useUiNotification from '~/composables/useUiNotification';
import { useBFF } from '~/composables/useBFF/useBFF';

export const useForm = () => {
  const { $ct } = useBFF();
  const { send } = useUiNotification();

  const loading = ref(false);
  const errors = ref(false);

  const sendEmail = async (form) => {
    loading.value = true;
    errors.value = false;
    try {
      const { errors: apiErrors } = await $ct.sendEmail(form);

      if (apiErrors?.length) {
        throw new Error();
      }

      send({
        message: 'Your message was submitted with success',
        type: 'success',
      });
    } catch {
      errors.value = true;
      send({
        message: 'An error occured',
        type: 'danger',
      });
      return;
    } finally {
      loading.value = false;
    }
  };

  return {
    sendEmail,
    loading: computed(() => loading.value),
    errors: computed(() => errors.value),
  };
};
