import { computed, ref, useContext } from '@nuxtjs/composition-api';

import useUiNotification from '~/composables/useUiNotification';
import { PaymentMethod } from '~/composables/custom/usePayment/usePayment';
import { useBFF } from '~/composables/useBFF/useBFF';

interface UnzerResponse {
  id?: string;
  isSuccess: boolean;
  isPending: boolean;
  isResumed: boolean;
  isError: boolean;
  url: string;
  timestamp: Date;
  errors: {
    code: string;
    merchantMessage: string;
    customerMessage: string;
  }[];
}

export const useUnzer = () => {
  const errors = ref({ token: undefined });
  const { $config, $domain } = useContext();
  const { $internal } = useBFF();
  const { send } = useUiNotification();

  const initializeUnzerPayment = async (paymentOption: Pick<PaymentMethod, 'method' | 'provider'>) => {
    errors.value.token = undefined;

    const { unzer, providers } = $config.payment;

    const { unzerClientId: clientId, unzerClientIdpEcom: clientIdEcom } = $domain;
    const unzerPaymentTypeUrl = unzer[paymentOption?.method];

    try {
      if (!unzerPaymentTypeUrl || !clientId) {
        throw new Error('Not possible to get payment types since mandatory configurations are missing');
      }

      const token = btoa(`${providers?.UNZER_ECOM === paymentOption?.provider ? clientIdEcom : clientId}:`);

      if (!token) {
        throw new Error('Error getting token for Unzer.');
      }

      const result = await fetch(unzerPaymentTypeUrl, {
        cache: 'no-cache',
        referrerPolicy: 'no-referrer',
        method: 'POST',
        headers: {
          Authorization: `Basic ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then<UnzerResponse>((data) => data.json());

      if (result?.isError) {
        throw new Error(result.errors.map((error) => `${error.code}: ${error.customerMessage}`).join('. '));
      }

      if (!result?.id) {
        throw new Error('Error getting resource.id from Unzer');
      }

      if (paymentOption?.method.includes('pay_later') && !result?.id.startsWith('p-piv')) {
        await $internal.logError(`initializeUnzerPayment :: ${paymentOption?.method} selected but ${result?.id} generated from unzer`, {
          clientId, clientIdEcom, paymentOption: { provider: paymentOption.provider, method: paymentOption.method }, unzerPaymentTypeUrl,
        });
      }

      return result?.id;
    } catch (error) {
      await $internal.logError(`initializeUnzerPayment :: ${error.message}`, {
        clientId, clientIdEcom, paymentOption: { provider: paymentOption.provider, method: paymentOption.method }, unzerPaymentTypeUrl,
      });
      errors.value.token = `Error getting unzer key for ${paymentOption?.method}`;
      send({
        message: 'somethingWentWrong',
        type: 'danger',
      });
    }
    return undefined;
  };

  return {
    initializeUnzerPayment,
    errors: computed(() => errors.value),
  };
};
