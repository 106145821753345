import { sharedRef } from '@vue-storefront/core';
import { useContext, useRoute } from '@nuxtjs/composition-api';
import VueI18nPhraseInContextEditor from 'vue-i18n-phrase-in-context-editor';

export const useInContextEditor = () => {
  const { i18n, $config } = useContext();
  const route = useRoute();

  const inContextEditor = sharedRef(undefined, 'in-context-editor');

  const init = () => {
    const { editorEnabled = false, projectId, accountId } = $config.phrase || {};

    const phraseEnabled = route.value.query?.phrase !== undefined && editorEnabled;
    if (inContextEditor.value || !phraseEnabled) {
      return;
    }
    // eslint-disable-next-line no-new
    inContextEditor.value = new VueI18nPhraseInContextEditor(i18n, {
      phraseEnabled,
      autoLowercase: false,
      projectId,
      accountId,
    });
  };

  return {
    init,
  };
};
