import { useUser as useUserCore } from '@vue-storefront/commercetools';
import { computed, ref, useContext } from '@nuxtjs/composition-api';
import { Address } from '@vue-storefront/commercetools-api';
import { v4 as uuidV4 } from 'uuid';

import useUiNotification from '~/composables/useUiNotification';
import { getErrorMessage } from '~/helpers/utils/getErrorMessage';
import { useCart } from '~/composables/custom/useCart/useCart';
import { useMe } from '~/composables/custom/useMe';
import { useAppLocale } from '~/composables/custom/useAppLocale';
import { useBFF } from '~/composables/useBFF/useBFF';

export const useUser = () => {
  const {
    load,
    loading,
    user,
    setUser,
    isAuthenticated,
    logout: logoutCore,
    error: coreErrors,
    changePassword,
  } = useUserCore();
  const { setCart } = useCart();
  const { resetCustomerGroup } = useMe();
  const { store, country } = useAppLocale();

  const { $ct } = useBFF();
  const { $domain } = useContext();

  const updating = ref(false);
  const error = ref({
    deleteAddress: undefined,
    updateAddress: undefined,
    saveAddress: undefined,
    saveBirthDate: undefined,
    registerUser: undefined,
    login: undefined,
    updateUser: undefined,
    deleteUser: undefined,
    save: undefined,
  });
  const { send } = useUiNotification();

  const deleteAddress = async ({ addressId }) => {
    if (!isAuthenticated.value || !addressId) {
      return;
    }
    try {
      updating.value = true;
      const { data, errors } = await $ct.removeAddress({ version: user.value.version, addressId });
      error.value.deleteAddress = errors?.join('');

      if (data?.updateMyCustomer) {
        setUser({ ...user.value, ...data?.updateMyCustomer });
      }

      if (error.value.deleteAddress) {
        send({
          icon: 'profile_fill',
          message: 'Sorry! It was not possible to delete your address. Please try again later.',
          type: 'danger',
        });

        return;
      }

      send({
        icon: 'profile_fill',
        message: 'Address successfully removed.',
        type: 'success',
      });
    } finally {
      updating.value = false;
    }
  };

  const getAddress = (address) => ({
    ...(address || {}),
    key: address.key || uuidV4(),
    country: address.country || country,
    email: address.email || user.value?.email,
  });

  const updateAddress = async ({ address, isDefault, type }: { address: any, type?: 'shipping' | 'billing', isDefault: boolean}): Promise<undefined | Address> => {
    if (!isAuthenticated.value || !address) {
      return undefined;
    }

    try {
      updating.value = true;

      const { data, errors } = await $ct.updateAddress({
        version: user.value.version, address: getAddress(address), isDefault, type,
      });
      error.value.updateAddress = errors?.join('');

      if (data) {
        setUser({ ...user.value, ...data });
      }

      if (error.value.updateAddress) {
        send({
          icon: 'profile_fill',
          message: 'Sorry! It was not possible to update your address. Please try again later.',
          type: 'danger',
        });

        return undefined;
      }

      send({
        icon: 'profile_fill',
        message: 'Address successfully updated.',
        type: 'info',
      });
    } finally {
      updating.value = false;
    }
    return address;
  };

  const saveAddress = async ({ address, type, isDefault }: { address: any, type?: 'shipping' | 'billing', isDefault?: boolean}): Promise<undefined | Address> => {
    let newAddress;
    if (!isAuthenticated.value || !address) {
      return undefined;
    }

    try {
      updating.value = true;

      const { data, errors } = await $ct.addUserAddress(user.value.version, getAddress(address));

      if (data) {
        setUser({ ...user.value, ...data });
      }

      if (errors?.length) {
        error.value.saveAddress = errors?.join();
        send({
          icon: 'profile_fill',
          message: 'Sorry! It was not possible to save your new address in your profile.',
          type: 'warning',
        });
        return undefined;
      }

      newAddress = user.value.addresses.find(({ key }) => key === address.key);

      if (!type) {
        return newAddress;
      }

      const params = { version: user.value.version, address: newAddress, isDefault };
      const {
        data: dataWithNewShipping,
        errors: shippingError,
      } = await (type === 'shipping' ? $ct.addUserShippingAddress(params) : $ct.addUserBillingAddress(params));

      if (dataWithNewShipping) {
        setUser({ ...user.value, ...dataWithNewShipping });
        send({
          icon: 'profile_fill',
          message: 'Address successfully added.',
          type: 'success',
        });
      }

      error.value.saveAddress = shippingError?.join();

      if (error.value.saveAddress) {
        send({
          icon: 'profile_fill',
          message: 'Sorry! It was not possible to save your new address in your profile.',
          type: 'warning',
        });
      }
    } finally {
      updating.value = false;
    }
    return newAddress;
  };

  const updateBirthDate = async ({ dateOfBirth }) => {
    error.value.saveBirthDate = undefined;
    updating.value = true;
    try {
      const { data, errors } = await $ct.updateBirthDate(
        user.value.version,
        dateOfBirth,
      );

      if (errors?.length) {
        error.value.saveBirthDate = errors;
      }

      if (data) {
        setUser({
          ...user.value,
          ...data,
        });
      }
    } finally {
      updating.value = false;
    }
  };

  const getRegistrationPayload = (form) => {
    const userBillingAddress = {
      id: undefined,
      key: form.key ?? uuidV4(),
      salutation: form.salutation,
      firstName: form.firstName,
      lastName: form.lastName,
      streetName: form.streetName,
      streetNumber: form.streetNumber,
      additionalStreetInfo: form.additionalStreetInfo,
      email: form.email ?? user.value.email,
      city: form.city,
      postalCode: form.postalCode,
      country: form.country,
      phone: form.phone,
      mobile: form.mobile,
      companyName: form.companyName,
      vatId: form.vatId,
    };

    return {
      store,
      salutation: form.salutation,
      title: form.title,
      firstName: form.firstName,
      lastName: form.lastName,
      dateOfBirth: form.dateOfBirth,
      email: form.email,
      password: form.password,
      addresses: [userBillingAddress],
      billingAddresses: [0],
    };
  };

  /**
   * Sign up user vie middleware
   *
   * @returns Newly created customer
   */
  const registerUser = async (form) => {
    const registrationForm = getRegistrationPayload(form);
    updating.value = true;
    error.value.login = undefined;
    error.value.registerUser = undefined;
    try {
      const { data, errors } = await $ct.registerUserViaMiddleware({ registrationForm });

      if (errors?.length) {
        send({
          icon: 'profile_fill',
          message: getErrorMessage(errors.length === 1 ? errors[0] : errors),
          type: 'danger',
        });
        error.value.registerUser = errors.join(',');
        return undefined;
      }

      if (!data?.customerNumber) {
        return data;
      }

      // Login the new registered user
      const loginUser = {
        email: registrationForm.email,
        password: registrationForm.password,
        activeCartSignInMode: 'UseAsNewActiveCustomerCart',
      };

      const { data: userData, errors: userErrors } = await $ct.customerSignMeIn(loginUser);

      setUser(userData?.user?.customer);
      await setCart(userData?.user?.cart);

      error.value.login = userErrors?.join();
      return data.customer;
    } finally {
      updating.value = false;
    }
  };

  const updateUser = async ({
    dateOfBirth, salutation, title, lastName,
  } : { dateOfBirth: string, salutation: string, title: string, lastName: string }): Promise<string> => {
    try {
      error.value.updateUser = undefined;

      if (!isAuthenticated.value || !lastName || ($domain.dateOfBirthRequired && !dateOfBirth)) {
        return Promise.reject();
      }
      updating.value = true;
      const { data, errors } = await $ct.updateUser(
        user.value.version,
        dateOfBirth,
        salutation,
        title,
        lastName,
      );

      if (errors?.length) {
        error.value.updateUser = errors;
      }

      if (data) {
        setUser({ ...user.value, ...data });
      }

      return data;
    } finally {
      updating.value = false;
    }
  };

  const deleteProfile = async (id) => {
    if (!isAuthenticated.value) {
      return;
    }
    error.value.deleteUser = undefined;

    const { errors } = await $ct.deleteUserProfile({ id });

    if (errors?.length) {
      error.value.deleteUser = errors?.join(',');
      send({
        icon: 'profile_fill',
        message: 'Sorry! It was not possible to delete your user. Please try again later.',
        type: 'danger',
      });
    }
  };

  const logout = async () => {
    await logoutCore();

    resetCustomerGroup();

    await load();
  };

  return {
    load,
    loading,
    user,
    setUser,
    deleteProfile,
    deleteAddress,
    updateAddress,
    error: computed(() => ({ ...coreErrors.value, ...error.value })),
    updating: computed(() => updating.value),
    saveAddress,
    isAuthenticated,
    updateBirthDate,
    logout,
    registerUser,
    updateUser,
    changePassword,
  };
};
