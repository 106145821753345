import { Order } from '@vue-storefront/commercetools-api';
import { AgnosticOrderStatus } from '@vue-storefront/core';

import { orderGetters } from '~/composables/getters';

const getFirstDeliveryDate = (order: Order) => {
  if (!order?.shippingInfo?.deliveries) {
    return null;
  }

  const earliestDate = order.shippingInfo.deliveries.reduce((earliest, { createdAt }) => {
    const dateToCheck = order.completedAt ?? createdAt;
    return Date.parse(dateToCheck) <= Date.parse(earliest.toString()) ? dateToCheck : earliest;
  }, new Date());

  return earliestDate ? new Date(earliestDate) : null;
};

const day = 60 * 60 * 24 * 1000;

export default {
  isCancelled: (orderReturn): boolean => !!orderReturn?.items?.[0].custom?.customFieldsRaw?.find(({ name, value }) => name === 'cancelled' && value),
  isCancellable: (orderReturn): boolean => {
    if (!orderReturn?.items?.length) {
      return false;
    }

    const { shipmentState, paymentState } = orderReturn.items[0];
    return shipmentState === 'Returned' && paymentState === 'Initial';
  },
  isReturnable: (myOrder: Order, maxAllowedDaysToReturn: number) => {
    const firstDeliveryDate = getFirstDeliveryDate(myOrder);

    if (!firstDeliveryDate) {
      return false;
    }

    const isCompleted = orderGetters.getStatus(myOrder) === AgnosticOrderStatus.Complete;
    const dateLimit = new Date(Date.now() - maxAllowedDaysToReturn * day);
    const isWithinReturnPeriod = firstDeliveryDate >= dateLimit;

    return isCompleted && isWithinReturnPeriod;
  },
};
