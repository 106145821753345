export interface UsercentricService {
  categorySlug: string,
  consent: {
    status: boolean,
  },
  name: string,
}

type ConsentType = 'trbo' | 'functional' | 'marketing' | 'essential' | 'pers-basket';

export const useUsercentrics = () => {
  const serviceConsentGiven = (consentType: ConsentType) => {
    if (!window.UC_UI) {
      return false;
    }

    const services: UsercentricService[] = window.UC_UI.getServicesBaseInfo();
    return services.some(({ categorySlug, consent, name }) => ((categorySlug === consentType || name === consentType) && consent.status));
  };

  const isTrboConsentGiven = () => serviceConsentGiven('trbo');
  const isFunctionalConsentGiven = () => serviceConsentGiven('functional');
  const isMarketingConsentGiven = () => serviceConsentGiven('marketing');
  const isEssentialConsentGiven = () => serviceConsentGiven('essential');
  const isPersistBasketConsentGiven = () => serviceConsentGiven('pers-basket');

  return {
    isTrboConsentGiven,
    isFunctionalConsentGiven,
    isMarketingConsentGiven,
    isEssentialConsentGiven,
    isPersistBasketConsentGiven,
  };
};
