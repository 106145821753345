<template>
  <SfInput
    :label="label"
    :required="isRequired"
  />
</template>

<script>
import { SfInput } from '@storefront-ui/vue';

export default {
  name: 'MagnoliaInput',
  components: {
    SfInput,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    fieldId: {
      type: String,
      required: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
