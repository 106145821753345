import { useContext } from '@nuxtjs/composition-api';

interface Options {
  fallbackLocale?: string;
}

export const useAppLocale = ({ fallbackLocale = 'de-DE' }: Options = { fallbackLocale: 'de-DE' }) => {
  const { i18n, $domain, $config } = useContext();
  const locale: string = $domain.locales[0] || i18n.locale || fallbackLocale;
  const country: string = $domain.countryCode?.toUpperCase();
  const currencyCode: string = $domain.currency?.toUpperCase();
  const [lang] = locale.split('-'); // de-DE -> lang=de | country=DE

  const isoCode: string = $config.i18n.countries.find((entry) => entry.name === country)?.isoCode; // DEU

  return {
    locale,
    lang,
    country,
    isoCode,
    store: $domain.store,
    currencyCode,
  };
};
