import { sharedRef } from '@vue-storefront/core';
import { Ref, computed, ref } from '@nuxtjs/composition-api';
import { Address } from '@vue-storefront/commercetools-api';

import useUiNotification, { UiNotification } from '~/composables/useUiNotification';
import { BFFResponse } from '~/types/BFFResponse';
import { useUser } from '~/composables/custom/useUser/useUser';
import { useBFF } from '~/composables/useBFF/useBFF';

export const useLoyalty = (id = '') => {
  const { $ct } = useBFF();
  const { send } = useUiNotification();
  const { load } = useUser();

  const result: Ref<{}> = sharedRef({}, `useLoyalty-${id}`);
  const address: Ref<Address | null> = sharedRef({}, 'useLoyalty');
  const loading: Ref<boolean> = sharedRef(false, `useLoyalty-loading-${id}`);
  const registrationError: Ref<boolean> = sharedRef(false, `useLoyalty-registrationError-${id}`);
  const syncError: Ref<boolean> = sharedRef(false, `useLoyalty-syncError-${id}`);

  const transactionsCount = ref(0);
  const isTransactionsCountVisible = ref(true);
  const loadingTransactionsCount = ref(false);

  const register = async (userForm) => {
    loading.value = true;
    registrationError.value = false;

    try {
      const { data, errors }: BFFResponse = await $ct.applyLoyaltyMembership(userForm);
      if (errors.length) {
        registrationError.value = true;
        return undefined;
      }

      result.value = data;
      address.value = data?.addressInCRM || {};
      return data;
    } catch (err) {
      registrationError.value = true;
    } finally {
      loading.value = false;
      if (registrationError.value) {
        send({
          message: 'Register unsuccessful. Please contact customer service.',
          type: 'danger',
        } as UiNotification);
      } else {
        await load();
      }
    }

    return undefined;
  };

  const unregister = async () => {
    loading.value = true;
    registrationError.value = false;

    try {
      const { data, errors } = await $ct.unregisterLoyaltyMembership();
      if (errors.length) {
        registrationError.value = true;
        return undefined;
      }
      result.value = data;
    } catch (err) {
      registrationError.value = true;
    } finally {
      loading.value = false;
      if (registrationError.value) {
        send({
          message: 'Unregister unsuccessful. Please contact customer service.',
          type: 'danger',
        } as UiNotification);
      } else {
        await load();
      }
    }

    return result.value;
  };

  const synchronize = async () => {
    syncError.value = false;
    try {
      const { data, errors } = await $ct.synchronizeUserWithCRM();
      if (errors.length) {
        syncError.value = true;
        return undefined;
      }
      result.value = data;
    } catch (err) {
      syncError.value = true;
    }

    return result.value;
  };

  const getCRMAddress = async () => {
    registrationError.value = false;
    const { data, errors } = await $ct.getAddressFromCRM();

    address.value = data?.addressInCRM;

    if (errors.length) {
      registrationError.value = true;
      send({
        message: 'Sorry! At the moment it\'s not possible to get this information. Please try again later',
        type: 'danger',
      });
      return undefined;
    }

    return address.value;
  };

  const loadTransactionCount = async () => {
    loadingTransactionsCount.value = true;
    isTransactionsCountVisible.value = true;
    try {
      const { data, errors } = await $ct.userTransactionCount<number>();

      if (errors.length) {
        isTransactionsCountVisible.value = false;

        return;
      }

      transactionsCount.value = data;
    } catch {
      isTransactionsCountVisible.value = false;
    } finally {
      loadingTransactionsCount.value = false;
    }
  };

  return {
    result: computed<typeof result.value>(() => result.value),
    loading: computed<boolean>(() => loading.value),
    error: computed<boolean>(() => registrationError.value),
    address: computed<typeof address.value>(() => address.value),
    loadTransactionCount,
    register,
    unregister,
    synchronize,
    getCRMAddress,
    syncError: computed(() => syncError.value),
    registrationError: computed(() => registrationError.value),
    transactionsCount: computed(() => transactionsCount.value),
    loadingTransactionsCount: computed(() => loadingTransactionsCount.value),
    isTransactionsCountVisible: computed(() => isTransactionsCountVisible.value),
  };
};
