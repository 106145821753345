const middleware = {}

middleware['cache-control'] = require('../middleware/cache-control.ts')
middleware['cache-control'] = middleware['cache-control'].default || middleware['cache-control']

middleware['cart-not-empty'] = require('../middleware/cart-not-empty.ts')
middleware['cart-not-empty'] = middleware['cart-not-empty'].default || middleware['cart-not-empty']

middleware['hrefLang'] = require('../middleware/hrefLang.ts')
middleware['hrefLang'] = middleware['hrefLang'].default || middleware['hrefLang']

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

export default middleware
