
// FIXME
// @ts-nocheck
import {
  defineComponent,
  onMounted,
  onUnmounted,
  useRouter,
  ref,
  useContext,
} from '@nuxtjs/composition-api';

import { useTracking } from '~/composables/custom/useTracking';
import { useAppLocale } from '~/composables/custom/useAppLocale';
import { useUsercentrics } from '~/composables/custom/useUsercentrics/useUsercentrics';

const UC_UI_INITIALIZED_EVENT = 'UC_UI_INITIALIZED';
const UC_UI_CMP_EVENT = 'UC_UI_CMP_EVENT';
const onUcPrivacyClick = 'onUcPrivacyClick';
const onUcImprintClick = 'onUcImprintClick';
const SAVE_TYPE = 'SAVE';
const ACCEPT_ALL_TYPE = 'ACCEPT_ALL';

const clearMarketingCookies = [
  'trbo_',
  '_trbo_ud', // SR-1397
];

const clearMarketingSessionStorage = [
  'tr_geo',
  '_trbo_ud', // SR-1397
];

const clearMarketingLocalStorage = [
  'tr_geo',
  '_trbo_ud', // SR-1397
];

const clearFunctionalCookies = [
  'wtsid',
  'wteid',
  'wtstp_',
];

const clearSessionStorage = (storageNames: string[] = []) => storageNames.forEach((item) => sessionStorage.removeItem(item));
const clearLocalStorage = (storageNames: string[] = []) => storageNames.forEach((item) => localStorage.removeItem(item));

export default defineComponent({
  name: 'CMPHandler',
  setup() {
    const { $cookies, i18n } = useContext();
    const {
      initTrbo, gtmTracking, trackNavigation,
    } = useTracking();
    const { country } = useAppLocale();
    const router = useRouter();
    const { isFunctionalConsentGiven, isMarketingConsentGiven, isPersistBasketConsentGiven } = useUsercentrics();

    const afterEachHook = ref();

    const clearCookies = (cookiePrefixes) => {
      const allCookies = Object.keys($cookies.getAll());

      allCookies.forEach((cookieName) => {
        const match = cookiePrefixes.some((prefix) => cookieName.startsWith(prefix));
        if (match) {
          $cookies.remove(cookieName, { path: '/', domain: `.kik.${country}` });
        }
      });
    };

    const handleCmpEvents = ({ detail }) => {
      const { type } = detail;

      if (type === SAVE_TYPE || type === ACCEPT_ALL_TYPE) {
        const marketing = isMarketingConsentGiven();
        const functional = isFunctionalConsentGiven();

        gtmTracking({ event: 'consentChange', marketing, functional });

        if (!marketing) {
          clearCookies(clearMarketingCookies);
          clearLocalStorage(clearMarketingLocalStorage);
          clearSessionStorage(clearMarketingSessionStorage);
        }

        if (!functional) {
          clearCookies(clearFunctionalCookies);
        }

        const persBasket = isPersistBasketConsentGiven();
        $cookies.set('persBasket', persBasket, { expires: new Date(Date.now() + 12096e5), path: '/' }); // expires in 2 weeks

        initTrbo();
        window.location.reload();
      }
    };

    const showPrivacy = () => {
      window.open(i18n.t('https://legal.kik.{countryCode}/privacy/', { countryCode: country }).toLowerCase(), '_blank', 'noreferrer,noopener');
    };
    const showImprint = () => {
      window.open(i18n.t('https://legal.kik.{countryCode}/imprint/', { countryCode: country }).toLowerCase(), '_blank', 'noreferrer,noopener');
    };

    onMounted(() => {
      window.addEventListener(UC_UI_CMP_EVENT, handleCmpEvents);
      window.addEventListener(onUcPrivacyClick, showPrivacy);
      window.addEventListener(UC_UI_INITIALIZED_EVENT, initTrbo);
      window.addEventListener(onUcImprintClick, showImprint);

      afterEachHook.value = router.afterEach(trackNavigation);
    });

    onUnmounted(() => {
      window.removeEventListener(UC_UI_CMP_EVENT, handleCmpEvents);
      window.removeEventListener(onUcPrivacyClick, showPrivacy);
      window.removeEventListener(UC_UI_INITIALIZED_EVENT, initTrbo);
      window.removeEventListener(onUcImprintClick, showImprint);

      if (afterEachHook.value) {
        afterEachHook.value();
      }
    });

    return {};
  },
  render() {
    return null;
  },
});
