import locale51374db1 from '../../lang/fetchTranslations.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"silentTranslationWarn":true,"numberFormats":{"en-GB":{"currency":{"style":"currency","currency":"USD","currencyDisplay":"symbol"}},"de-DE":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"de-AT":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"pl-PL":{"currency":{"style":"currency","currency":"PLN","currencyDisplay":"symbol"}},"pt-PT":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"es-ES":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"it-IT":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"nl-NL":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"cs-CZ":{"currency":{"style":"currency","currency":"CZK","currencyDisplay":"symbol"}},"sk-SK":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"hu-HU":{"currency":{"style":"currency","currency":"HUF","currencyDisplay":"symbol"}},"sl-SI":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"hr-HR":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"ro-RO":{"currency":{"style":"currency","currency":"RON","currencyDisplay":"symbol"}},"bg-BG":{"currency":{"style":"currency","currency":"BGN","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"de-DE","label":"German","file":"fetchTranslations.js","iso":"de-DE"},{"code":"de-AT","label":"German","file":"fetchTranslations.js","iso":"de-AT"},{"code":"pl-PL","label":"Poland","file":"fetchTranslations.js","iso":"pl-PL"},{"code":"pt-PT","label":"Portugal","file":"fetchTranslations.js","iso":"pt-PT"},{"code":"es-ES","label":"Spain","file":"fetchTranslations.js","iso":"es-ES"},{"code":"it-IT","label":"Italy","file":"fetchTranslations.js","iso":"it-IT"},{"code":"nl-NL","label":"Netherlands","file":"fetchTranslations.js","iso":"nl-NL"},{"code":"cs-CZ","label":"Czech Republic","file":"fetchTranslations.js","iso":"cs-CZ"},{"code":"sk-SK","label":"Slovakia","file":"fetchTranslations.js","iso":"sk-SK"},{"code":"hu-HU","label":"Hungary","file":"fetchTranslations.js","iso":"hu-HU"},{"code":"sl-SI","label":"Slovenia","file":"fetchTranslations.js","iso":"sl-SI"},{"code":"hr-HR","label":"Croatia","file":"fetchTranslations.js","iso":"hr-HR"},{"code":"ro-RO","label":"Romania","file":"fetchTranslations.js","iso":"ro-RO"},{"code":"bg-BG","label":"Bulgaria","file":"fetchTranslations.js","iso":"bg-BG"}],
  defaultLocale: "de-DE",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"vsf-locale","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true,"cookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  currency: "EUR",
  country: "DE",
  autoRedirectByLocale: false,
  countries: [{"name":"DE","label":"Germany","isoCode":"DEU"},{"name":"AT","label":"Austria","isoCode":"AUT"},{"name":"BE","label":"Belgium","isoCode":"BEL"},{"name":"BG","label":"Bulgaria","isoCode":"BGR"},{"name":"HR","label":"Croatia","isoCode":"HRV"},{"name":"CY","label":"Republic of Cyprus","isoCode":"CYP"},{"name":"CZ","label":"Czech Republic","isoCode":"CZE"},{"name":"DK","label":"Denmark","isoCode":"DNK"},{"name":"EE","label":"Estonia","isoCode":"EST"},{"name":"FI","label":"Finland","isoCode":"FIN"},{"name":"FR","label":"France","isoCode":"FRA"},{"name":"EL","label":"Greece","isoCode":"GRC"},{"name":"HU","label":"Hungary","isoCode":"HUN"},{"name":"IE","label":"Ireland","isoCode":"IRL"},{"name":"IT","label":"Italy","isoCode":"ITA"},{"name":"LV","label":"Latvia","isoCode":"LVA"},{"name":"LT","label":"Lithuania","isoCode":"LTU"},{"name":"LU","label":"Luxembourg","isoCode":"LUX"},{"name":"MT","label":"Malta","isoCode":"MLT"},{"name":"NL","label":"Netherlands","isoCode":"NLD"},{"name":"PL","label":"Poland","isoCode":"POL"},{"name":"PT","label":"Portugal","isoCode":"PRT"},{"name":"RO","label":"Romania","isoCode":"ROU"},{"name":"SK","label":"Slovakia","isoCode":"SVK"},{"name":"SI","label":"Slovenia","isoCode":"SVN"},{"name":"SP","label":"Spain","isoCode":"ESP"},{"name":"SE","label":"Sweden","isoCode":"SWE"}],
  currencies: [{"name":"EUR","label":"Euro"},{"name":"PLN","label":"Polish Zloty"}],
  seo: true,
  autoChangeCookie: {"currency":false,"locale":false,"country":false},
  normalizedLocales: [{"code":"de-DE","label":"German","file":"fetchTranslations.js","iso":"de-DE"},{"code":"de-AT","label":"German","file":"fetchTranslations.js","iso":"de-AT"},{"code":"pl-PL","label":"Poland","file":"fetchTranslations.js","iso":"pl-PL"},{"code":"pt-PT","label":"Portugal","file":"fetchTranslations.js","iso":"pt-PT"},{"code":"es-ES","label":"Spain","file":"fetchTranslations.js","iso":"es-ES"},{"code":"it-IT","label":"Italy","file":"fetchTranslations.js","iso":"it-IT"},{"code":"nl-NL","label":"Netherlands","file":"fetchTranslations.js","iso":"nl-NL"},{"code":"cs-CZ","label":"Czech Republic","file":"fetchTranslations.js","iso":"cs-CZ"},{"code":"sk-SK","label":"Slovakia","file":"fetchTranslations.js","iso":"sk-SK"},{"code":"hu-HU","label":"Hungary","file":"fetchTranslations.js","iso":"hu-HU"},{"code":"sl-SI","label":"Slovenia","file":"fetchTranslations.js","iso":"sl-SI"},{"code":"hr-HR","label":"Croatia","file":"fetchTranslations.js","iso":"hr-HR"},{"code":"ro-RO","label":"Romania","file":"fetchTranslations.js","iso":"ro-RO"},{"code":"bg-BG","label":"Bulgaria","file":"fetchTranslations.js","iso":"bg-BG"}],
  localeCodes: ["de-DE","de-AT","pl-PL","pt-PT","es-ES","it-IT","nl-NL","cs-CZ","sk-SK","hu-HU","sl-SI","hr-HR","ro-RO","bg-BG"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "f",
  3: "e",
  4: "t",
  5: "c",
  6: "h",
  7: "T",
  8: "r",
  9: "a",
  10: "n",
  11: "s",
  12: "l",
  13: "a",
  14: "t",
  15: "i",
  16: "o",
  17: "n",
  18: "s",
  19: ".",
  20: "j",
  21: "s",
  22: "\"",
  23: ":",
  24: "\"",
  25: ".",
  26: ".",
  27: "/",
  28: "l",
  29: "a",
  30: "n",
  31: "g",
  32: "/",
  33: "f",
  34: "e",
  35: "t",
  36: "c",
  37: "h",
  38: "T",
  39: "r",
  40: "a",
  41: "n",
  42: "s",
  43: "l",
  44: "a",
  45: "t",
  46: "i",
  47: "o",
  48: "n",
  49: "s",
  50: ".",
  51: "j",
  52: "s",
  53: "\"",
  54: "}",
}

export const localeMessages = {
  'fetchTranslations.js': () => Promise.resolve(locale51374db1),
}
