import { type Category } from '~/composables/custom/useCategory';

export interface State {
  category: Category;
}

const state: State = ({
  category: undefined,
});

export default () => ({ ...state });
