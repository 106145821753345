import { computed, useRoute } from '@nuxtjs/composition-api';
import { vsfRef } from '@vue-storefront/core';

import { usePaymentNavigation } from '~/composables/custom/useCheckoutNavigation/usePaymentNavigation';
import { useBillingNavigation } from '~/composables/custom/useCheckoutNavigation/useBillingNavigation';
import { useShippingNavigation } from '~/composables/custom/useCheckoutNavigation/useShippingNavigation';
import { PaymentMethod } from '~/composables/custom/usePayment/usePayment';
import { useUser } from '~/composables/custom/useUser/useUser';

export enum CheckoutNavigationStatus {
  TO_ORDER, TO_PAYMENT, MISSING_INFO, LOADING, TO_SHIPPING
}
export enum UserType {
  ANONYMOUS, AUTHENTICATED, NEW
}

export const useCheckoutNavigation = () => {
  const route = useRoute();

  const paymentNavigation = usePaymentNavigation();
  const billingNavigation = useBillingNavigation();
  const shippingNavigation = useShippingNavigation();
  const userType = vsfRef<UserType>(route.value.query.newCustomer ? UserType.NEW : undefined, 'selected-user-journey');

  const reset = () => {
    billingNavigation.setShippingMethod(undefined);
    billingNavigation.setBillingAddress(undefined);
    paymentNavigation.setPaymentMethod({} as PaymentMethod);
    shippingNavigation.resetShipping();
    userType.value = undefined;
  };

  return {
    journey: {
      userType: computed(() => userType.value),
      shouldRestart: computed(() => false),

      setUserType: (value: UserType) => { userType.value = value; },
      reset,
    },
    shipping: { ...shippingNavigation },
    billing: { ...billingNavigation },
    payment: { ...paymentNavigation },
  };
};

export const useCheckoutPaths = (type: 'new-customer' | 'guest' | 'customer') => {
  const { isAuthenticated } = useUser();
  const { journey: { userType } } = useCheckoutNavigation();

  const isNewCustomer = computed(() => (!isAuthenticated.value && userType.value === UserType.NEW) || type === 'new-customer');
  const isGuest = computed(() => (!isAuthenticated.value && userType.value === UserType.ANONYMOUS) || type === 'guest');
  const isCustomer = computed(() => (isAuthenticated.value && userType.value === UserType.AUTHENTICATED) || type === 'customer');

  const billingPaths = computed(() => {
    if (isNewCustomer.value) {
      return '/checkout/billing-new-customer';
    }
    if (isGuest.value) {
      return '/checkout/billing-guest';
    }
    if (isCustomer.value) {
      return '/checkout/billing-customer';
    }

    return '/checkout/billing';
  });
  const paymentPaths = computed(() => {
    if (isNewCustomer.value) {
      return '/checkout/payment-new-customer';
    }
    if (isGuest.value) {
      return '/checkout/payment-guest';
    }
    if (isCustomer.value) {
      return '/checkout/payment-customer';
    }

    return '/checkout/payment';
  });
  const shippingPaths = computed(() => {
    if (isNewCustomer.value) {
      return '/checkout/shipping-new-customer';
    }
    if (isGuest.value) {
      return '/checkout/shipping-guest';
    }
    if (isCustomer.value) {
      return '/checkout/shipping-customer';
    }

    return '/checkout/shipping';
  });

  return {
    billingPaths,
    paymentPaths,
    shippingPaths,
  };
};
