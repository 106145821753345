var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"sf-pagination"},[_vm._t("prev",function(){return [(_vm.hasArrows)?_c('div',{staticClass:"sf-pagination__item prev"},[_c(_vm.componentIs,{tag:"component",class:{
          'sf-arrow--transparent': !_vm.canGoPrev,
        },attrs:{"link":_vm.getLinkTo(_vm.getPrev),"override-link":true,"disabled":!_vm.canGoPrev ? true : false,"aria-label":"Go to previous page"},nativeOn:{"click":function($event){return _vm.onNavigateTo(_vm.getPrev)}}},[_c('SfIcon',{attrs:{"icon":"arrow_left","size":"1.125rem"}})],1)],1):_vm._e()]},null,{ isDisabled: !_vm.canGoPrev, prev: _vm.getPrev }),_vm._v(" "),(_vm.showFirst)?[_vm._t("number",function(){return [_c(_vm.componentIs,{tag:"component",staticClass:"sf-pagination__item",attrs:{"override-link":true,"link":_vm.getLinkTo(1)},nativeOn:{"click":function($event){return _vm.onNavigateTo(1)}}},[_vm._v("\n        1\n      ")])]},null,{ page: 1 }),_vm._v(" "),(_vm.firstVisiblePageNumber > 2)?_vm._t("points",function(){return [_c('div',{staticClass:"sf-pagination__item"},[_vm._v("\n        ...\n      ")])]}):_vm._e()]:_vm._e(),_vm._v(" "),_vm._l((_vm.limitedPageNumbers),function(page){return [_vm._t("number",function(){return [_c(_vm.currentPage === page ? 'span' : _vm.componentIs,{key:page,tag:"component",staticClass:"sf-pagination__item",class:{
          'sf-button--pure': _vm.currentPage !== page,
          current: _vm.currentPage === page,
        },attrs:{"override-link":true,"link":_vm.currentPage !== page ? _vm.getLinkTo(page) : null},nativeOn:{"click":function($event){return _vm.onNavigateTo(page)}}},[_vm._v("\n        "+_vm._s(page)+"\n      ")])]},null,{ page, currentPage: _vm.currentPage })]}),_vm._v(" "),(_vm.showLast)?[(_vm.lastVisiblePageNumber < _vm.total - 1)?_vm._t("points",function(){return [_c('div',{staticClass:"sf-pagination__item"},[_vm._v("\n        ...\n      ")])]}):_vm._e(),_vm._v(" "),_vm._t("number",function(){return [_c(_vm.componentIs,{tag:"component",staticClass:"sf-pagination__item",attrs:{"override-link":true,"link":_vm.getLinkTo(_vm.total)},nativeOn:{"click":function($event){return _vm.onNavigateTo(_vm.total)}}},[_vm._v("\n        "+_vm._s(_vm.total)+"\n      ")])]},null,{ page: _vm.total })]:_vm._e(),_vm._v(" "),_vm._t("next",function(){return [(_vm.hasArrows)?_c('div',{staticClass:"sf-pagination__item next"},[_c(_vm.componentIs,{tag:"component",class:{
          'disabled': !_vm.canGoNext,
        },attrs:{"link":_vm.getLinkTo(_vm.getNext),"disabled":!_vm.canGoNext ? true : false,"override-link":true,"aria-label":"Go to previous next"},nativeOn:{"click":function($event){return _vm.onNavigateTo(_vm.getNext)}}},[_c('SfIcon',{attrs:{"icon":"arrow_right","size":"1.125rem"}})],1)],1):_vm._e()]},null,{ isDisabled: !_vm.canGoNext, next: _vm.getNext })],2)
}
var staticRenderFns = []

export { render, staticRenderFns }