var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"big-card-carousel"},[_c('h2',{style:({ color: _vm.topTitleColor })},[_vm._v("\n    "+_vm._s(_vm.topTitle)+"\n  ")]),_vm._v(" "),_c('p',{style:({ color: _vm.subtitleColor})},[_vm._v("\n    "+_vm._s(_vm.subtitle)+"\n  ")]),_vm._v(" "),(_setup.products.length > 0)?_c(_setup.SfCarousel,{staticClass:"big-card-carousel__carousel",attrs:{"settings":{
      type: 'carousel',
      perView: 3,
      gap: 12,
      peek: 50,
      breakpoints: {
        1024: { perView: 4 },
        1023: { perView: 4 },
        768: { perView: 2 },
        767: { perView: 1 },
      },
    }},scopedSlots:_vm._u([{key:"prev",fn:function({ go }){return [_c('div',{staticClass:"carousel-left"},[_c(_setup.SfIcon,{attrs:{"icon":"chevron_left"},on:{"click":go}})],1)]}},{key:"next",fn:function({ go }){return [_c('div',{staticClass:"carousel-right"},[_c(_setup.SfIcon,{attrs:{"icon":"chevron_right"},on:{"click":go}})],1)]}}],null,false,1357330847)},[_vm._v(" "),_vm._l((_setup.products),function(product,i){return _c('SfCarouselItem',{key:i,staticClass:"carousel__item"},[_c(_setup.TeaserCard,{staticClass:"carousel__item__product",attrs:{"title":product.title,"title-color":product.titleColor,"src":product.src,"src-set":product.srcSet,"introduction":product.subtitle,"introduction-color":product.subtitleColor,"link":product.mode === 'product' ? product.productLink : product.ctaLink,"is-my-kik-discount":product.isMyKikDiscount}})],1)})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }