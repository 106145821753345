import { onMounted, onUnmounted } from '@nuxtjs/composition-api';
import { useCart } from './useCart/useCart';

export const useWindow = () => {
  const { refresh } = useCart();

  const refreshCart = async () => {
    await refresh();
  };

  onMounted(() => {
    document.addEventListener('focus', refreshCart);
  });

  onUnmounted(() => {
    document.removeEventListener('focus', refreshCart);
  });

  return {};
};
