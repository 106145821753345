import { Locale } from '@nuxtjs/i18n';
import { ShippingMethod } from '@vue-storefront/commercetools-api';

import { ShippingMethodInfo } from '~/types/shipping-methods';

const { cartGetters } = require('~/composables/getters');

const getDefaultShippingMethod = ([firstMethod]: ShippingMethod[] = []) => firstMethod;

const isClickAndCollect = (shippingMethod: ShippingMethodInfo) => shippingMethod?.type === 'shop' || shippingMethod?.custom?.customFieldsRaw.some((field) => field.name === 'type' && field.value === 'shop');
const isPackstation = (shippingMethod: ShippingMethodInfo) => shippingMethod?.type === 'packstation' || shippingMethod?.custom?.customFieldsRaw.some((field) => field.name === 'type' && field.value === 'packstation');

export default {
  getDefaultShippingMethod,
  getDefaultShippingMethodPrice: (shippingMethods: ShippingMethod[]) => getDefaultShippingMethod(shippingMethods) ?? 0,
  getShippingDescription: (shippingMethod: ShippingMethod) => shippingMethod?.localizedDescription,
  getShippingDisplayName: (shippingMethod: ShippingMethod, locale: Locale) => shippingMethod?.custom?.customFieldsRaw.find((field) => field.name === 'localizedDisplayName')?.value?.[locale] ?? shippingMethod?.localizedDescription,
  isClickAndCollect,
  isPackstation,
  getShippingPrice: (shippingMethod: ShippingMethodInfo, totalPrice: number) => {
    const { freeAbove, amount } = shippingMethod ?? {};

    if (!totalPrice) {
      return amount;
    }

    if (freeAbove > 0 && totalPrice > freeAbove) {
      return 0;
    }

    return amount;
  },
  isClickAndCollectAvailable: (shippingMethod: ShippingMethodInfo, cart?) => {
    if (!shippingMethod || !cart || !isClickAndCollect(shippingMethod)) {
      return false;
    }

    return cartGetters.getTotals(cart)?.special >= (shippingMethod.thresholdToActivate ?? 0);
  },
  isShippingAvailable: (shippingMethod: ShippingMethodInfo, cart?) => {
    if (!shippingMethod || !cart) {
      return false;
    }

    return cartGetters.getTotals(cart)?.special >= (shippingMethod.thresholdToActivate ?? 0);
  },
};
