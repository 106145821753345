import { useContext, useMeta, useRoute } from '@nuxtjs/composition-api';
import { MetaPropertyProperty } from 'vue-meta/types';

import { useCategory } from '../useCategory';

export const useProductMeta = () => {
  const { i18n, $domain } = useContext();
  const meta = useMeta();
  const route = useRoute();
  const { result: category } = useCategory();
  const greatOffers = 'Great offers now on KiK';

  const setProductMeta = () => {
    const { query, path, fullPath } = route.value;
    const { host: shopUrl } = $domain;
    const currentCategoryName = route.value.meta.isSale ? (category.value?.saleMetadata?.metaTitle || `${i18n.t('Sale')}: ${category.value?.metaTitle ?? category.value?.name}`) : category.value?.metaTitle;
    const currentCategoryDescription = route.value.meta.isSale ? category.value?.saleMetadata?.metaDescription : category.value?.metaDescription;
    const noCategorySelected = !category.value;

    const defaultContent = noCategorySelected ? i18n.t('All new products at KiK | New products at KiK').toString() : `${currentCategoryName} | KiK Onlineshop`;

    let title = currentCategoryName;

    if (noCategorySelected) {
      title = i18n.t('All new products at KiK | New products at KiK').toString();
    } else if (!title) {
      title = `${category.value?.name} | ${i18n.t(greatOffers)}`;
    }
    meta.title.value = title;

    let description = currentCategoryDescription;

    if (noCategorySelected) {
      description = i18n.t('New products in Kik | Always buy cheap on the online store | Great deals now on KiK').toString();
    } else if (!description) {
      description = `${category.value?.name} ✓ ${i18n.t('Always shop cheaply in the online shop')} | ${i18n.t(greatOffers)}`;
    }

    const metaList: MetaPropertyProperty[] = [
      {
        hid: 'apple-mobile-web-app-title',
        name: 'apple-mobile-web-app-title',
        property: 'apple-mobile-web-app-title',
        content: defaultContent,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: defaultContent,
        name: 'og:title',
      },
      {
        hid: 'description',
        property: 'description',
        name: 'description',
        content: description,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        name: 'og:description',
        content: description,
      },
    ];

    if (noCategorySelected) {
      metaList.push({
        hid: 'og:url',
        property: 'og:url',
        content: `${shopUrl}${fullPath}`,
        name: 'og:url',
      });
    }

    meta.meta.value = metaList;

    const page = Number(query.page);

    const link = [
      {
        rel: 'next',
        href: `${path}?page=${Number.isNaN(page) ? 2 : page + 1}`,
      },
    ];

    if (page > 1) {
      link.push(
        {
          rel: 'prev',
          href: `${path}?page=${page - 1}`,
        },
      );
    }

    meta.link.value = link;
  };

  return {
    setProductMeta,
  };
};
