import { computed, useContext, useRoute } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';

import { Logger } from '~/apiExtensions/utils';
import { CustomFilters, Facets } from '~/components/Product/types/product';
import { replaceHistory } from '~/helpers/utils/replaceHistory';
import { useBFF } from '~/composables/useBFF/useBFF';

export const useFilters = () => {
  const route = useRoute();
  const { $ct } = useBFF();
  const { i18n } = useContext();

  const {
    slug_1: slug1,
    slug_2: slug2,
    slug_3: slug3,
    slug_4: slug4,
  } = route.value.params;

  const id = [slug1, slug2, slug3, slug4].filter(Boolean).join('-');

  const categoryFacets = sharedRef(undefined, `facets-${id}`);
  const priceFilter = sharedRef(undefined, `price-filter-${id}`);
  const hasFilters = sharedRef(false, `is-filter-selected-${id}`);

  const saleParam = i18n.t('Sale').toString().toLowerCase();
  const newParam = i18n.t('New').toString().toLowerCase();
  const mykikParam = i18n.t('mykik').toString().toLowerCase();

  const setHasFilters = (filters) => {
    hasFilters.value = Object.keys(filters).some((key) => Object.values(Facets).some((facet: string) => facet === key) || Object.values(CustomFilters).some((facet: string) => facet === key));
  };

  const getFilters = (originalAttributes = {}) => {
    let filters = route.value.query;
    if (process.client) {
      const queryParams = new URLSearchParams(window.location.search);
      filters = Object.fromEntries(queryParams);
    }

    setHasFilters(filters);

    const facetsValues: string[] = Object.values(Facets);

    const newAndSale = (filters?.[CustomFilters.SALE_AND_NEW] as string)?.split(',') || [];

    const result = Object.keys(filters || {}).reduce((acc, currentValue) => {
      if (facetsValues.includes(currentValue)) {
        return {
          ...acc,
          [currentValue]: (filters[currentValue] as string).split(','),
        };
      }
      return acc;
    }, {
      sale: newAndSale.includes(saleParam) ?? false,
      recent: newAndSale.includes(newParam) ?? false,
      mykik: newAndSale.includes(mykikParam) ?? false,
      sort: filters?.[CustomFilters.SORT],
      search: filters?.[CustomFilters.SEARCH],
      price: filters?.[CustomFilters.PRICE_RANGE_MIN] && filters?.[CustomFilters.PRICE_RANGE_MAX] ? { min: filters[CustomFilters.PRICE_RANGE_MIN], max: filters[CustomFilters.PRICE_RANGE_MAX] } : undefined,
    });

    return {
      ...result,
      ...originalAttributes,
    };
  };

  const getSearchParamsWithoutPreviousFilters = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryParamName: string[] = Object.values(Facets);
    const customQueryParams = Object.values(CustomFilters);

    [...queryParamName, ...customQueryParams].forEach((value) => searchParams.delete(value));

    return searchParams;
  };

  const resetFilters = () => {
    const searchParams = getSearchParamsWithoutPreviousFilters();

    replaceHistory(route.value.path, searchParams);
  };

  const addFiltersToPath = (params) => {
    const searchParams = new URLSearchParams(window.location.search);
    Object.keys(params).forEach((key) => {
      searchParams.set(key, params[key]);
    });
    replaceHistory(route.value.path, searchParams);
  };

  const changeFilters = (selectedFilters) => {
    const {
      sale, recent, mykik, sort, price, ...filters
    } = selectedFilters;

    const filterKeys = Object.keys(filters || {});
    const searchParams = getSearchParamsWithoutPreviousFilters();

    if (recent || sale || mykik) {
      const params = [
        recent ? newParam : undefined,
        sale ? saleParam : undefined,
        mykik ? mykikParam : undefined]
        .filter(Boolean)
        .join(',');
      searchParams.set(CustomFilters.SALE_AND_NEW, params);
    }

    if (sort) {
      searchParams.set(CustomFilters.SORT, sort);
    }

    if (price?.min !== undefined && price?.max && (priceFilter.value.min !== price.min || priceFilter.value.max !== price.max)) {
      searchParams.set(CustomFilters.PRICE_RANGE_MIN, price.min);
      searchParams.set(CustomFilters.PRICE_RANGE_MAX, price.max);
    }

    filterKeys.forEach((key) => {
      const value = filters[key];
      if (value?.length) {
        searchParams.set(key, `${value}`);
      }
    });

    setHasFilters(searchParams);

    replaceHistory(route.value.path, searchParams);
  };

  const getInitialPriceRange = async (categories?: string[], products?: string[]): Promise<{ min: number, max: number }> => {
    if (!categoryFacets.value && categories?.length) {
      await getCategoryFacets([Facets.PRICE], categories, products);
    }

    return {
      min: 0,
      max: categoryFacets.value?.price?.max ?? 100,
    };
  };

  const calculateFilters = async (categories?: string[], products?: string[]) => {
    const initialObject = getFilters();

    priceFilter.value = await getInitialPriceRange(categories, products);

    const initialFilters = Object.values(Facets)?.reduce((acc, curr) => ({ ...acc, [curr]: initialObject?.[curr] ?? [] }), {});

    return {
      ...initialObject,
      ...initialFilters,
      price: initialObject?.price ?? priceFilter.value,
    };
  };

  const getCategoryFacets = async (facetsFilters, categories, products) => {
    const { data, errors } = await $ct.getCategoryFacets({
      categories,
      facetsFilters,
      products,
    });

    if (errors?.length) {
      Logger.error('Error fetching facets: ', errors);
    }
    categoryFacets.value = data;
  };

  return {
    getFilters,
    changeFilters,
    priceFilter: computed(() => priceFilter.value),
    calculateFilters,
    getInitialPriceRange,
    hasFilters,
    resetFilters,
    addFiltersToPath,
  };
};
