import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { MetaInfo } from 'vue-meta/types';

const EHI_ID = 4030615;

function obfuscate(seed: number, id: number) {
  const now = Date.now();
  const random = seed * 6e4;
  const offset = (Math.sin(id) || 0) * random;

  return Math.floor((now + offset) / random);
}

export default defineNuxtPlugin(async ({ app, $flags }) => {
  if (!$flags.has('ehi')) {
    return;
  }

  (app.head as MetaInfo).script.push({
    type: 'text/javascript',
    src: `https://dashboard.trustprofile.com/sidebar.js?id=${EHI_ID}&c=${obfuscate(10, EHI_ID)}`,
    async: true,
  });
});
