export interface State {
  flags: string[];
  lastFetchAt: number,
}

const state: State = ({
  flags: [],
  lastFetchAt: -1,
});

export default () => ({ ...state });
