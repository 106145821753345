import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { MetaInfo } from 'vue-meta/types';

export default defineNuxtPlugin((({ app, $flags, $domain }) => {
  if (!$domain.luigiScript || !$flags.has('luigisbox')) {
    return;
  }

  (app.head as MetaInfo).script.push({
    src: $domain.luigiScript,
    'data-usercentrics': 'Luigi’s Box',
    type: 'text/plain',
    defer: true,
    async: true,
  });
}));
