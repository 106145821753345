/* eslint-disable no-prototype-builtins, no-shadow, no-restricted-syntax, no-nested-ternary, no-param-reassign */
import { AgnosticOrderStatus } from '@vue-storefront/core';
import { VsfCategory, CtCategory } from '~/types/categories';
import { useReturns } from '~/composables';

const calculateAge = (birthday) => {
  const currentDate = new Date();
  const diff = currentDate.valueOf() - birthday;
  // This is the difference in milliseconds
  const currentAge = Math.floor(diff / 31557600000);
  // Divide by 1000*60*60*24*365.25
  return currentAge;
};

const findAttr = (attributes: any[], name: string) => attributes.find((attr) => attr.name === name)?.value;

function convertCategoryCTtoVSF(category: CtCategory, locale: string, currentSlugs: string[]) {
  const localizedSlug: string = category.slug ?? '';
  const subCategories: VsfCategory[] = category.childrenItems?.map((subCat): VsfCategory => convertCategoryCTtoVSF(subCat, locale, currentSlugs));
  return {
    count: null,
    key: category.key,
    id: category.id,
    isCurrent: currentSlugs.includes(localizedSlug),
    items: subCategories,
    label: category.name,
    slug: localizedSlug,
    slugAllLocales: category.slugAllLocales,
    canonicalUrl: category.canonicalUrl,
    metaTitle: category.metaTitle,
    metaDescription: category.metaDescription,
    headlineTitle: category.headlineTitle,
    categoryDescription: category.description,
    isHidden: category.isHidden,
    saleMetadata: category.saleMetadata,
    redirectionRules: category.redirectionRules,
  };
}

const getLocalizedDate = (date, locale: string) => {
  const createdAt = new Date(date);
  const options: any = {
    year: 'numeric', month: '2-digit', day: 'numeric',
  };

  return createdAt.toLocaleDateString(locale, options);
};

function getReturnStatus(shipmentState, paymentState) {
  const {
    RETURN_SHIPMENT_STATES: { RETURNED, BACK_IN_STOCK },
    RETURN_PAYMENT_STATES: { NOT_REFUNDED, REFUNDED, INITIAL },
  } = useReturns(undefined);

  if (
    shipmentState === RETURNED
        || (shipmentState === BACK_IN_STOCK && paymentState === INITIAL)
  ) {
    return 'In process';
  }
  if (shipmentState === BACK_IN_STOCK && paymentState === REFUNDED) {
    return 'finalized';
  }
  if (shipmentState === BACK_IN_STOCK && paymentState === NOT_REFUNDED) {
    return 'Clarification case';
  }

  return undefined;
}

function getOrderStatus(paymentMethod: string, orderStatus: string, shipmentStatus: string, paymentStatus: string) {
  switch (paymentMethod) {
    case 'vorkasse': {
      if (orderStatus === AgnosticOrderStatus.Open) { return 'Waiting for payment'; }
      if (orderStatus === AgnosticOrderStatus.Confirmed && paymentStatus === 'Pending') { return 'Waiting for payment'; }
      if (orderStatus === AgnosticOrderStatus.Confirmed && paymentStatus === 'Paid') { return 'In process'; }
      if (orderStatus === AgnosticOrderStatus.Complete) { return 'shipped'; }
      if (orderStatus === AgnosticOrderStatus.Cancelled) { return 'cancelled'; }
      return undefined;
    }
    case 'kik_filiale': {
      if (orderStatus === AgnosticOrderStatus.Open) { return 'In process'; }
      if (orderStatus === AgnosticOrderStatus.Confirmed && shipmentStatus === 'Pending' && paymentStatus === 'Pending') { return 'On the way to the store'; }
      if (orderStatus === AgnosticOrderStatus.Confirmed && shipmentStatus === 'Shipped' && paymentStatus === 'Pending') { return 'Ready for pickup in the store'; }
      if (orderStatus === AgnosticOrderStatus.Confirmed && paymentStatus === 'Pending') { return 'In process'; }
      if (orderStatus === AgnosticOrderStatus.Complete) { return 'Picked up at the store'; }
      if (orderStatus === AgnosticOrderStatus.Cancelled) { return 'cancelled'; }
      return undefined;
    }
    default: {
      if (orderStatus === AgnosticOrderStatus.Open) return 'In process';
      if (orderStatus === AgnosticOrderStatus.Confirmed) return 'In process';
      if (orderStatus === AgnosticOrderStatus.Complete) return 'shipped';
      if (orderStatus === AgnosticOrderStatus.Cancelled) return 'cancelled';
      return undefined;
    }
  }
}

export {
  convertCategoryCTtoVSF,
  getOrderStatus,
  getReturnStatus,
  getLocalizedDate,
  findAttr,
  calculateAge,
};
