import { ref, useContext } from '@nuxtjs/composition-api';
import { LineItem, Order } from '@vue-storefront/commercetools-api';
import { Locale } from '@nuxtjs/i18n';

import { useAppLocale } from './useAppLocale';
import { useTrbo } from './useTrbo/useTrbo';
import { useGtm } from './useGtm/useGtm';
import { orderGetters, paymentGetters } from '~/composables/getters';
import { useUsercentrics } from './useUsercentrics/useUsercentrics';

const PAYMENT_MAP = {
  pay_later: 'Invoice',
  sofort: 'Sofort',
  ideal: 'iDeal',
  paypal: 'Paypal',
  paypal_express: 'Paypal express',
  eps: 'EPS',
  p24: 'p24',
  payu: 'payu',
  vorkasse: 'Payment in advance',
  card: 'Payment with credit card',
  kik_filiale: 'Payment in store',
  kik_filiale_popup: 'Payment in store',
};

const getPaymentMethod = (order: Order): string | null => {
  const { method } = paymentGetters.getActivePaymentMethod(order.paymentInfo).paymentMethodInfo || {};

  if (method) {
    return PAYMENT_MAP[method.toLowerCase()];
  }
  return null;
};

const TrackingItem = (lineItem: LineItem, index: number, locale: Locale, currency: string) => {
  const findAttr = (name) => lineItem.variant?.attributesRaw.find((x) => x.name === name)?.value;
  const lineItemBreadcrumbs = lineItem.custom?.customFieldsRaw?.find(({ name }) => name === 'categoryPath')?.value?.split(' / ') ?? [];

  const itemCategories = lineItemBreadcrumbs.reduce((acc, cat, i: number) => ({
    ...acc,
    [`item_category${i + 1}`]: cat,
  }), {});
  return {
    item_id: findAttr('color_number'),
    currency,
    index,
    item_color: findAttr('color')?.label[locale],
    item_group: findAttr('wgr'),
    item_ekb: findAttr('ekb'),
    item_name: lineItem.name,
    // @ts-ignore
    item_product: lineItem.productKey,
    item_variant: lineItem.variant?.sku,
    price: ((lineItem?.price?.value.centAmount || 0) * lineItem.quantity) / 100,
    quantity: lineItem.quantity,
    ...itemCategories,
  };
};

export const useTracking = () => {
  const {
    initTrbo,
    trboEnabled,
    trboPageView,
    trboAddToCart,
    trboTracking,
    trboCurrentBasket,
    delayedTrboTracking,
  } = useTrbo();
  const {
    gtmTracking,
    gtmPageView,
    gtmProductView,
    gtmRemoveItem,
    gtmAddToCart,
  } = useGtm();
  const { locale, currencyCode: currency } = useAppLocale();
  const { $domain } = useContext();
  const { isFunctionalConsentGiven } = useUsercentrics();

  const searchQuery = ref();

  const checkout = (order: Order) => {
    if (!order?.lineItems) {
      trackError(`Checkout tracking event :: No line items found in order ${order?.orderNumber}!`);
      return;
    }

    const items = order.lineItems.map((item, index) => TrackingItem(item, index, locale, currency));

    const transactionId = isFunctionalConsentGiven() ? order.orderNumber : `anonym-${Date.now()}`;

    const trackObj = {
      event: 'purchase',
      ecommerce: {
        transaction_id: transactionId,
        tax: order.taxedPrice?.taxPortions?.[0]?.rate,
        value: orderGetters.getFormattedPrice(order.totalPrice),
        shipping: order.shippingInfo?.price ? orderGetters.getFormattedPrice(order.shippingInfo.price) : undefined,
        shipment: order.shippingInfo?.shippingMethodName,
        payment: getPaymentMethod(order),
        coupon: order.discountCodes?.[0]?.discountCode?.name ?? undefined,
        coupon_key: order.discountCodes?.[0]?.discountCode?.code ?? undefined,
        items,
      },
    };

    gtmTracking(trackObj);

    trboTracking('sale', {
      order_id: transactionId,
      value: orderGetters.getFormattedPrice(order.totalPrice),
      currency,
      coupon_code: order.discountCodes?.[0]?.discountCode?.name ?? '',
      items,
    });

    // Replace the property values with your actual values
    window.VWO?.event?.('purchase', {
      checkout: true,
      revenue: orderGetters.getFormattedPrice(order.totalPrice),
    });
  };

  const plpSearch = (internalSearch: string) => {
    if (searchQuery.value === internalSearch) {
      return;
    }

    searchQuery.value = internalSearch;
    gtmTracking({ event: 'search', internalSearch });
  };

  const trackBasketOpen = () => {
    gtmTracking({ event: 'open_basket' });
  };

  const trackLoginOpen = () => {
    gtmTracking({ event: 'open_login_layer' });
    trboTracking('open_login_layer', {});
  };

  const trackForgetPassword = () => {
    gtmTracking({ event: 'forgot_password' });
    trboTracking('forgot_password', {});
  };

  const trackForgetPasswordResend = () => {
    gtmTracking({ event: 'forgot_password_resend' });
    trboTracking('forgot_password_resend', {});
  };

  const trackLogin = () => {
    gtmTracking({ event: 'login' });
    trboTracking('login', {});
  };

  const trackStartRegistration = () => {
    gtmTracking({ event: 'start_registration' });
    trboTracking('start_registration', {});
  };

  const trackStartCheckout = () => {
    gtmTracking({ event: 'start_checkout' });
    trboTracking('start_checkout', {});
  };

  const trackSelectShipping = () => {
    gtmTracking({ event: 'select_shipping' });
    trboTracking('select_shipping', {});
  };

  const trackSelectPayment = () => {
    gtmTracking({ event: 'select_payment' });
    trboTracking('select_payment', {});
  };

  const trackError = (message: string) => {
    gtmTracking({ event: 'error', error_message: message });
    trboTracking('error', { error_message: message });
  };

  const trackRemoveItem = (item) => {
    gtmRemoveItem(item);
  };

  const trackProductView = (item) => {
    gtmProductView(item);
  };

  const trackNavigation = () => {
    if (!window.UC_UI) {
      return;
    }

    const statuses: Record<string, boolean> = (window.UC_UI.getServicesBaseInfo() as any[])?.reduce((acc, { name, consent }) => ({ ...acc, [name]: consent.status }), {});

    gtmTracking({ ...statuses, event: 'consent_status' });
  };

  const trackPage = (includeTrbo = false) => {
    gtmPageView();

    if (includeTrbo && trboEnabled()) {
      trboCurrentBasket();
      trboPageView();
    }
  };

  const trackAddToCart = (item, data) => {
    gtmAddToCart(item, data.sku);
    trboAddToCart(item, data.quantity);
  };

  const trackTrustedShops = (order: Order) => {
    gtmTracking(
      {
        event: 'trusted_shops_data',
        ts_checkout: {
          tsCheckoutOrderNr: order?.orderNumber,
          tsCheckoutBuyerEmail: order?.billingAddress?.email,
          tsCheckoutOrderAmount: order && order.totalPrice.centAmount / 100,
          tsCheckoutOrderCurrency: $domain.currency,
          tsCheckoutOrderPaymentType: paymentGetters.getActivePaymentMethod(order?.paymentInfo)?.paymentMethodInfo.method,
        },
      },
    );
  };

  return {
    // GTM
    gtmTracking,
    // trbp
    initTrbo,
    trboPageView,
    delayedTrboTracking,
    // misc
    checkout,
    plpSearch,
    trackBasketOpen,
    trackLoginOpen,
    trackForgetPassword,
    trackForgetPasswordResend,
    trackLogin,
    trackStartRegistration,
    trackStartCheckout,
    trackSelectShipping,
    trackSelectPayment,
    trackError,
    trackRemoveItem,
    trackProductView,
    trackAddToCart,
    trackPage,
    trackNavigation,
    trackTrustedShops,
  };
};
