import { render, staticRenderFns } from "./OneCTImage.vue?vue&type=template&id=2e81b80e&scoped=true&"
import script from "./OneCTImage.vue?vue&type=script&lang=ts&setup=true&"
export * from "./OneCTImage.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./OneCTImage.vue?vue&type=style&index=0&id=2e81b80e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e81b80e",
  null
  
)

export default component.exports