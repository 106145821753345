import {
  ref,
  onMounted,
  onUnmounted,
  computed,
} from '@nuxtjs/composition-api';

export const useLuigisbox = () => {
  const isMounted = ref(false);

  onMounted(() => {
    isMounted.value = true;
  });

  onUnmounted(() => {
    isMounted.value = false;
  });

  return {
    classes: computed(() => ([
      { 'lbx-ready-for-search': isMounted.value },
      { 'lbx-ready-for-ac': isMounted.value },
    ])),
  };
};
