import { sharedRef } from '@vue-storefront/core';
import {
  computed, Ref, useContext, watch,
} from '@nuxtjs/composition-api';
import { v4 as uuidV4 } from 'uuid';
import { useUser } from '@vue-storefront/commercetools';
import { useAppLocale } from './useAppLocale';

export default function useCheckoutForm(id?: string, formId?: string) {
  const { $flags } = useContext();

  const { country: localeCountry } = useAppLocale();
  const INITIAL_STATE = {
    key: uuidV4(),
    id: formId ?? '',
    userType: $flags.has('enabledKiosk') ? 'kiosk' : 'personal',
    vat: undefined,
    title: '',
    salutation: '',
    firstName: '',
    lastName: '',
    streetName: '',
    streetNumber: '',
    packstationNumber: '',
    packstationPostNumber: '',
    additionalAddressInfo: 'PersonalAddress',
    additionalStreetInfo: '',
    postalCode: '',
    city: '',
    country: localeCountry,
    email: undefined,
    phone: '',
    mobile: '',
    company: '',
  };
  const form = sharedRef({ ...INITIAL_STATE }, formId ?? INITIAL_STATE.key);
  const { isAuthenticated } = useUser();
  const isVisible: Ref<boolean> = sharedRef(
    !isAuthenticated.value,
    `useCheckoutForm-visibility-${id ?? INITIAL_STATE.key}`,
  );

  const updateForm = (address) => {
    form.value = { ...address, country: address.country || localeCountry };
  };

  const resetForm = () => {
    updateForm(INITIAL_STATE);
  };

  watch(() => form.value.userType, () => {
    resetForm();
  });

  const toggleVisibility = (state: boolean) => {
    isVisible.value = state;
  };

  return {
    form: computed(() => form.value),
    isVisible: computed(() => isVisible.value),
    toggleVisibility,
    updateForm,
    resetForm,
  };
}
