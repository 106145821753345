import { computed, reactive } from '@nuxtjs/composition-api';

export interface UiNotification {
  message: string;
  // eslint-disable-next-line no-unused-vars
  action?: { text: string; onClick: (...args: any) => void };
  type: 'danger' | 'success' | 'info' | 'warning';
  icon?: string;
  persist?: boolean;
  id?: symbol;
  dismiss?: () => void;
  ttlInMs?: number;
}

interface Notifications {
  notifications: Array<UiNotification>;
}

const state = reactive<Notifications>({
  notifications: [],
});
const maxVisibleNotifications = 3;
const timeToLive = 5000;

const useUiNotification = () => {
  const send = (notification: UiNotification) => {
    const id = Symbol('id');

    const dismiss = () => {
      const index = state.notifications.findIndex((n) => n.id === id);

      if (index !== -1) state.notifications.splice(index, 1);
    };

    const newNotification = {
      ...notification,
      id,
      dismiss,
    };

    state.notifications.push(newNotification);
    if (state.notifications.length > maxVisibleNotifications) state.notifications.shift();

    if (!notification.persist) {
      setTimeout(dismiss, notification.ttlInMs ?? timeToLive);
    }
  };

  return {
    send,
    notifications: computed(() => state.notifications),
  };
};

export default useUiNotification;
