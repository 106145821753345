import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { MetaInfo } from 'vue-meta/types';

const layer = 'dataLayer';

export default defineNuxtPlugin((({ app, $domain, $config }, inject) => {
  inject('gtm', {
    push(obj) {
      if (!$config.gtm.enabled) {
        return;
      }

      if ($config.gtm.debug) {
        console.log('push', obj);
      }

      if (!window[layer]) {
        window[layer] = [];
      }
      window[layer].push(obj);
    },
  });

  if (!$domain.gtmId) {
    throw new Error('Missing GTM config');
  }

  (app.head as MetaInfo).script.push({
    src: `https://www.googletagmanager.com/gtm.js?id=${$domain.gtmId}`,
    'data-usercentrics': 'Google Tag Manager',
    type: 'text/plain',
    defer: true,
    async: true,
  });
}));
