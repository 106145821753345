import { render, staticRenderFns } from "./OneCTTextWrapper.vue?vue&type=template&id=4d9ece26&"
import script from "./OneCTTextWrapper.vue?vue&type=script&lang=ts&setup=true&"
export * from "./OneCTTextWrapper.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports