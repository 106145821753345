import { userGetters } from '@vue-storefront/commercetools';
import { Customer } from '@vue-storefront/commercetools-api';

const isCustomerFeatureEnabled = (user: Customer, feature: string): boolean => user?.custom?.customFieldsRaw?.find(({ name: fieldName }) => fieldName === 'featureBlackList')?.value?.includes(feature) ?? false;

export default {
  ...userGetters,
  isClickCollectBlocked: (user: Customer) => isCustomerFeatureEnabled(user, 'shipment.click_collect'),
  isPaymentInAdvanceBlocked: (user: Customer) => isCustomerFeatureEnabled(user, 'payment.vorkasse.vorkasse'),
};
