var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"top-categories-slider"},[_c(_setup.SfHeading,{staticClass:"sf-heading--left",attrs:{"title":_vm.topTitle ? _vm.topTitle : 'Top Categories'}}),_vm._v(" "),_c(_setup.SfCarousel,{staticClass:"top-categories-slider__carousel",attrs:{"settings":{
      type: 'carousel',
      perView: 6,
      gap: 12,
      peek: 50,
      breakpoints: {
        1024: { perView: 4 },
        1023: { perView: 3 },
        768: { perView: 3 },
        767: { perView: 2 },
      },
    }},scopedSlots:_vm._u([{key:"prev",fn:function({ go }){return [_c('div',{staticClass:"carousel-left"},[_c(_setup.SfIcon,{attrs:{"icon":"chevron_left"},on:{"click":go}})],1)]}},{key:"next",fn:function({ go }){return [_c('div',{staticClass:"carousel-right"},[_c(_setup.SfIcon,{attrs:{"icon":"chevron_right"},on:{"click":go}})],1)]}}])},[_vm._v(" "),_vm._v(" "),_vm._l((_setup.topCategories),function(category,i){return _c('SfCarouselItem',{key:i,staticClass:"carousel__item"},[_c(_setup.TopItem,{attrs:{"title":category.title,"subtitle":category.subtitle,"image":category.image,"src-set":category.srcSet,"cta-link":category.ctaLink,"slug":category.slug,"link-target-mode":category.linkTargetMode}})],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }