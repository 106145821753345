import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { MetaInfo } from 'vue-meta/types';

export default defineNuxtPlugin(async ({ app, $domain, $flags }) => {
  if ($flags.hasnt('vwo')) {
    return;
  }

  (app.head as MetaInfo).script.push({
    id: 'vwoCode',
    type: 'text/javascript',
    innerHTML: `// Do replace 'ACCOUNT_ID' below with the actual account id
                window._vwo_code ||
                  (function () {
                      var account_id = ${$domain.vwoId},
                          version = 2.1,
                          settings_tolerance = 2000,
                          hide_element = 'body',
                          hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
                          /* DO NOT EDIT BELOW THIS LINE */
                          f = false,
                          w = window,
                          d = document,
                          v = d.querySelector('#vwoCode'),
                          cK = '_vwo_' + account_id + '_settings',
                          cc = {};
                      try {
                          var c = JSON.parse(localStorage.getItem('_vwo_' + account_id + '_config'));
                          cc = c && typeof c === 'object' ? c : {};
                      } catch (e) {}
                      var stT = cc.stT === 'session' ? w.sessionStorage : w.localStorage;
                      code = {
                          use_existing_jquery: function () {
                              return typeof use_existing_jquery !== 'undefined' ? use_existing_jquery : undefined;
                          },
                          library_tolerance: function () {
                              return typeof library_tolerance !== 'undefined' ? library_tolerance : undefined;
                          },
                          settings_tolerance: function () {
                              return cc.sT || settings_tolerance;
                          },
                          hide_element_style: function () {
                              return '{' + (cc.hES || hide_element_style) + '}';
                          },
                          hide_element: function () {
                              if (performance.getEntriesByName('first-contentful-paint')[0]) {
                                  return '';
                              }
                              return typeof cc.hE === 'string' ? cc.hE : hide_element;
                          },
                          getVersion: function () {
                              return version;
                          },
                          finish: function () {
                              if (!f) {
                                  f = true;
                                  var a = d.getElementById('_vis_opt_path_hides');
                                  if (a) a.parentNode.removeChild(a);
                              }
                          },
                          finished: function () {
                              return f;
                          },
                          addScript: function (content) {
                              var script = d.createElement('script');
                              script.type = 'text/javascript';
                              if (content.src) {
                                  script.src = content.src;
                              } else {
                                  script.text = content.text;
                              }
                              d.getElementsByTagName('head')[0].appendChild(script);
                          },
                          load: function (a, options) {
                              var s = this.getSettings(),
                                  b = d.createElement('script'),
                                  t = this;
                              options = options || {};
                              if (s) {
                                  b.textContent = s;
                                  d.getElementsByTagName('head')[0].appendChild(b);
                                  // This check is necessary to ensure jPhp loads in case the cache is corrupted or has a syntax error.
                                  if (!w.VWO || VWO.caE) {
                                      // caE -> stands for cache error (its value will be 1 if we enter cache block in jphp)
                                      stT.removeItem(cK);
                                      t.load(a);
                                  }
                              } else {
                                  var xhr = new XMLHttpRequest();
                                  xhr.open('GET', a, true);
                                  //dSC -> dont send Cookies
                                  xhr.withCredentials = !options.dSC;
                                  xhr.responseType = options.responseType || 'text';
                                  xhr.onload = function () {
                                      if (options.onloadCb) {
                                          return options.onloadCb(xhr, a);
                                      }
                                      if (xhr.status === 200) {
                                          _vwo_code.addScript({ text: xhr.responseText });
                                      } else {
                                          _vwo_code.finish('&e=loading_failure:' + a);
                                      }
                                  };
                                  xhr.onerror = function () {
                                      if (options.onerrorCb) {
                                          return options.onerrorCb(a);
                                      }
                                      _vwo_code.finish('&e=loading_failure:' + a);
                                  };
                                  xhr.send();
                              }
                          },
                          getSettings: function () {
                              try {
                                  var i = stT.getItem(cK);
                                  if (!i) {
                                      return;
                                  }
                                  i = JSON.parse(i);
                                  if (Date.now() > i.e) {
                                      stT.removeItem(cK);
                                      return;
                                  }
                                  return i.s;
                              } catch (e) {
                                  return;
                              }
                          },
                          init: function () {
                              if (d.URL.indexOf('__vwo_disable__') > -1) return;
                              var sT = this.settings_tolerance();
                              w._vwo_settings_timer = setTimeout(function () {
                                  _vwo_code.finish();
                                  stT.removeItem(cK);
                                  _vwo_code.removeLoaderAndOverlay();
                              }, sT);
                              var filterConfig = this.filterConfig;
                              if (!filterConfig || filterConfig.filterTime === 'balanced') {
                                  var a;
                                  if (this.hide_element() !== 'body') {
                                      a = d.createElement('style');
                                      var hE = this.hide_element(),
                                          b = hE ? hE + this.hide_element_style() : '',
                                          h = d.getElementsByTagName('head')[0];
                                      a.setAttribute('id', '_vis_opt_path_hides');
                                      v && a.setAttribute('nonce', v.nonce);
                                      a.setAttribute('type', 'text/css');
                                      if (a.styleSheet) a.styleSheet.cssText = b;
                                      else a.appendChild(d.createTextNode(b));
                                      h.appendChild(a);
                                  } else {
                                      a = d.getElementsByTagName('head')[0];
                                      var b = d.createElement('div');
                                      b.style.cssText =
                                          'z-index: 2147483647 !important;position: fixed !important;left: 0 !important;top: 0 !important;width: 100% !important;height: 100% !important;background: white !important;';
                                      b.setAttribute('id', '_vis_opt_path_hides');
                                      b.classList.add('_vis_hide_layer');
                                      a.parentNode.insertBefore(b, a.nextSibling);
                                  }
                              }
                              if (filterConfig && filterConfig.filterTime === 'early') {
                                  this.removeLoaderAndOverlay();
                              }
                              var j =
                                  'https://dev.visualwebsiteoptimizer.com/j.php?a=' +
                                  account_id +
                                  '&u=' +
                                  encodeURIComponent(d.URL) +
                                  '&vn=' +
                                  version;
                              if (w.location.search.indexOf('_vwo_xhr') !== -1) {
                                  this.addScript({ src: j });
                              } else {
                                  this.load(j + '&x=true');
                              }
                          },
                          setFilterConfigAndApplyFilter: function (config) {
                              if (!config) {
                                  // No config will lead to early return and no addition of smart code
                                  return;
                              }
                              var state = this.isNonLiveMode(account_id) || config.isConsentGiven();
                              if (state === '1') {
                                  // if cookie consent was already given and the cookies were already accepted, execute out normal flow
                                  hide_element = 'body';
                                  w._vwo_settings_timer = _vwo_code.init();
                                  return;
                              } else if (state === '2') {
                                  return;
                              }


                              if (!config.filterTolerance) {
                                  // Execute removal logic immediately when filterTolerance is 0 or empty
                                  return;
                              }


                              function clearIntervals(timerIds) {
                                  for (var i = 0; i < timerIds.length; i++) {
                                      clearInterval(timerIds[i]);
                                  }
                              }
                              this.filterConfig = config;
                              var popupTimer = setInterval(function () {
                                  if (_vwo_code.applyFilters(config)) {
                                      clearInterval(popupTimer);
                                  }
                              }, 100);
                              var timer = setInterval(function () {
                                  var state = config.isConsentGiven();
                                  var shouldClearAllIntervals;
                                  if (state === '1') {
                                      _vwo_code.showLoader();
                                      w._vwo_settings_timer = _vwo_code.init();
                                      shouldClearAllIntervals = true;
                                  } else if (state === '2') {
                                      _vwo_code.filterConfig = undefined;
                                      _vwo_code.removeLoaderAndOverlay();
                                      shouldClearAllIntervals = true;
                                  }
                                  if (shouldClearAllIntervals) {
                                      clearIntervals([timer, popupTimer]);
                                      clearInterval(filterToleranceTimer);
                                  }
                              }, 100);
                              var filterToleranceTimer;


                              // RECOMMENDED: When using Best Approach
                              filterToleranceTimer = setTimeout(function () {
                                  _vwo_code.removeLoaderAndOverlay();
                                  clearIntervals([timer, popupTimer]);
                              }, config.filterTolerance);
                          },
                          showLoader: function () {
                              var vwoOverlay = d.getElementsByClassName('vwo-overlay')[0];
                              if (!vwoOverlay) return;
                              var vwoContentLoader = d.createElement('div');
                              vwoContentLoader.classList.add('vwo-content-loader');
                              vwoOverlay.parentNode.insertBefore(vwoContentLoader, vwoOverlay.nextSibling);
                          },
                          applyFilters: function (config) {
                              var popup = d.querySelector(config.popupSelector);
                              var popupZIndex;
                              if (!popup && d.getElementById('_vis_opt_overlay')) {
                                  return;
                              }
                              var maxZIndex = 2147483647;
                              if (popup) {
                                  var popupStyle = w.getComputedStyle(popup);
                                  popupZIndex = popupStyle.getPropertyValue('z-index');
                                  if (!popupZIndex || popupZIndex === 'auto') {
                                      popupZIndex = maxZIndex;
                                  }
                                  popup.style.zIndex = popupZIndex;
                              }
                              popupZIndex = popupZIndex || maxZIndex;
                              // You can change the styling to suit your requirements
                              // This is the default CSS filters VWO provides
                              // NOTE: do not change any class names IMPORTANT ...
                              var vwoFilter =
                                      'position: fixed; top: 0; left: 0; right: 0; bottom: 0; height: 100%; width: 100%; -webkit-filter: blur(5px); filter: blur(5px); backdrop-filter: saturate(180%) blur(3px); -webkit-backdrop-filter: saturate(180%) blur(3px); z-index:' +
                                      (popupZIndex - 1) +
                                      ';',
                                  vwoLoaderCss =
                                      ' .vwo-content-loader{ border: 16px solid #f3f3f3; border-top: 16px solid #3498db; border-radius: 50%; width: 90px; height: 90px; position: fixed; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); animation: vwo-spin 2s linear infinite; z-index:' +
                                      (popupZIndex - 1) +
                                      '; }' +
                                      '@keyframes vwo-spin { 0% { -webkit-transform: translate(-50%, -50%) rotate(0deg); transform: translate(-50%, -50%) rotate(0deg); } 100% { -webkit-transform: translate(-50%, -50%) rotate(360deg); transform: translate(-50%, -50%) rotate(360deg); } }';
                              /**
                                * This below written code should not be tweaked
                                * */
                              var overlayStyleTag = d.getElementById('_vis_opt_overlay'),
                                  overlayCSS = config.preventBlur ? '' : '.vwo-overlay {' + vwoFilter + '}' + vwoLoaderCss;
                              if (overlayStyleTag) {
                                  if (overlayStyleTag.styleSheet) {
                                      overlayStyleTag.styleSheet.cssText = overlayCSS;
                                  } else {
                                      var _vwo_textNode = d.createTextNode(overlayCSS);
                                      overlayStyleTag.appendChild(_vwo_textNode);
                                      overlayStyleTag.removeChild(overlayStyleTag.childNodes[0]);
                                  }
                              } else {
                                  var a = d.createElement('style'),
                                      h = d.getElementsByTagName('head')[0],
                                      body = d.getElementsByTagName('body')[0];
                                  var vwoOverlay = d.createElement('div');
                                  vwoOverlay.classList.add('vwo-overlay');
                                  body.prepend(vwoOverlay);
                                  // CAUTION Do not remove this id from your style tag. It is required by VWO
                                  a.setAttribute('id', '_vis_opt_overlay');
                                  a.setAttribute('type', 'text/css');
                                  if (a.styleSheet) a.styleSheet.cssText = overlayCSS;
                                  else a.appendChild(d.createTextNode(overlayCSS));
                                  h.appendChild(a);
                              }
                              return !!popup;
                          },
                          removeLoaderAndOverlay: function () {
                              var overlay = d.getElementsByClassName('vwo-overlay');
                              var loader = d.getElementsByClassName('vwo-content-loader');
                              var overlayStyleTag = d.getElementById('_vis_opt_overlay');
                              overlay && (overlay = overlay[0]) && overlay.parentElement.removeChild(overlay);
                              loader && (loader = loader[0]) && loader.parentElement.removeChild(loader);
                              overlayStyleTag && overlayStyleTag.parentElement.removeChild(overlayStyleTag);
                          },
                          isNonLiveMode: function (accountId) {
                              var wName = w.name;
                              if (!wName) {
                                  return;
                              }
                              return (
                                  (wName.indexOf('_vis_editor') > -1 ||
                                      wName.indexOf('_vis_preview_' + accountId) > -1 ||
                                      wName.indexOf('_vis_heatmap_' + accountId) > -1) &&
                                  '1'
                              );
                          },
                      };
                      w._vwo_code = code;
                  })();


                _vwo_code.setFilterConfigAndApplyFilter({
                  popupSelector: '#usercentrics-root',
                  preventBlur: true,
                  filterTime: 'best',
                  isConsentGiven: function () {
                    if(!window.UC_UI){
                        return;
                    }
                    const vwoConsent = window.UC_UI?.getServicesBaseInfo()?.filter( ({ name, consent }) => name === "Visual Website Optimizer (VWO)" )[0]?.consent?.status;

                    if(vwoConsent){
                        return '1';
                    } else {
                        return '2';
                    }  
                  },
                  filterTolerance: 30000,
                });
      `,
  });

  window.VWO = window.VWO || [];
  window.VWO.event = window.VWO.event || function vwoEvent(...args) { window.VWO.push(['event'].concat([].slice.call(args))); };
});
