import { defineNuxtMiddleware } from '@nuxtjs/composition-api';

import { expectedErrorCodes } from '~/composables/custom/usePayment/usePayment';

export default defineNuxtMiddleware(async ({ app, route, redirect }) => {
  const { name, query } = route;

  const { errorCode = '' } = route.query || {};
  const errorCodes: string[] = typeof errorCode === 'string' ? errorCode?.split(',') : errorCode;

  /* FIXME Error codes are validated in order to certify that checkout payment failed,
  * FIXME there is a problem where not always the customer access token of CT comes via cookies in the request making the activeCart to be nul,
  * FIXME the only way to assure that on payment error in this route interceptor won't redirect to home-page is by checking the query params error code
  */
  if (name === 'thank-you' || (query.orderNumber && errorCodes.some((code) => expectedErrorCodes.includes(code)))) {
    return;
  }

  const { data } = await app.$vsf.$ct.api.getMe();

  if (data?.me?.activeCart) {
    return;
  }
  redirect('/');
});
