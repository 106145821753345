
import { SfIcon, SfButton, SfLink } from '@storefront-ui/vue';
import {computed, defineComponent, toRef, useRoute} from "@nuxtjs/composition-api";

export default defineComponent({
  components: {
    SfIcon,
    SfLink,
    SfButton,
  },
  props: {
    /**
     * Total number of pages
     */
    total: {
      type: Number,
      required: true,
    },
    /**
     * Maximum visible pagination items
     */
    visible: {
      type: Number,
      required: false,
      default: 5,
    },
    /**
     * Status of arrows display
     */
    hasArrows: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Current page number, for non router
     */
    current: {
      type: Number,
      required: false,
      default: 1,
    },
    /**
     * Name of page query param for router
     */
    pageParamName: {
      type: String,
      required: false,
      default: 'page',
    },
  },
  emits: ['click:navigate'],
  setup(props, { emit }) {

    const route = useRoute();

    const currentPage = toRef(props, 'current');
    const visible = toRef(props, 'visible');
    const total = toRef(props, 'total');

    const listOfPageNumbers = computed(() => Array.from(Array(total.value), (_, i) => i + 1));

    const limitedPageNumbers = computed(() => {
      if (total.value <= visible.value) {
        return listOfPageNumbers.value;
      }
      if (currentPage.value < visible.value - Math.floor(visible.value / 2) + 1) {
        return listOfPageNumbers.value.slice(0, visible.value);
      }
      if (
          total.value - currentPage.value
          < visible.value - Math.ceil(visible.value / 2) + 1
      ) {
        return listOfPageNumbers.value?.slice(total.value - visible.value);
      }
      return listOfPageNumbers.value.slice(
          currentPage.value - Math.ceil(visible.value / 2),
          currentPage.value + Math.floor(visible.value / 2),
      );
    });

    const firstVisiblePageNumber = computed(() => limitedPageNumbers.value[0]);

    const lastVisiblePageNumber = computed(() => limitedPageNumbers.value[limitedPageNumbers.value.length - 1]);

    const getLinkTo = (page) => {
      let {query} = route.value;

      if (process.client) {
        const searchParams = new URLSearchParams(window.location.search);
        query = Object.fromEntries(searchParams)
      }

      return {
        ...route.value,
        query: {...query, [props.pageParamName]: page},
      };
    }

    const onNavigateTo = (page) => {
      emit('click:navigate', page);
    }

    return {
      getLinkTo,
      onNavigateTo,
      componentIs: 'SfLink',
      currentPage,
      getPrev: computed(() => currentPage.value === firstVisiblePageNumber.value ? currentPage.value : currentPage.value - 1),
      canGoPrev: computed(() => currentPage.value !== firstVisiblePageNumber.value),
      canGoNext: computed(() => currentPage.value !== lastVisiblePageNumber.value),
      getNext: computed(() => currentPage.value === lastVisiblePageNumber.value ? currentPage.value : currentPage.value + 1),
      showFirst: computed(() => firstVisiblePageNumber.value > 1),
      showLast: computed(() => lastVisiblePageNumber.value < total.value),
      lastVisiblePageNumber,
      limitedPageNumbers,
      listOfPageNumbers,
      firstVisiblePageNumber,
    };
  }
});

