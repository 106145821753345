// FIXME
// @ts-nocheck
import { useShippingProvider as useVSFShippingProvider } from '@vue-storefront/commercetools';
import {
  computed, reactive, ref, shallowRef, useContext,
} from '@nuxtjs/composition-api';
import { Address, ShippingMethod } from '@vue-storefront/commercetools-api';

import { useCart } from '~/composables/custom/useCart/useCart';
import useUiNotification from '~/composables/useUiNotification';
import { useTracking } from '~/composables/custom/useTracking';
import { useBFF } from '~/composables/useBFF/useBFF';

const updating = ref(false);

export const useShippingProvider = () => {
  const { $ct } = useBFF();
  const { i18n, $flags } = useContext();
  const error = reactive({
    save: undefined,
    load: undefined,
  });
  const {
    load, state, setState,
  } = useVSFShippingProvider();
  const { send } = useUiNotification();
  const { cart, refresh, setCart } = useCart();
  const { trackError } = useTracking();

  const loadingByCountry = ref(false);
  const shippingMethodsByCountry = shallowRef<ShippingMethod[]>(undefined);

  const loadingByCart = shallowRef(false);
  const shippingMethodsByCart = shallowRef<ShippingMethod[]>(undefined);

  const loadShippingMethodByCountry = async () => {
    loadingByCountry.value = true;
    try {
      const { data, errors } = await $ct.getShippingMethodsByCountry();

      if (errors?.length) {
        trackError('not able to fetch shipping methods for country');
        return;
      }

      shippingMethodsByCountry.value = data;
    } finally {
      loadingByCountry.value = false;
    }
  };

  const loadShippingMethodByCart = async () => {
    if (shippingMethodsByCart.value) {
      return;
    }

    if (!cart.value) {
      await refresh();
    }

    error.load = undefined;
    loadingByCart.value = true;
    try {
      const { data, errors } = await $ct.getShippingMethodsByCart(cart.value?.id, $flags.has('enabledKiosk'));

      if (errors?.length) {
        error.load = errors?.join();

        trackError('not able to fetch shipping methods for cart');

        send({
          message: 'There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details or later.',
          type: 'danger',
        });

        return;
      }
      shippingMethodsByCart.value = data;
    } finally {
      loadingByCart.value = false;
    }
  };

  const save = async (shippingMethod: ShippingMethod, address?: Address) => {
    const { id, version } = cart.value || {};

    if (!id || !version) {
      return;
    }

    updating.value = true;
    error.save = undefined;

    try {
      const { data, errors } = await $ct.saveShippingMethod({
        id,
        version,
        address,
        shippingMethod,
      });

      if (errors?.length) {
        error.save = errors;
        const message = 'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method or later.';
        trackError(message);
        send({
          message: i18n.t(message).toString(),
          type: 'danger',
        });
      }

      if (data?.cart) {
        await setCart(data.cart);
      }
    } finally {
      updating.value = false;
    }
  };

  return {
    setState,
    state,
    save,
    load,
    updating: computed(() => updating.value),
    error,
    loadingByCountry: computed(() => loadingByCountry.value),
    loadShippingMethodByCountry,
    loadShippingMethodByCart,
    loadingByCart: computed(() => loadingByCart.value),
    shippingMethodsByCountry: computed(() => shippingMethodsByCountry.value),
    shippingMethodsByCart: computed(() => shippingMethodsByCart.value),
  };
};
