
import Vue from 'vue';
import { InputBuilder, WrapperProps } from './Input.model';
import KikInput from './Input.vue';

export default Vue.extend({
  name: 'InputWrapper',
  components: {
    KikInput,
  },
  props: WrapperProps,

  data(): Object {
    return {
      mgnlProps: InputBuilder.build(this.$props),
    };
  },
});
