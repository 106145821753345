const getters = {
  isKikEmployee: (customerGroup) => customerGroup?.key === 'MAKIK' ?? false,
  isBigBuyer: (customerGroup) => customerGroup?.key === 'GRKUNDEN' ?? false,
  getDiscountLabel(customerGroup) {
    if (this.isKikEmployee(customerGroup)) {
      return 'KiK employee discount';
    }

    return 'Discount savings';
  },
};

module.exports = getters;
