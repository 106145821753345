import { defineNuxtPlugin } from '@nuxtjs/composition-api';

// these HTTP methods do not require CSRF protection
const csrfSafeMethod = (method) => (/^(GET|HEAD|OPTIONS)$/.test(method));

export default defineNuxtPlugin(async ({ app, isDev }) => {
  const { csrfToken: token } = await app.$vsf.$ct.api.csrfToken();

  return app.$vsf.$ct.client.interceptors.request.use((config: Request) => {
    if (csrfSafeMethod(config.method)) {
      return config;
    }

    if (!config.headers['X-CSRF-Token'] && token && !isDev) {
      // eslint-disable-next-line no-param-reassign
      config.headers['X-CSRF-Token'] = token;
    }

    return config;
  }, (error) => Promise.reject(error));
});
