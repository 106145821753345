// FIXME

import { sharedRef } from '@vue-storefront/core';
import {
  computed, Ref, useContext, useRoute,
} from '@nuxtjs/composition-api';

import categoryBreadCrumbs, { CategoryBreadCrumbs } from '~/helpers/utils/categoryBreadCrumbs';

import { useNavbarCategories } from '~/composables/custom/useNavbarCategories';
import { useProduct } from '~/composables/custom/useProduct/useProduct';
import { urlGetters } from '~/composables/getters';
import { useCategory } from '../custom/useCategory';

export const useBreadcrumbs = () => {
  const route = useRoute();
  const { result: categories } = useNavbarCategories();
  const { result: category } = useCategory();
  const { product } = useProduct(route.value.params.id);
  const { i18n } = useContext();

  const breadcrumbs: Ref<CategoryBreadCrumbs[]> = sharedRef([], 'breadcrumbs');
  const breadcrumbsToDisplay: Ref<{ link?: string, text: string }[]> = sharedRef([], 'breadcrumbsToDisplay');

  const { isSale } = route.value.meta;

  const getCatLink = (slugs): string => {
    const { query, meta } = route.value;

    const getNavigationUrl = urlGetters.getCategoryNavigationByMeta(meta, i18n);

    if (!query) {
      return `${getNavigationUrl}/${slugs.join('/')}`;
    }
    return `${getNavigationUrl}/${slugs.join('/')}?${Object.entries(query).map(([key, value]) => `${key}=${value}`).join('')}`;
  };

  const build = () => {
    const {
      slug_1: slug1,
      slug_2: slug2,
      slug_3: slug3,
      slug_4: slug4,
    } = route.value.params;

    const currentSlugs = [slug4, slug3, slug2, slug1].filter(Boolean);

    let value = route.value.query.categoryId as string;
    let property = 'id';

    if (!value) {
      [value] = currentSlugs;
      property = 'slug';
    }

    breadcrumbs.value = categoryBreadCrumbs(categories.value, property, value) || [];

    breadcrumbsToDisplay.value = breadcrumbs.value.map(({ name, slug }, i) => {
      const slugs = [breadcrumbs.value[0].slug];

      if (i > 0) {
        slugs.push(slug);
      }

      return ({
        index: i,
        text: name,
        link: getCatLink(slugs),
      });
    });
  };

  const currentBreadcrumb = computed(() => {
    const details = category.value;

    const titlesAndDescriptions = {
      metaTitle: details?.metaTitle,
      metaDescription: details?.metaDescription,
      headlineTitle: details?.headlineTitle ?? details?.name,
      description: details?.description ?? details?.name,
    };

    if (isSale) {
      const saleTranslation = i18n.t('Sale');
      titlesAndDescriptions.metaTitle = details?.saleMetadata.metaTitle ?? `${saleTranslation}: ${titlesAndDescriptions.headlineTitle}`;
      titlesAndDescriptions.metaDescription = details?.saleMetadata.metaDescription ?? `${saleTranslation}: ${titlesAndDescriptions.description}`;
      titlesAndDescriptions.headlineTitle = details?.saleMetadata.headline ?? `${saleTranslation}: ${titlesAndDescriptions.headlineTitle}`;
      titlesAndDescriptions.description = details?.saleMetadata.description; // no fallback needed
    }

    return {
      ...titlesAndDescriptions,
      canonicalUrl: details?.canonicalUrl,
      category: (categories?.[0]?.value ? breadcrumbs.value?.find(({ id }) => id === categories?.[0].value) : details),
    };
  });

  return {
    currentBreadcrumb: computed(() => currentBreadcrumb.value),
    breadcrumbs: computed(() => breadcrumbs.value),
    breadcrumbsToDisplay: computed(() => [
      ...breadcrumbsToDisplay.value,
      ...(product.value?.name ? [{ text: product.value?.name }] : []),
    ]),

    build,
  };
};
