import Vue from 'vue';
import { defineNuxtPlugin, nextTick } from '@nuxtjs/composition-api';

export interface FeatureFlagsPlugin {
  flags: string[];
  has(flags: string | string[]): boolean;
  hasnt(flags: string | string[]): boolean;
  some(prefix: string): boolean;
}

// @ts-nocheck
Vue.directive('flags', {
  inserted: (el, { arg, value }, vnode) => {
    if (!vnode.context.$flags[arg]) {
      const availableArgs = Object.entries(vnode.context.$flags).filter(([, v]) => typeof v === 'function').map(([key]) => key);
      throw new TypeError(`v-flags:${arg} is not valid. Valid options: ${availableArgs.join(', ')}`);
    }

    const shouldDisplay = vnode.context.$flags[arg](value);

    if (shouldDisplay) {
      return;
    }

    nextTick().then(() => {
      el.parentElement?.removeChild(el);
    });
  },
});
// @ts-check

export default defineNuxtPlugin(async ({ store }, inject) => {
  if (!store.getters['featureFlags/getFlags']?.length) {
    await store.dispatch('featureFlags/fetch');
  }

  const featureFlags = {
    flags: store.getters['featureFlags/getFlags'],
    some(prefix: string) {
      return this.flags.some((flag: string) => flag.startsWith(prefix));
    },
    has(flag: string | string[]) {
      if (typeof flag === 'string') {
        return this.flags.includes(flag);
      }

      if (Array.isArray(flag)) {
        if (flag.some((f) => typeof f !== 'string')) {
          throw new TypeError('Flags must be strings');
        }

        return flag.every((f) => this.flags.includes(f));
      }

      return false;
    },
    hasnt(flag: string | string[]) {
      return !this.has(flag);
    },
  };

  inject('flags', featureFlags);
});
