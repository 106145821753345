import { NuxtI18nInstance } from '@nuxtjs/i18n';

const getCategoryNavigationByType = (type: 'new' | 'sale' | undefined, i18n: NuxtI18nInstance) => {
  if (!type) {
    return '/c';
  }
  if (type === 'new') {
    return `/c/n/${i18n.t('New').toString().toLowerCase()}`;
  }
  return `/c/s/${i18n.t('Sale').toString().toLowerCase()}`;
};

export default {
  getCategoryNavigationByType,
  getCategoryNavigationByMeta: (meta, i18n: NuxtI18nInstance) => {
    const { isNew, isSale } = meta || {};
    // eslint-disable-next-line no-nested-ternary
    return getCategoryNavigationByType(isNew ? 'new' : (isSale ? 'sale' : undefined), i18n);
  },
};
