const getDefaultTranslations = async (locale) => import(`./translations/${locale}.json`).then((module) => module.default);

export default async (context, locale) => {
  const { $config } = context;

  if ($config.phrase.enabled === 'false' || context?.route?.query?.phraseLocal !== undefined) {
    return getDefaultTranslations(locale);
  }

  try {
    const response = await fetch(
      `https://api.phrase.com/v2/projects/${$config.phrase.projectId}/locales/${locale}/download?file_format=simple_json`,
      {
        method: 'GET',
        headers: {
          accept: 'application/json',
          Authorization: `token ${$config.phrase.token}`,
        },
        body: null,
      },
    );

    if (!response.ok) {
      return getDefaultTranslations(locale);
    }

    return await response.json();
  } catch (e) {
    return getDefaultTranslations(locale);
  }
};
