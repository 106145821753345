import { ref, useRouter } from '@nuxtjs/composition-api';
import { Me } from '@vue-storefront/commercetools-api';

import { useMe } from '~/composables/custom/useMe';
import { useCart } from '~/composables/custom/useCart/useCart';
import { useBFF } from '~/composables/useBFF/useBFF';
import { useUser } from '~/composables/custom/useUser/useUser';
import { useUiState } from '~/composables';

export default function useLegacyLogin() {
  const { $ct } = useBFF();
  const { setCart } = useCart();
  const { setUser, load } = useUser();
  const { load: loadCustomerGroup } = useMe();
  const router = useRouter();
  const {
    isLoginModalOpen,
    toggleLoginModal,
  } = useUiState();

  const loading = ref(false);
  const error = ref<string | undefined | string[]>();

  interface LoginParams {
    user: {
      username: string;
      password: string
    };
  }

  const userLogin = async ({ username, password }) => {
    loading.value = true;
    error.value = undefined;
    try {
      const { data, errors } = await $ct.customerSignMeIn<{ user: Me }, { code: string, message: string }[]>({ email: username, password, activeCartSignInMode: 'UseAsNewActiveCustomerCart' });

      if (errors.length) {
        throw new Error(errors.join('. '));
      }

      const { cart, customer } = data?.user || {};
      setUser(customer);
      setCart(cart);

      await load();
      await loadCustomerGroup();

      if (isLoginModalOpen.value) {
        toggleLoginModal();
        if (router.currentRoute.query.redirectTo) {
          router.push(router.currentRoute.query.redirectTo as string);
        }
      }
    } catch (err) {
      error.value = err.message;
    } finally {
      loading.value = false;
    }
  };

  /**
   * @deprecated Use userLogin instead
   *
   * @param userForm
   */
  const login = async (userForm: LoginParams) => {
    await userLogin({ username: userForm.user.username, password: userForm.user.password });
  };

  return {
    loading,
    error,
    login,
    userLogin,
  };
}
