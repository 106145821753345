import { Logger } from '~/apiExtensions/utils';

export default {
  dateIsInFuture: (date: Date) => date >= new Date(),
  // https://stackoverflow.com/a/66858753
  dateFormatForPlaceholder: (locale: string, localizedDate: any = { day: 'd', month: 'm', year: 'y' }, isMasked: boolean = false) => {
    const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
      switch (part.type) {
        case 'day':
          return (isMasked ? '#' : localizedDate.day).repeat(part.value.length);
        case 'month':
          return (isMasked ? '#' : localizedDate.month).repeat(part.value.length);
        case 'year':
          return (isMasked ? '#' : localizedDate.year).repeat(part.value.length);
        case 'literal':
          return part.value;
        default:
          Logger.warn('Unsupported date part', part);
          return '';
      }
    };

    return new Intl.DateTimeFormat(locale).formatToParts(new Date('2021-10-10'))
      .map(getPatternForPart)
      .join('');
  },
};
