const getCompanyName = (address) => address?.custom?.customFieldsRaw?.find(({ name }) => name === 'companyName')?.value;
const getVAT = (address) => address?.custom?.customFieldsRaw?.find(({ name }) => name === 'vatId')?.value;
const isCompanyAddress = (address) => address.userType === 'company' || address.companyName?.length || getCompanyName(address)?.length;
const isCreatingPackstationAddress = ({ userType }) => userType === 'packstation';
const isPackstationAddress = (address) => (
  address?.custom?.customFieldsRaw?.some(((customField) => customField.name === 'addressType' && customField.value === 'packstation'))
    // next line should be removed whenever custom type "type" is created and in use
    || address?.custom?.customFieldsRaw?.some(((customField) => customField.name === 'packstationNumber'))
);
const packstationRelevantFields = (address) => ({
  pickupLocationInformation: address.custom?.customFieldsRaw?.find((field) => field.name === 'pickupLocationInformation')?.value,
  packstationPostNumber: address.custom?.customFieldsRaw?.find((field) => field.name === 'packstationPostNumber')?.value,
  packstationNumber: address.custom?.customFieldsRaw?.find((field) => field.name === 'packstationNumber')?.value,
});
const isKioskAddress = (address) => address.userType === 'kiosk';

const addressGetters = {
  getCompanyName,
  getVAT,
  isCompanyAddress,
  isPackstationAddress,
  isKioskAddress,
  existsAddressCustomType: (address) => address?.custom?.customFieldsRaw?.length > 0,
  packstationRelevantFields,
  transformToFormAddress: (address) => {
    if (!address) {
      return undefined;
    }

    let userType = 'personal';
    let packstationFields = {};

    if (isPackstationAddress(address) || isCreatingPackstationAddress(address)) {
      userType = 'packstation';
      if (address.custom?.customFieldsRaw) {
        packstationFields = packstationRelevantFields(address);
      }
    } else if (isCompanyAddress(address)) {
      userType = 'company';
    } else if (isKioskAddress(address)) {
      userType = 'kiosk';
    }

    const isCompany = isCompanyAddress(address);
    return {
      ...address,
      userType,
      ...packstationFields,
      companyName: isCompany ? (address.companyName ?? getCompanyName(address)) : undefined,
      vatId: isCompany ? (address.vatId ?? getVAT(address)) : undefined,
    };
  },
  packStationFieldsPerCountry: (country) => {
    if (country?.toLowerCase() === 'de') {
      return ['userType', 'salutation', 'title', 'firstName', 'lastName', 'packstationPostNumber', 'packstationNumber', 'postalCode', 'city', 'country', 'mobile', 'phone'];
    }
    if (country?.toLowerCase() === 'at') {
      return ['userType', 'firstName', 'lastName', 'streetName', 'streetNumber', 'additionalStreetInfo', 'postalCode', 'city', 'country', 'mobile', 'pickupLocationInformation'];
    }

    return ['userType', 'salutation', 'title', 'firstName', 'lastName', 'streetName', 'streetNumber', 'additionalStreetInfo', 'postalCode', 'city', 'country', 'mobile', 'phone'];
  },
};

module.exports = { addressGetters };
