const mapOfErrorMessages = {
  emailAlreadyUsedInShopRegisterInLoyaltyAfterLogin: 'emailAlreadyUsedInShopRegisterInLoyaltyAfterLogin',
  unknownCardNumber: 'unknownCardNumber',
  customerCreationInLEFailed: 'An Error occured',
  customerNeedsAnotherEmail: 'existingCustomerinCRM',
  customerNotFoundUseLoyaltyRegistration: 'customerNotFoundUseLoyaltyRegistration',
  emailAlreadyUsedInShop: 'emailAlreadyUsedInShop',
  customerAlreadyExistsInCRM: 'customerAlreadyExistsInCRM',
  loyaltyCardNumberOrBirthdateNotValid: 'loyaltyCardNumberOrBirthdateNotValid',
  customerMasterDataFoundOnDifferentUser: 'customerMasterDataFoundOnDifferentUser',
  customerWithMasterDataFoundInCRM: 'customerWithMasterDataFoundInCRM',
  unknownLoyaltyCardNumber: 'unknownLoyaltyCardNumber',
  customersDataDoesNotMatchDataInCRM: 'customersDataDoesNotMatchDataInCRM',
  customerNotFoundInCRM: 'customerNotFoundInCRM',
  'loyalty.crm-validation-failed': 'loyaltyCardNumberOrBirthdateNotValid',
  'customer-registration.email-in-use': 'emailAlreadyUsedInShopRegisterInLoyaltyAfterLogin',
  'customer-registration.master-data-found': 'customerWithMasterDataFoundInCRM',
  'loyalty-registration.billing-address-required': 'An Error occured',
  'loyalty-registration.age-validation-failed': 'An Error occured',
  'loyalty-registration.crm-registration-failed': 'An Error occured',
  'loyalty-registration.crm-customer-already-exists': 'customerAlreadyExistsInCRM',
  'loyalty-registration.crm-data-mismatch': 'customersDataDoesNotMatchDataInCRM',
  'loyalty-registration.unknown-loyalty-card-number': 'loyaltyCardNumberOrBirthdateNotValid',
  paymentPspTransactionDeclined: 'paymentPspDeclined',
  paymentPspIncorrectData: 'paymentPspDeclined',
  changedPrice: 'changedPriceError',
  reducedStock: 'reducedStockError',
  removedProduct: 'removedProductError',
  discountCodeNotValid: 'discountCodeNotValidError',
  priceMismatchAfterRecalculate: 'priceMismatchAfterRecalculate',
  default: 'An error occured',
};

const getErrorMessage = (error: unknown) => {
  if (!error || typeof error !== 'string') {
    return mapOfErrorMessages.default;
  }

  const foundErrorMessage = Object.keys(mapOfErrorMessages).find((key: string) => error.includes(key));

  return foundErrorMessage ? mapOfErrorMessages[foundErrorMessage] : mapOfErrorMessages.default;
};

export {
  getErrorMessage,
};
