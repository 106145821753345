import {
  computed,
  onMounted,
  onUnmounted,
  ref,
} from '@nuxtjs/composition-api';

export const useToTop = () => {
  const showScrollToTop = ref(false);

  const toTopClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const checkScrollToBottomVisibility = () => {
    showScrollToTop.value = window.scrollY > 10;
  };

  onMounted(async () => {
    checkScrollToBottomVisibility();
    window.addEventListener('scroll', checkScrollToBottomVisibility);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', checkScrollToBottomVisibility);
  });

  return {
    toTopClick,
    showScrollToTop: computed(() => showScrollToTop.value),
  };
};
