var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sf-steps"},[_c('div',{staticClass:"sf-steps__header"},[_vm._l((_vm.parsedSteps),function(step){return _vm._t("steps",function(){return [_c('SfButton',{key:step.index,staticClass:"sf-button--pure",class:{
          'sf-steps__step': true,
          'is-done': step.done,
          current: step.current,
          'is-disabled': step.disabled,
          inactive: step.inactive,
        },on:{"click":function($event){return _vm.stepClick(step)}}},[_c('span',{staticClass:"sf-steps__title"},[_vm._v(_vm._s(step.step))])])]},{"stepClick":_vm.stepClick},{ step })}),_vm._v(" "),_c('div',{staticClass:"sf-steps__progress",style:(_vm.progress)})],2),_vm._v(" "),_c('div',{staticClass:"sf-steps__content"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }