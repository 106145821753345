import { computed, Ref, useContext } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';

import { ShippingMethodInfo, ShippingType } from '~/types/shipping-methods';

import { useShippingProvider } from '~/composables/custom/useShippingProvider';
import useCheckoutForm from '~/composables/custom/useCheckoutForm';
import { useCheckout } from '~/composables/custom/useCheckout';
import { CheckoutNavigationStatus } from './useCheckoutNavigation';
import { useUser } from '~/composables/custom/useUser/useUser';
import { useCart } from '~/composables/custom/useCart/useCart';
import { shippingMethodGetters } from '~/composables/getters';

export const useBillingNavigation = () => {
  const {
    updating: savingShipping,
    shippingMethodsByCart: shippingMethods,
  } = useShippingProvider();
  const { cart } = useCart();
  const shippingMethod = sharedRef(undefined, 'shipping-method');
  const billingAddress = sharedRef(undefined, 'billing-address');
  const { error: errorUser, updating: updatingCustomer } = useUser();
  const { isVisible } = useCheckoutForm('billing');
  const { updating, errors, resetErrors } = useCheckout();
  const { $domain } = useContext();

  const hasErrors = computed(() => errorUser.value.saveBirthDate || errorUser.value.login || errors.value);

  const nextState: Ref<CheckoutNavigationStatus> = computed(() => {
    if (savingShipping.value || updating.value || updatingCustomer.value) {
      return CheckoutNavigationStatus.LOADING;
    }

    const selectedShippingMethod = shippingMethods.value?.find((method) => method.id === cart.value.shippingInfo?.shippingMethod?.id);

    if (
      hasErrors.value
      || !shippingMethod.value?.key
      || (!billingAddress.value && !isVisible.value)
      || (shippingMethod.value.key === ShippingType.SHIPPING_SAME && billingAddress.value?.country !== $domain.countryCode)
      || (shippingMethodGetters.isClickAndCollect(selectedShippingMethod as ShippingMethodInfo) && !shippingMethodGetters.isShippingAvailable(selectedShippingMethod as ShippingMethodInfo, cart.value)
      )) {
      return CheckoutNavigationStatus.MISSING_INFO;
    }

    return shippingMethod.value.key === ShippingType.SHIPPING_SAME ? CheckoutNavigationStatus.TO_PAYMENT : CheckoutNavigationStatus.TO_SHIPPING;
  });

  return {
    nextState: computed(() => nextState.value),
    shippingMethod: computed(() => shippingMethod.value),
    billingAddress: computed(() => billingAddress.value),
    setShippingMethod: (value) => { if (shippingMethod.value) { resetErrors(); } shippingMethod.value = value; },
    setBillingAddress: (value) => { billingAddress.value = value; },
  };
};
