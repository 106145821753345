import { computed, Ref, useContext } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';

import { CheckoutNavigationStatus } from '~/composables/custom/useCheckoutNavigation/useCheckoutNavigation';
import { useCart } from '~/composables/custom/useCart/useCart';
import { useShippingProvider } from '~/composables/custom/useShippingProvider';
import { usePayment, PaymentMethod } from '~/composables/custom/usePayment/usePayment';
import { paymentGetters, shippingMethodGetters } from '~/composables/getters';
import { ShippingMethodInfo } from '~/types/shipping-methods';

export const usePaymentNavigation = () => {
  const selectedPaymentOption: Ref<PaymentMethod> = sharedRef({} as PaymentMethod, 'selected-payment');
  const isValidDate: Ref<boolean> = sharedRef(true, 'is-valid-date');

  const {
    updating, loading: cartLoading, cart,
  } = useCart();
  const {
    loading: loadingPayment, errors: paymentErrors, resetError: resetPaymentErrors,
  } = usePayment();
  const { $config } = useContext();

  const {
    shippingMethodsByCart: shippingMethods,
  } = useShippingProvider();

  const paymentMethods = $config.payment.methods;

  const updatesOnPaymentAllowed = computed(() => !updating.value && !loadingPayment.value);

  const canProceedAfterPayment = computed(() => {
    const noPaymentErrors = !Object.values(paymentErrors.value).filter(Boolean).length;
    return noPaymentErrors && !cartLoading.value && selectedPaymentOption.value.provider !== undefined;
  });

  const hasMissingAddresses = computed(() => !cart.value?.shippingAddress?.firstName || !cart.value?.billingAddress?.firstName);

  const selectedShippingMethod = computed(() => shippingMethods.value?.find((method) => method.id === cart.value?.shippingInfo?.shippingMethod?.id));
  const shouldRestart = computed(() => (
    hasMissingAddresses.value
    || (shippingMethodGetters.isClickAndCollect(selectedShippingMethod.value as ShippingMethodInfo) && !shippingMethodGetters.isShippingAvailable(selectedShippingMethod.value as ShippingMethodInfo, cart.value))
  ));

  const hasMissingMandatoryInfoForUnzer = computed(() => {
    const activePaymentMethod = paymentGetters.getActivePaymentMethod(cart.value?.paymentInfo);
    if (activePaymentMethod?.paymentMethodInfo.method !== paymentMethods.INVOICE) {
      return false;
    }

    if (cart.value?.customer) {
      return !cart.value.customer.dateOfBirth;
    }

    return !cart.value?.billingAddress?.custom?.customFieldsRaw?.some((field) => field.name === 'dateOfBirth');
  });

  const missingCartInfo = computed(() => !cart.value?.paymentInfo || hasMissingMandatoryInfoForUnzer.value);

  const nextState = computed(() => {
    if (!updatesOnPaymentAllowed.value) {
      return CheckoutNavigationStatus.LOADING;
    }

    if (!cart.value || shouldRestart.value || !canProceedAfterPayment.value || missingCartInfo.value) {
      return CheckoutNavigationStatus.MISSING_INFO;
    }

    const { method } = selectedPaymentOption.value || {};
    if (![paymentMethods.FILIALE, paymentMethods.VORKASSE, paymentMethods.INVOICE].includes(method)) {
      return CheckoutNavigationStatus.TO_PAYMENT;
    }

    const isInvoiceReady = !hasMissingMandatoryInfoForUnzer.value && isValidDate.value;
    const otherPaymentMethods = method && [paymentMethods.FILIALE, paymentMethods.VORKASSE].includes(method);
    if (isInvoiceReady || otherPaymentMethods) {
      return CheckoutNavigationStatus.TO_ORDER;
    }

    return CheckoutNavigationStatus.MISSING_INFO;
  });

  const resetErrors = () => {
    resetPaymentErrors('loadPayments');
    resetPaymentErrors('addPaymentOption');
    resetPaymentErrors('createPaymentOrder');
  };

  const setPaymentMethod = (paymentOption) => {
    if (paymentOption
      && selectedPaymentOption.value?.method === paymentOption?.method
      && selectedPaymentOption.value?.provider === paymentOption?.provider
    ) {
      return;
    }

    resetErrors();

    selectedPaymentOption.value = paymentOption;
  };
  const setIsValidDate = (valid) => {
    isValidDate.value = valid;
  };
  return {
    shouldRestart,
    setPaymentMethod,
    setIsValidDate,
    paymentOption: computed(() => selectedPaymentOption.value),
    nextState: computed(() => nextState.value),
  };
};
