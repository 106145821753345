export const state = () => ({
  name: 'kik-root-store-state',
});

export const getters = {

};

export const actions = {

};

export const mutations = {

};
