import { defineNuxtMiddleware } from '@nuxtjs/composition-api';

export default defineNuxtMiddleware(() => {
  // if (!app.head) {
  //   return;
  // }

  // const links = app.head().link.filter(({ hid }) => !hid?.startsWith('hreflang')); // filter out the previous ones

  // links.push(...$config.mainCountryCodes.map(({ host, locales }) => ({
  //   rel: 'alternate',
  //   hid: `hreflang-${locales[0]}`,
  //   href: `${host}${route.path}`,
  //   hreflang: locales[0],
  // })));

  // // eslint-disable-next-line no-param-reassign
  // app.head().link = links;
});
