import { sharedRef } from '@vue-storefront/core';
import {
  Ref,
  computed,
  ref,
} from '@nuxtjs/composition-api';
import { Category } from '@vue-storefront/commercetools-api';

import { useBFF } from '~/composables/useBFF/useBFF';

export const categoryGetters = {
  getId: (category: Category) => category?.id,
  getName: (category: Category) => category?.name,
  getSlug: (category: Category) => category?.slug,
  getCanonical: (category: Category & { canonicalUrl?: string }) => category?.canonicalUrl,
};

export interface NavbarCategory {
  name: string;
  slug: string;
  id: string;
  key: string;
  hideChartSize?: boolean;
  childrenItems?: NavbarCategory[] | null;
}

const flattenCategories = (childrenItems: NavbarCategory[]) => (
  childrenItems?.reduce((acc, category) => {
    acc[category.slug] = category.key;
    if (category.childrenItems) {
      return { ...acc, ...flattenCategories(category.childrenItems) };
    }
    return acc;
  }, {})
);

export const useNavbarCategories = () => {
  const { $ct } = useBFF();

  const result: Ref<NavbarCategory[]> = sharedRef(undefined, 'useNavbarCategories');
  const loading = ref(false);
  const error = ref({ load: null });

  const load = async () => {
    loading.value = true;
    try {
      const { data: categories, errors } = await $ct.getNavbarCategories();

      if (errors.length) {
        throw new Error(errors.join(''));
      }

      result.value = categories.childrenItems;

      error.value.load = null;
    } catch (err) {
      error.value.load = true;
    } finally {
      loading.value = false;
    }
  };

  return {
    result: computed(() => result.value),
    flatCategories: computed(() => flattenCategories(result.value)),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    load,
  };
};
