import { sharedRef } from '@vue-storefront/core';
import { computed, ref, useContext } from '@nuxtjs/composition-api';

import { Logger } from '~/apiExtensions/utils';
import { useBFF } from '~/composables/useBFF/useBFF';

export const usePageLoader = () => {
  const { $magnolia } = useBFF();
  const { $config, $domain } = useContext();
  const result = sharedRef(undefined, 'usePageLoader-result');
  const errorPage = sharedRef(undefined, 'usePageloader-errorPage');
  const loading = ref(false);
  const error = ref({
    content: false,
    contentStatusCode: undefined,
    header: false,
    preFooter: false,
    footer: false,
  });

  const header = sharedRef(undefined, 'usePageLoader-header-result');
  const preFooter = sharedRef(undefined, 'usePageLoader-pre-footer-result');
  const footer = sharedRef(undefined, 'usePageLoader-footer-result');

  const { apiTimeout } = $config;

  const getErrorPage = async () => {
    try {
      const page = await fetch(`${$config.mgnlRestPages}/${$domain.countryCode}/Home/404`).then((data) => data.json());

      errorPage.value = page?.area1?.[0]?.content;
    } catch (e) {
      Logger.error('Error fetching 404 content', { error: e.message });
    }
  };

  const fetchContent = async <T>(url: string): Promise<T | undefined> => {
    loading.value = true;
    error.value.content = false;
    try {
      if (process.server) {
        const { data, errors } = await $magnolia.fetchContent({ url, apiTimeout });

        if (errors.length) {
          error.value.content = true;
          error.value.contentStatusCode = 404;
          return undefined;
        }
        result.value = data;
      } else {
        const resp = await fetch(url);

        if (!resp.ok) {
          error.value.content = true;
          error.value.contentStatusCode = resp.status;

          return undefined;
        }

        result.value = await resp.json();
      }
    } catch (err) {
      error.value.content = err.message;
    } finally {
      loading.value = false;
    }

    return result.value;
  };

  const getHeader = async () => {
    try {
      const url = `${$config.mgnlRestPages}${$domain.mgnlRootPath}/header`;

      if (process.server) {
        const { data, errors } = await $magnolia.fetchContent({ url, apiTimeout });

        if (errors.length) {
          error.value.header = true;
          header.value = undefined;
          return undefined;
        }
        header.value = data;
      } else {
        const resp = await fetch(url);

        if (!resp.ok) {
          error.value.header = true;
          header.value = undefined;
          return undefined;
        }

        header.value = await resp.json();
      }
    } catch (err) {
      header.value = undefined;
      error.value.header = true;
    }

    return header.value;
  };
  const getPreFooter = async () => {
    try {
      const url = `${$config.mgnlRestPages}${$domain.mgnlRootPath}/prefooter`;

      if (process.server) {
        const { data, errors } = await $magnolia.fetchContent({ url, apiTimeout });

        if (errors.length) {
          error.value.preFooter = true;
          preFooter.value = undefined;
          return undefined;
        }
        preFooter.value = data;
      } else {
        const resp = await fetch(url);

        if (!resp.ok) {
          error.value.preFooter = true;
          preFooter.value = undefined;
          return undefined;
        }

        preFooter.value = await resp.json();
      }
    } catch (err) {
      preFooter.value = undefined;
      error.value.preFooter = true;
    }

    return preFooter.value;
  };
  const getFooter = async () => {
    try {
      const url = `${$config.mgnlRestPages}${$domain.mgnlRootPath}/footer`;

      if (process.server) {
        const { data, errors } = await $magnolia.fetchContent({ url, apiTimeout });

        if (errors.length) {
          error.value.footer = true;
          footer.value = undefined;
          return undefined;
        }
        footer.value = data;
      } else {
        const resp = await fetch(url);

        if (!resp.ok) {
          error.value.footer = true;
          footer.value = undefined;
          return undefined;
        }

        footer.value = await resp.json();
      }
    } catch (err) {
      footer.value = undefined;
      error.value.footer = true;
    }

    return footer.value;
  };

  return {
    result: computed(() => result.value),
    errorPage: computed(() => errorPage.value),
    error: computed(() => error.value),
    loading: computed(() => loading.value),
    header: computed(() => header.value),
    preFooter: computed(() => preFooter.value),
    footer: computed(() => footer.value),
    getHeader,
    getPreFooter,
    getFooter,
    fetchContent,
    getErrorPage,
  };
};
