import { PaymentInfo } from '@vue-storefront/commercetools-api';
import { Attributes } from '~/components/Product/types/product';

export default {
  attributesForPayment: () => [
    Attributes.BRAND,
    Attributes.HAZARD_NOTICE,
    Attributes.ENERGY_EFFICIENCY_LABEL,
    Attributes.ENERGY_EFFICIENCY_CLASS,
    Attributes.DANGER_ICONS,
    Attributes.SEALS,
    Attributes.DETAIL_NAME,
    Attributes.SIGNAL_WORD,
    Attributes.MANUFACTURER_INFOS,
    Attributes.CAPACITY_UOM,
    Attributes.CAPACITY_QUANTITY,
    Attributes.MATERIAL,
    Attributes.COLOR,
    Attributes.SIZE,
    Attributes.COLOR_CODE,
    Attributes.DISCOUNT_SALE_VALUE,
  ],
  getActivePaymentMethod: (paymentInfo: PaymentInfo) => paymentInfo?.payments?.find((payment) => payment.custom?.customFieldsRaw?.some((field) => field.name === 'isActive' && field.value === true)),
};
