/* eslint-disable import/no-extraneous-dependencies */
import { GetterTree } from 'vuex';

import { State } from './state';

const getters: GetterTree<State, State> = {
  getFlags: ({ flags }) => flags,
  getLastFetchAt: ({ lastFetchAt }) => new Date(lastFetchAt).toLocaleString(),
};

export default getters;
