import { useContext } from '@nuxtjs/composition-api';

import { useAppLocale } from '~/composables/custom/useAppLocale';
import { useMe } from '~/composables/custom/useMe';
import { useUser } from '~/composables/custom/useUser/useUser';

const customerGetters = require('~/composables/getters/customerGetters');

// Guest → so long as he didn’t log in
// NewCustomer → when he registeres new in the profile or during the checkout
// Customer → when he logs in with a current account in the profile or during the checkout
// Employees → when he logs in with a current account in the profile or during the checkout and has the customer group for employees
const CUSTOMER_TYPES = {
  GUEST: 'Guest',
  NEW_CUSTOMER: 'NewCustomer',
  CUSTOMER: 'Customer',
  EMPLOYEES: 'Employees',
};

const calculateBreakpoint = () => {
  const width = window.screen.width || 1024;

  if (width < 768) {
    return 'MOB';
  }
  if (width < 1024) {
    return 'TAB';
  }
  return 'DES';
};

interface TrackingProduct {
  item_id: string;
  currency: string;
  index: number;
  item_category1?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_color: string;
  item_group: string;
  item_ekb: string;
  item_name: string;
  item_product: string; // product key
  price: number;
  quantity?: number;
}

export const useGtm = () => {
  const { isAuthenticated } = useUser();
  const { customerGroupKey, newRegistration } = useMe();
  const { country, isoCode } = useAppLocale();
  const { $gtm } = useContext();

  const gtmTracking = <T extends { event: string }>(data: T) => {
    if (!$gtm) {
      return;
    }

    $gtm.push(data);
  };

  const gtmPageView = () => {
    let customerType = CUSTOMER_TYPES.GUEST;
    if (customerGetters.isKikEmployee({ key: customerGroupKey.value })) {
      customerType = CUSTOMER_TYPES.EMPLOYEES;
    } else if (newRegistration.value) {
      customerType = CUSTOMER_TYPES.NEW_CUSTOMER;
    } else if (isAuthenticated.value) {
      customerType = CUSTOMER_TYPES.CUSTOMER;
    }

    gtmTracking({
      event: 'pageView',
      breakpoint: calculateBreakpoint(),
      country: isoCode,
      customerType,
      language: country,
      pageType: 'ECOM',
      pageType2: 'B2C',
    });
  };

  const gtmProductView = (product: TrackingProduct) => {
    gtmTracking({
      event: 'view_item',
      ecommerce: {
        items: [product],
      },
    });
  };

  const gtmRemoveItem = (product) => {
    gtmTracking({
      event: 'remove_item',
      ecommerce: {
        items: [
          {
            ...product,
            price: (product.price * product.quantity).toFixed(2),
          },
        ],
      },
    });
  };

  const gtmAddToCart = (product: TrackingProduct, variantSku: string) => {
    gtmTracking({
      event: 'add_to_cart',
      ecommerce: {
        items: [
          {
            ...product,
            item_variant: variantSku,
            price: product.price * (product.quantity || 0),
          },
        ],
      },
    });
  };

  return {
    gtmTracking,
    gtmPageView,
    gtmProductView,
    gtmRemoveItem,
    gtmAddToCart,
  };
};
