import { Locale } from '@nuxtjs/i18n';
import { cartGetters } from '@vue-storefront/commercetools';
import { Cart, DiscountedLineItemPriceForQuantity } from '@vue-storefront/commercetools-api';
import { AgnosticTotals } from '@vue-storefront/core';

export default {
  ...cartGetters,
  checkNewsletterSubscription: (cart: Cart) => cart?.custom?.customFieldsRaw?.find(({ name }) => name === 'newsletterRegistration')?.value ?? false,
  getTotals: (cart: Cart) => {
    if (!cart?.lineItems) {
      return {} as AgnosticTotals;
    }

    const calculatePrice = (acc: number, current: DiscountedLineItemPriceForQuantity, target = 'default') => {
      const { quantity = 1, discountedPrice } = current;

      const lineItemDiscount = discountedPrice?.includedDiscounts?.reduce((accDiscount, includedDiscount) => {
        if (target === 'employee') {
          if (includedDiscount?.discount?.key?.toLowerCase().includes('makik')) {
            return accDiscount + includedDiscount.discountedAmount.centAmount;
          }
        } else if (target === 'myKik') {
          if (includedDiscount?.discount?.key?.toLowerCase().includes('mykik')) {
            return accDiscount + includedDiscount.discountedAmount.centAmount;
          }
        } else if (target === 'voucher') {
          if (includedDiscount?.discountedAmount?.centAmount && includedDiscount?.discount.requiresDiscountCode && includedDiscount?.discount?.key !== 'MAKIK' && !includedDiscount?.discount?.key?.toLowerCase().includes('mykik')) {
            return accDiscount + includedDiscount.discountedAmount.centAmount;
          }
        } else if (target === 'cart') {
          if (includedDiscount?.discountedAmount?.centAmount && !includedDiscount?.discount.requiresDiscountCode && includedDiscount?.discount?.key !== 'MAKIK' && !includedDiscount?.discount?.key?.toLowerCase().includes('mykik')) {
            return accDiscount + includedDiscount.discountedAmount.centAmount;
          }
        }
        return accDiscount;
      }, 0);

      if (!lineItemDiscount) {
        return acc;
      }

      return acc + (quantity * lineItemDiscount);
    };

    const { lineItems = [] } = cart;

    const totalEmployeeDiscount = lineItems
      .flatMap(({ discountedPricePerQuantity }) => discountedPricePerQuantity)
      .reduce((acc, current) => calculatePrice(acc, current, 'employee'), 0);

    const totalmyKikDiscount = lineItems
      .flatMap(({ discountedPricePerQuantity }) => discountedPricePerQuantity)
      .reduce((acc, current) => calculatePrice(acc, current, 'myKik'), 0);

    const cartDiscount = lineItems
      .flatMap(({ discountedPricePerQuantity }) => discountedPricePerQuantity)
      .reduce((acc, current) => calculatePrice(acc, current, 'cart'), 0);

    const voucherDiscount = lineItems
      .flatMap(({ discountedPricePerQuantity }) => discountedPricePerQuantity)
      .reduce((acc, current) => calculatePrice(acc, current, 'voucher'), 0);

    const totalStrikePrice = lineItems.reduce((acc, { price, quantity }) => {
      const strikePrice = price?.custom?.customFieldsRaw?.find(({ name }) => name === 'strike_price')?.value.centAmount ?? 0;
      if (!strikePrice) {
        return acc;
      }

      if (price.value.centAmount > strikePrice) {
        return acc;
      }

      const saving = strikePrice - price.value.centAmount;

      return acc + quantity * saving;
    }, 0);

    const totalDeposit = lineItems.reduce((acc, { price, quantity }) => {
      const deposit = price?.custom?.customFieldsRaw?.find(({ name }) => name === 'deposit_price')?.value.centAmount ?? 0;
      if (!deposit) {
        return acc;
      }

      return acc + quantity * deposit;
    }, 0);

    return {
      ...cartGetters.getTotals(cart),
      totalStrikePrice: totalStrikePrice * 10 ** -cart.totalPrice.fractionDigits,
      cartDiscount: cartDiscount * 10 ** -cart.totalPrice.fractionDigits,
      voucherDiscount: voucherDiscount * 10 ** -cart.totalPrice.fractionDigits,
      totalEmployeeDiscount: totalEmployeeDiscount * 10 ** -cart.totalPrice.fractionDigits,
      totalmyKikDiscount: totalmyKikDiscount * 10 ** -cart.totalPrice.fractionDigits,
      totalDeposit: totalDeposit * 10 ** -cart.totalPrice.fractionDigits,
    };
  },
  getDiscounts: (cart: Cart) => cart?.discountCodes,
  getCoupons: (cart: Cart) => cart?.discountCodes?.map(({ discountCode }) => discountCode),
  isActive: (cart: Cart) => cart.cartState === 'Active',
  getItemColor: (item, locale: Locale) => item.variant?.attributesRaw?.find(({ name: attrName }) => attrName === 'color')?.value?.label?.[locale],
  getItemQtyBySku: (cart: Cart, sku: string) => cart?.lineItems?.find(({ variant }) => variant.sku === sku)?.quantity ?? 0,
  isPackstationShipping: (cart: Cart) => cart.shippingInfo?.shippingMethod?.custom?.customFieldsRaw?.some(((customField) => customField.name === 'type' && customField.value === 'packstation')),
  getLoyaltyCouponCode: (cart: Cart) => cart?.custom.customFieldsRaw?.find((loyaltyCouponCode) => loyaltyCouponCode.name === 'loyaltyCouponCode'),
};
