
import Vue from 'vue';
import { RTEBuilder, WrapperProps } from './RTE.model';
import RTE from './RTE.vue';

export default Vue.extend({
  name: 'RTEWrapper',

  components: {
    RTE,
  },
  props: WrapperProps,

  data(): Object {
    return {
      mgnlProps: RTEBuilder.build(this.$props),
    };
  },
});
