import { defineNuxtMiddleware } from '@nuxtjs/composition-api';

import { Logger } from '~/apiExtensions/utils';

export default defineNuxtMiddleware(async ({ app, route, redirect }) => {
  try {
    const isLoggedIn: boolean = await app.$vsf.$ct.api.isLoggedIn();

    if (!isLoggedIn) {
      throw new Error(`"${route.fullPath}" route is only available to logged-in customers`);
    }

    if (route.query.redirectTo) {
      return redirect(route.query.redirectTo as string);
    }

    return undefined;
  } catch (error) {
    Logger.warn(error.toString());
    return redirect(`/?redirectTo=${route.fullPath}`);
  }
});
