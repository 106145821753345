import { computed } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';

import { useCheckout } from '~/composables/custom/useCheckout';
import useCheckoutForm from '~/composables/custom/useCheckoutForm';
import { CheckoutNavigationStatus } from '~/composables/custom/useCheckoutNavigation/useCheckoutNavigation';

export const useShippingNavigation = () => {
  const { updating } = useCheckout();
  const { isVisible } = useCheckoutForm('shipping');
  const shippingInfo = sharedRef(undefined, 'shipping-info');

  const nextState = computed(() => {
    if (updating.value) {
      return CheckoutNavigationStatus.LOADING;
    }

    return (Object.values(shippingInfo.value || {}).length > 1 || isVisible.value) ? CheckoutNavigationStatus.TO_PAYMENT : CheckoutNavigationStatus.MISSING_INFO;
  });

  return {
    nextState,
    resetShipping: () => { shippingInfo.value = undefined; },
    setShippingInfo: (value) => { shippingInfo.value = value; },
  };
};
