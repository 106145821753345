import { computed, useRoute } from '@nuxtjs/composition-api';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Route } from 'vue-router';

export enum ContextViewMode {
  PREVIEW = 'preview',
  EDIT = 'edit',
  STANDALONE = 'standalone',
}

export const getMagnoliaContext = (requestUrl: Route) => {
  const isPreviewMode = requestUrl.query.mgnlPreview;
  if (isPreviewMode === undefined) {
    return ContextViewMode.STANDALONE;
  }
  if (isPreviewMode === 'true') {
    return ContextViewMode.PREVIEW;
  }
  return ContextViewMode.EDIT;
};

export const useMagnolia = () => {
  const route = useRoute();
  const context = getMagnoliaContext(route.value);

  return {
    atMagnolia: computed(() => ([ContextViewMode.EDIT, ContextViewMode.PREVIEW].includes(context))),
    context,
  };
};
