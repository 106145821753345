var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"three-ct-container"},[_c('h2',{style:({ color: _vm.topTitleColor })},[_vm._v("\n    "+_vm._s(_vm.topTitle)+"\n  ")]),_vm._v(" "),_c('p',{style:({ color: _vm.subTextColor })},[_vm._v("\n    "+_vm._s(_vm.subText)+"\n  ")]),_vm._v(" "),_c(_setup.SfCarousel,{staticClass:"three-wrapper-carousel",attrs:{"settings":{
      type: 'carousel',
      perView: 2,
      gap: 20,
      peek: 50,
      breakpoints: {
        767: { perView: 1 }
      },
    }}},_vm._l((_setup.teaserData),function(item,i){return _c('SfCarouselItem',{key:i},[(item.ctaLink )?_c(_setup.TargetLink,{attrs:{"link":item.ctaLink}},[_c('nuxt-img',{attrs:{"src":item.src,"srcset":item.srcSet,"alt":item.title,"loading":_vm.imageLoadType,"preload":_vm.imageLoadType === 'eager',"fetchpriority":_vm.imageLoadType === 'eager' ? 'high' : 'low'}})],1):_vm._e(),_vm._v(" "),_c('section',{staticClass:"three-wrapper__content"},[_c('h2',[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(item.subtitle))]),_vm._v(" "),(item.ctaLink )?_c(_setup.TargetLink,{attrs:{"link":item.ctaLink}},[_c('span',{style:({color: `${item.ctaTextColor}`})},[_vm._v(_vm._s(item.ctaText || _vm.$t('Shop now')))]),_vm._v(" "),_c(_setup.SfIcon,{attrs:{"color":"white","icon":"arrow_right","size":"xs","viewBox":"0 0 24 24"}})],1):_vm._e()],1)],1)}),1),_vm._v(" "),_c('div',{staticClass:"three-wrapper"},_vm._l((_setup.teaserData),function(item,i){return _c('div',{key:i,staticClass:"three-wrapper__item"},[(item.ctaLink )?_c(_setup.TargetLink,{attrs:{"link":item.ctaLink}},[_c('nuxt-img',{attrs:{"src":item.src,"srcset":item.srcSet,"alt":item.title,"loading":_vm.imageLoadType,"preload":_vm.imageLoadType === 'eager',"fetchpriority":_vm.imageLoadType === 'eager' ? 'high' : 'low'}})],1):_vm._e(),_vm._v(" "),_c('section',{staticClass:"three-wrapper__content"},[_c('h2',[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(item.subtitle))]),_vm._v(" "),(item.ctaLink )?_c(_setup.TargetLink,{attrs:{"link":item.ctaLink}},[_c('span',{style:({color: `${item.ctaTextColor}`})},[_vm._v(_vm._s(item.ctaText || _vm.$t('Shop now')))]),_vm._v(" "),_c(_setup.SfIcon,{attrs:{"color":"white","icon":"arrow_right","size":"xs","viewBox":"0 0 24 24"}})],1):_vm._e()],1)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }