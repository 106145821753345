import { ImageCTX, ProviderGetImage } from '@nuxt/image';

export const getImage: ProviderGetImage = (src, config, { options }: ImageCTX) => {
  const { modifiers, preset, imageFormat } = config;
  const { width, height } = modifiers || {};

  if (!preset || !/media.*kik/.test(new URL(src).host)) {
    return {
      url: src,
    };
  }

  let dimensions = `${width}-${height}`;

  if (options.presets[preset]?.modifiers) {
    const { modifiers: presetModifiers } = options.presets[preset];
    if (presetModifiers?.width && presetModifiers?.height) {
      dimensions = [presetModifiers.width, presetModifiers.height].join('-');
    }
  }

  const [, sizeInSrc] = src.match(/(\d*-\d*)\/\w*.\w*/) || [];
  const [imageFormatInSrc] = src.match(/.\w*$/) || [];

  let url = src;

  if (imageFormat.from.map((format) => `.${format}`).includes(imageFormatInSrc)) {
    url = url.replace(imageFormatInSrc, `.${imageFormat.to}`);
  }

  if (sizeInSrc) {
    url = url.replace(sizeInSrc, dimensions);
  }

  return {
    url,
  };
};
