
import {
  PageEditorBridge,
  TemplateAnnotations,
  PersonalizationService,
  EditorContextHelper,
} from '@magnolia/template-annotations';
import { computed } from '@nuxtjs/composition-api';
import { Logger } from '~/apiExtensions/utils';

export type CMSContext = 'preview' | 'edit';

function removeCommentElement(element) {
  if (element && element.nodeType === 8) {
    element.remove();
  }
}

export const removeComments = (node) => {
  if (!node) {
    return;
  }
  removeCommentElement(node.previousSibling);
  removeCommentElement(node.nextSibling);
};

function insertComments(node, commentOpen, commentClose) {
  if (!node || !commentOpen) {
    return;
  }
  node.parentNode?.insertBefore(document.createComment(commentOpen), node);
  node.parentNode?.insertBefore(document.createComment(commentClose), node.nextSibling);
}

function getTemplate(componentMappings, mgnlTemplate) {
  const template = componentMappings[mgnlTemplate];

  if (!template) {
    Logger.warn(`Component with ID ${mgnlTemplate} is not mapped.`);
  }

  return template;
}

export const EditablePage = {
  name: 'EditablePage',
  props: {
    context: {},
    content: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    templateAnnotations: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  provide() {
    return {
      context: computed(() => this.context),
      config: computed(() => this.config),
      templateAnnotations: computed(() => this.templateAnnotations ?? {}),
    };
  },
  computed: {
    renderingContent() {
      const generatedAreasContent = TemplateAnnotations.generateMissingAreas(this.content, this.templateAnnotations);

      return PersonalizationService.getVariant(generatedAreasContent, this.templateAnnotations);
    },
  },
  mounted() {
    PageEditorBridge.init({}, () => null);
    removeComments(this.$el);
    insertComments(this.$el, this.templateAnnotations[this.content['@path']], '/cms:page');
    EditorContextHelper.onFrameReady();
  },
  updated() {
    PageEditorBridge.init({}, () => null);
    removeComments(this.$el);
    insertComments(this.$el, this.templateAnnotations[this.content['@path']], '/cms:page');
    EditorContextHelper.onFrameReady();
  },
  render(createElement) {
    const template = getTemplate(this.config.componentMappings, this.content['mgnl:template']);

    return template ? createElement(template, { props: this.renderingContent }) : null;
  },
};

export const EditableComponent = {
  name: 'EditableComponent',
  inject: ['context', 'config', 'templateAnnotations'],
  props: ['content'],
  computed: {
    renderingContent() {
      const generatedAreasContent = TemplateAnnotations.generateMissingAreas(this.content, this.templateAnnotations);

      return PersonalizationService.getVariant(generatedAreasContent, this.templateAnnotations);
    },
  },
  mounted() {
    if (this.context === 'edit') { insertComments(this.$el, this.templateAnnotations[this.content['@path']], '/cms:component'); }

    EditorContextHelper.onFrameReady();
  },
  updated() {
    if (this.context === 'edit') { insertComments(this.$el, this.templateAnnotations[this.content['@path']], '/cms:component'); }

    EditorContextHelper.onFrameReady();
  },
  render(createElement) {
    const template = getTemplate(this.config.componentMappings, this.content['mgnl:template']);

    return template ? createElement(template, { props: this.renderingContent }) : null;
  },
};

export const EditableArea = {
  name: 'EditableArea',
  components: {
    EditableComponent,
  },
  inject: ['context', 'config', 'templateAnnotations'],
  props: ['content'],
  mounted() {
    if (this.context === 'edit' && this.content) {
      insertComments(this.$el, this.templateAnnotations[this.content['@path']], '/cms:area');
    }

    EditorContextHelper.onFrameReady();
  },
  updated() {
    if (this.context === 'edit' && this.content) {
      insertComments(this.$el, this.templateAnnotations[this.content['@path']], '/cms:area');
    }

    EditorContextHelper.onFrameReady();
  },
  render(createElement) {
    const nodes: any[] = (this.content?.['@nodes']?.map((nodeName) => this.content[nodeName])) || [];

    return createElement(
      'div',
      null,
      nodes.map((node) => createElement(EditableComponent, { props: { key: node['@id'], content: node } }, null)),
    );
  },
};

export default EditablePage;
