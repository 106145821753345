import { computed, ref, Ref } from '@nuxtjs/composition-api';
import { Order } from '@vue-storefront/commercetools-api';

import { useBFF } from '../useBFF/useBFF';

export default function useReturns(orderId: string) {
  const { $ct } = useBFF();

  const loading = ref(false);
  const returns: Ref<Order[]> = ref<Order[]>([]);
  const error = ref(null);

  const RETURN_SHIPMENT_STATES = {
    ADVISED: 'Advised',
    RETURNED: 'Returned',
    BACK_IN_STOCK: 'BackInStock',
    UNUSABLE: 'Unusable',
  };

  const RETURN_PAYMENT_STATES = {
    NOT_REFUNDED: 'NotRefunded',
    INITIAL: 'Initial',
    REFUNDED: 'Refunded',
  };

  const setReturns = async (returnOrders) => {
    returns.value = returnOrders;
  };

  const search = async () => {
    loading.value = true;
    error.value = null;
    try {
      const { errors, data } = await $ct.getMyReturns<Order[]>();
      if (errors?.length) {
        throw new Error(errors.join('. '));
      }
      returns.value = data ?? [];
    } catch (e) {
      error.value = e.message;
      returns.value = [];
    } finally {
      loading.value = false;
    }
  };

  const create = async (orderNumber, { lineItems }) => {
    loading.value = true;
    error.value = null;

    try {
      const { errors, data } = await $ct.addReturnInformation<Order[]>({
        lineItems,
        orderId,
        orderNumber,
      });

      if (errors.length) {
        throw new Error(errors.join('. '));
      }
      returns.value = data;
    } catch (e) {
      error.value = e.message;
      returns.value = [];
    } finally {
      loading.value = false;
    }
  };

  const cancel = async ({ orderNumber, returnTrackingId }) => {
    loading.value = true;
    error.value = null;

    try {
      const { errors } = await $ct.cancelReturn({
        orderNumber, orderId, returnTrackingId,
      });
      if (errors.length) {
        throw new Error(errors.join('. '));
      }
    } catch (e) {
      error.value = e.message;
    } finally {
      loading.value = false;
    }
  };

  return {
    RETURN_SHIPMENT_STATES,
    RETURN_PAYMENT_STATES,
    loading,
    returns: computed(() => returns.value),
    error,
    search,
    create,
    cancel,
    setReturns,
  };
}
