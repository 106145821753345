import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { MetaInfo } from 'vue-meta/types';

export default defineNuxtPlugin((({ app, $domain }) => {
  if (!$domain.trboScript) {
    return;
  }

  (app.head as MetaInfo).script.push({
    src: $domain.trboScript,
    'data-usercentrics': 'trbo',
    type: 'text/plain',
    defer: true,
    async: true,
  });
}));
