import { useCart as useCartCore } from '@vue-storefront/commercetools';
import { computed, ref, shallowRef } from '@nuxtjs/composition-api';
import { Cart } from '@vue-storefront/commercetools-api';

import { Logger } from '~/apiExtensions/utils';
import useUiNotification, { UiNotification } from '~/composables/useUiNotification';
import { useBFF } from '~/composables/useBFF/useBFF';

import { cartGetters } from '~/composables/getters';

const ERROR_MESSAGES = {
  MULTIPLE_COUPONS: 'You can not use more than 1 coupon code',
  INVALID_CODE: 'This code is invalid.',
  PROBLEM_REMOVING_COUPON: 'Something wrong happened when removing coupon',
};
interface UseExtendedCartErrors {
  applyOrRemoveCoupon: string | undefined;
  updateItemsInCart: string | undefined;
  deleteActiveCarts?: string;
  createCart?: string;
  addItem?: string;
  setNewletter?: string;
}

const loading = ref(false);
const updating = ref(false);

export const useCart = () => {
  const { $ct } = useBFF();
  const {
    cart,
    load,
    loading: cartLoading,
    setCart: setCartCore,
    error: cartError,
  } = useCartCore();
  const { send } = useUiNotification();

  const setCart = async (data: Cart | null, validateIfEmpty = true) => {
    if (!data && validateIfEmpty) {
      await refresh();
      return;
    }
    setCartCore(data);
  };

  const error = shallowRef<UseExtendedCartErrors>(
    {
      applyOrRemoveCoupon: undefined,
      updateItemsInCart: undefined,
      deleteActiveCarts: undefined,
      createCart: undefined,
      addItem: undefined,
      setNewletter: undefined,
    },
  );

  const discountCodes = computed(() => cartGetters.getCoupons(cart.value as Cart));
  const loyaltyCouponCode = computed(() => cartGetters.getLoyaltyCouponCode(cart.value as Cart));

  const refresh = async () => {
    loading.value = true;

    try {
      if (cart.value) {
        const { data, errors } = await $ct.checkCartVersion(cart.value.id);
        if (errors?.length) {
          throw new Error(errors.join('. '));
        }

        if (data?.version === cart.value.version) {
          return;
        }
      }

      const { data: profileData, errors = [] } = await $ct.getMe({ customer: false });

      if (errors.length) {
        throw new Error(errors.join('. '));
      }

      if (profileData?.me?.activeCart) {
        await setCart(profileData.me.activeCart, false);
      }
    } catch {
      send({
        message: 'Problems validating the current cart\'s version',
        type: 'danger',
      });
    } finally {
      loading.value = false;
    }
  };

  const applyCoupon = async (couponCode: string) => {
    if (couponCode === discountCodes.value?.[0]?.code || couponCode === loyaltyCouponCode?.value?.value) {
      return;
    }

    try {
      if (discountCodes.value?.length > 0 || loyaltyCouponCode?.value) {
        send({
          message: ERROR_MESSAGES.MULTIPLE_COUPONS,
          type: 'warning',
        });
        throw new Error(ERROR_MESSAGES.MULTIPLE_COUPONS);
      }

      updating.value = true;
      error.value.applyOrRemoveCoupon = undefined;
      const { data, errors } = await $ct.applyCoupon({
        id: cart.value.id, version: cart.value.version, code: couponCode,
      });

      if (errors.length) {
        send({
          message: errors.at(-1),
          type: 'danger',
        });
        throw new Error(errors.join('. '));
      }

      await setCart(data?.cart);
    } catch (err) {
      error.value.applyOrRemoveCoupon = err.message;
    } finally {
      updating.value = false;
    }
  };

  const removeCoupon = async (couponCode: string) => {
    const discountId = discountCodes.value.find((discountCode) => discountCode?.code === couponCode)?.id;
    if (!discountId && loyaltyCouponCode.value?.value !== couponCode) {
      return;
    }

    updating.value = true;
    if (couponCode === loyaltyCouponCode.value?.value) {
      try {
        error.value.applyOrRemoveCoupon = undefined;
        const { data, errors = [] } = await $ct.removeLoyaltyCoupon<{ cart: Cart }>({
          id: cart.value.id, version: cart.value.version,
        });
        if (errors.length) {
          throw new Error(errors.join('. '));
        }
        await setCart(data?.cart);
      } catch (err) {
        error.value.applyOrRemoveCoupon = err.message;
        send({
          message: ERROR_MESSAGES.PROBLEM_REMOVING_COUPON,
          type: 'danger',
        });
      } finally {
        updating.value = false;
      }
    } else {
      try {
        error.value.applyOrRemoveCoupon = undefined;
        const { data, errors = [] } = await $ct.removeCoupon<{ cart: Cart }>({
          id: cart.value.id, version: cart.value.version, discountId,
        });
        if (errors.length) {
          throw new Error(errors.join('. '));
        }

        await setCart(data?.cart);
      } catch (err) {
        error.value.applyOrRemoveCoupon = err.message;
        send({
          message: ERROR_MESSAGES.PROBLEM_REMOVING_COUPON,
          type: 'danger',
        });
      } finally {
        updating.value = false;
      }
    }
  };

  const setNewsletterState = async (newsletterState: boolean) => {
    updating.value = true;
    try {
      const { data, errors: apiErrors = [] } = await $ct.setNewsletterState({ id: cart.value.id, version: cart.value.version, newsletterState });

      if (apiErrors.length) {
        throw new Error(apiErrors.join('. '));
      }

      await setCart({ ...cart.value, ...data?.cart });
    } catch (err) {
      error.value.setNewletter = err.message;
      send({
        message: 'An error occured',
        type: 'danger',
      } as UiNotification);
    } finally {
      updating.value = false;
    }
  };

  const removeItem = async (lineItemId: string) => {
    updating.value = true;
    try {
      const { data, errors } = await $ct.removeItem<{ cart: Cart}>({ id: cart.value.id, version: cart.value.version, lineItemId });

      if (errors.length) {
        throw new Error(errors.join('. '));
      }

      await setCart(data?.cart);
    } catch (err) {
      error.value.updateItemsInCart = err.message;
      send({
        message: 'An error occured',
        type: 'danger',
      } as UiNotification);
    } finally {
      updating.value = false;
    }
  };

  const updateQty = async (lineItemId: string, quantity: number) => {
    updating.value = true;
    try {
      const { data, errors = [] } = await $ct.updateQty<{ cart: Cart }>({
        id: cart.value.id, version: cart.value.version, lineItemId, quantity,
      });

      if (errors.length) {
        throw new Error(errors.join('. '));
      }

      await setCart(data?.cart);
    } catch (err) {
      error.value.updateItemsInCart = err.message;
      send({
        message: 'An error occured',
        type: 'danger',
      } as UiNotification);
    } finally {
      updating.value = false;
    }
  };

  const deleteActiveCarts = async () => {
    updating.value = true;
    error.value.deleteActiveCarts = undefined;
    try {
      const { errors } = await $ct.deleteActiveCarts();

      if (errors.length) {
        throw new Error(errors.join('. '));
      }

      await setCart(null, false);
    } catch (err) {
      error.value.deleteActiveCarts = err.message;
      Logger.error(error.value.deleteActiveCarts);
    } finally {
      updating.value = false;
    }
  };

  const addItem = async ({ breadcrumbs = [], ...params }) => {
    updating.value = true;

    try {
      if (!cart.value) {
        error.value.createCart = undefined;
        const { data, errors } = await $ct.createCart();

        if (errors?.length) {
          error.value.createCart = errors.join();
          return;
        }

        await setCart(data?.cart, false);
      }

      await refresh();

      const { data, errors = [] } = await $ct.addToCart<{ cart: Cart }>({
        ...params,
        cart: {
          id: cart.value?.id,
          version: cart.value?.version,
        },
        breadcrumbs: breadcrumbs.map(({ text }) => text).join(' / '),
      });

      if (errors.length) {
        error.value.addItem = errors.join();
      }

      setCart(data?.cart, false);
    } finally {
      updating.value = false;
    }
  };

  return {
    cart: computed(() => cart.value as Cart),
    loading: computed(() => loading.value || cartLoading.value),
    error: computed(() => ({ ...cartError?.value, ...error?.value })),
    updating: computed(() => updating.value),
    deleteActiveCarts,
    addItem,
    load,
    refresh,
    setCart,
    updateQty,
    removeItem,
    applyCoupon,
    removeCoupon,
    setNewsletterState,
  };
};
