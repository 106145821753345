import { useContext, useRoute } from '@nuxtjs/composition-api';

import { useAppLocale } from '~/composables/custom/useAppLocale';
import { urlGetters } from '~/composables/getters';
import { NavbarCategory } from '../custom/useNavbarCategories';

const nonFilters = ['page', 'sort', 'phrase', 'itemsPerPage'];

const reduceFilters = (query) => (prev, curr) => {
  const makeArray = Array.isArray(query[curr]) || nonFilters.includes(curr);

  return {
    ...prev,
    [curr]: makeArray ? query[curr] : [query[curr]],
  };
};

const getFiltersDataFromUrl = (query, onlyFilters: boolean) => Object.keys(query)
  .filter((f) => (onlyFilters ? !nonFilters.includes(f) : nonFilters.includes(f)))
  .reduce(reduceFilters(query), {});

export default () => {
  const route = useRoute();
  const { country } = useAppLocale();
  const { i18n } = useContext();

  const getFacetsFromURL = () => {
    const { query, params } = route.value;
    const categorySlug = Object.keys(params).reduce((prev, curr) => params[curr] || prev, params.slug_1);

    return {
      rootCatSlug: params.slug_1,
      categorySlug,
      page: parseInt(query.page as string, 10) || 1,
      sort: query.sort || 'latest',
      filters: getFiltersDataFromUrl(query, true),
      itemsPerPage: parseInt(query.itemsPerPage as string, 10) || 20,
      phrase: query.phrase,
    };
  };

  const getCatLink = (categories: NavbarCategory[] = []): string => {
    const searchParams = getFacetsFromURL()?.filters?.search?.[0] ?? null;
    // @ts-ignore
    const slugs = categories.filter(Boolean).map(({ slug, ignoreRoot }) => {
      if (ignoreRoot) {
        return undefined;
      }
      return slug;
    }).filter(Boolean).join('/');

    let categoryPath = `${urlGetters.getCategoryNavigationByMeta(route.value.meta, i18n)}/${slugs}`;
    categoryPath += searchParams ? `?search=${searchParams}` : '';
    return categoryPath;
  };

  const getMagnoliaLink = (link: string) => {
    const pathToReplace = `/${country}/Home`;
    return link === pathToReplace ? '/' : link.replace(pathToReplace, '');
  };

  return {
    getFacetsFromURL,
    getCatLink,
    getMagnoliaLink,
  };
};
