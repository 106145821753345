import { Customer } from '@vue-storefront/commercetools-api';

import { calculateAge } from '~/helpers/utils';

export default {
  isLoyaltyApplicationEligible: (user: Customer) => {
    const userAge = calculateAge(new Date(user?.dateOfBirth));
    return {
      addressValid: (user && user?.billingAddresses.length > 0),
      ageValid: (user?.dateOfBirth && !Number.isNaN(userAge) && userAge >= 18),
    };
  },
};
