import { computed, ref, useContext } from '@nuxtjs/composition-api';
import { vsfRef } from '@vue-storefront/core';

import { Logger } from '~/apiExtensions/utils';
import { Attributes, ProductTeaser } from '~/components/Product/types/product';
import { useBFF } from '~/composables/useBFF/useBFF';

const MAX_ITEMS_PER_CAROUSEL_DESKTOP = process.client ? 20 : 6;
const MAX_ITEMS_PER_CAROUSEL_MOBILE = process.client ? 20 : 2;

export const useTeaserSlider = (category: { categoryId: string }) => {
  const { $device } = useContext();
  const { $ct } = useBFF();

  const MAX_ITEMS_PER_CAROUSEL = $device.isDesktop ? MAX_ITEMS_PER_CAROUSEL_DESKTOP : MAX_ITEMS_PER_CAROUSEL_MOBILE;

  const loading = ref(false);
  const products = vsfRef<ProductTeaser[]>([], `slider-teaser-products-${category.categoryId}`);

  const fetchProducts = async () => {
    loading.value = true;

    try {
      const { data, errors: apiErrors } = await $ct.getProducts({
        categories: [category.categoryId],
        attributeFilterNames: [
          Attributes.COLOR_CODE,
          Attributes.DETAIL_NAME,
          Attributes.COLOR,
          Attributes.SIZE,
          Attributes.SIZE_ORDER,
          Attributes.DISCOUNT_SALE_VALUE,
        ],
        withVariants: false,
        limit: MAX_ITEMS_PER_CAROUSEL,
      });

      if (apiErrors.length) {
        throw new Error(apiErrors.join('. '));
      }

      products.value = data.items ?? [];
    } catch (err) {
      Logger.error('error fetching product list ', err.message);
    } finally {
      loading.value = false;
    }
  };

  return {
    loading: computed(() => loading.value),
    products: computed(() => products.value),

    fetchProducts,
  };
};
