import {
  computed, ref, useContext, watch,
} from '@nuxtjs/composition-api';
import { useUser } from '@vue-storefront/commercetools';
import { useBFF } from '~/composables/useBFF/useBFF';
import useUiNotification from '~/composables/useUiNotification';

const wishlist = ref(null);
export const useWishlist = () => {
  const { $ct } = useBFF();
  const { isAuthenticated } = useUser();
  const { $flags } = useContext();
  const { send } = useUiNotification();
  const queueForWishlist = ref(null);
  const load = async () => {
    if (!wishlist.value && isAuthenticated.value && $flags.hasnt('enabledKiosk') && $flags.has('shoppinglist.wishlist')) {
      const { data, errors } = await $ct.getShoppingList();

      if (errors?.length) {
        throw new Error();
      }

      if (data?.shoppingList) {
        wishlist.value = data?.shoppingList;
      } else {
        const newShoppingList = await $ct.createShoppingList();
        wishlist.value = newShoppingList.data?.shoppingList;
      }
    }
  };

  const addToWishlist = async (product) => {
    const { id, version } = wishlist.value;
    const { sku, custom } = product;
    const { data, errors } = await $ct.addToShoppingList({
      shoppingListId: id, shoppingListVersion: version, sku, custom,
    });

    if (data?.shoppingList) {
      wishlist.value = data.shoppingList;

      send({
        message: 'addToWishlistMessage',
        type: 'success',
        icon: 'heart_fill',
      });
    }

    if (errors?.length) {
      send({
        message: 'An error occured',
        type: 'danger',
      });
      throw new Error();
    }
  };

  const removeFromWishlist = async (product) => {
    const lineItemId = getCurrentProduct(product)?.id;
    await removeLineItemFromWishlist(lineItemId);
  };

  const removeLineItemFromWishlist = async (lineItemId) => {
    const { id, version } = wishlist.value;
    const { data, errors } = await $ct.removeFromShoppingList({ shoppingListId: id, shoppingListVersion: version, lineItemId });

    if (data?.shoppingList) {
      wishlist.value = data.shoppingList;

      send({
        message: 'removeFromWishlistMessage',
        type: 'success',
        icon: 'heart',
      });
    }

    if (errors?.length) {
      send({
        message: 'An error occured',
        type: 'danger',
      });
      throw new Error();
    }
  };
  const toggleToWishlist = async (product, onlyAddToWishlist = false) => {
    if (isAuthenticated.value) {
      const productInWishlist = getCurrentProduct(product);
      if (productInWishlist && !onlyAddToWishlist) {
        await removeLineItemFromWishlist(productInWishlist.id);
      } else {
        await addToWishlist(product);
      }
    }
  };

  const getCurrentProduct = (product) => {
    let currentColor: string;
    if (product.variants) {
      currentColor = product.variants?.find((variant) => variant.sku === product.sku)?.attributes?.color;
    } else {
      currentColor = product?.attributes?.color;
    }
    return wishlist.value?.lineItems.find((lineItem) => lineItem.productId === product.id && lineItem.variant.attributes?.color === currentColor);
  };

  const isInWishlist = (product) => !!getCurrentProduct(product);
  const clearWishlist = () => { wishlist.value = null; };
  const addToQueue = (product) => { queueForWishlist.value = product; };

  watch(isAuthenticated, async () => {
    if (queueForWishlist.value) {
      await load();
      await toggleToWishlist(queueForWishlist.value, true);
      queueForWishlist.value = null;
    }
  });

  return {
    load,
    addToQueue,
    wishlist: computed(() => wishlist.value),
    wishlistTotalItems: computed(() => wishlist.value?.lineItems?.length ?? 0),
    toggleToWishlist,
    addToWishlist,
    removeFromWishlist,
    isInWishlist,
    clearWishlist,
  };
};
