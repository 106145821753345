import { render, staticRenderFns } from "./BrandImageWrapper.vue?vue&type=template&id=24229e5e&scoped=true&"
import script from "./BrandImageWrapper.vue?vue&type=script&lang=ts&setup=true&"
export * from "./BrandImageWrapper.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./BrandImageWrapper.vue?vue&type=style&index=0&id=24229e5e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24229e5e",
  null
  
)

export default component.exports