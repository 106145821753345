import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { MetaInfo } from 'vue-meta/types';

export default defineNuxtPlugin((({ app, $config, $domain }) => {
  if ($config.usercentricsId) {
    const [lang] = $domain.locales[0].split('-');
    (app.head as MetaInfo).script.push({
      'data-version': 'preview',
      'data-tcf-enabled': true,
      'data-settings-id': $config.usercentricsId,
      'data-language': lang,
      'data-avoid-prefetch-services': '',
      defer: true,
      async: true,
      id: 'usercentrics-cmp',
      src: 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
    });
  }
}));
