/* eslint-disable no-unused-expressions */
const getPaths = (nestedObj: any[], key: string, cb) => {
  if (!nestedObj) {
    return [];
  }

  const paths = [];
  const parentPath = [];

  return (function deepCheck(items) {
    if (!items?.length) {
      return [];
    }

    Array.isArray(items)
      && items.forEach((item) => {
        parentPath.push(item);
        if (cb(item)) {
          paths.push([...parentPath]);
        }

        if (item[key]) {
          deepCheck(item[key]);
        }
        parentPath.pop();
      });

    return paths[0];
  }(nestedObj));
};

export interface CategoryBreadCrumbs {
  count: number,
  id: string,
  isCurrent: boolean,
  items: CategoryBreadCrumbs[],
  slug: string;
  name: string;
  slugAllLocales: any;
  canonicalUrl: string;
  label: string;
  metaTitle: string;
  metaDescription: string;
  headlineTitle: string;
  categoryDescription: string;
  isHidden: boolean;
  saleMetadata: {
      metaTitle?: string;
      metaDescription?: string;
      headline?: string;
      description?: string;
  };
  redirectionRules: {
      statusCode: number;
      redirectUrl: string;
  };
}

export default (categoryTree: any[], property, value): CategoryBreadCrumbs[] => {
  if (!categoryTree) {
    return [];
  }
  if (!value) {
    return categoryTree;
  }

  return getPaths(categoryTree, 'childrenItems', (cat) => cat[property] === value);
};
