import { productGetters } from '@vue-storefront/commercetools';
import { Money, ProductVariant } from '@vue-storefront/commercetools-api';
import { findAttr } from '~/helpers/utils';
import { Attributes, TAttributes } from '~/components/Product/types/product';

export default {
  ...productGetters,
  getFormattedPrice: ({ centAmount, fractionDigits = 2 }: Pick<Money, 'centAmount' | 'fractionDigits'> = { centAmount: 0, fractionDigits: 2 } as Money) => centAmount * 10 ** -fractionDigits,
  getName: (item) => item?.variant?.name ?? item?.name,
  /**
   * @deprecated
   */
  getUrl: (item, { locale }) => {
    if (!item) {
      return '';
    }
    const { name, variant, productKey } = item;

    const detailName = findAttr(variant?.attributesRaw, 'detailname')?.[locale];
    const colorName = findAttr(variant?.attributesRaw, 'color')?.label?.[locale];
    const colorCode = findAttr(variant?.attributesRaw, 'color_code');

    const path = [name, detailName, colorName, colorCode]
      .filter(Boolean)
      .map((urlPart) => `${urlPart}`.trim().split(' '));

    return `/p/${encodeURIComponent(path.join('-'))}/${productKey}`;
  },
  getMaxAvailability: (item: ProductVariant) => {
    const maxQty = 100;
    const stock = item?.availability?.noChannel?.availableQuantity || 0;
    return Math.min(stock, maxQty);
  },
  /**
   * after all refactors we will start always using this getter to calculate the product url
   * @param item
   */
  getProductUrl: (product, locale, queryParams = {}) => {
    if (!product) {
      return '';
    }

    const {
      key,
      hrefLang,
    } = product;

    let url = `/p/${encodeURIComponent(hrefLang[locale])}/${key}`;

    if (Object.keys(queryParams).length) {
      url += `?${new URLSearchParams(queryParams)}`;
    }

    return url;
  },
  getProductUrlByVariant: (product, locale, queryParams = {}) => {
    if (!product) {
      return '';
    }

    const {
      variant,
      key,
    } = product;

    let url = `/p/${encodeURIComponent(variant.hrefLang[locale])}/${key}`;

    if (Object.keys(queryParams).length) {
      url += `?${new URLSearchParams(queryParams)}`;
    }

    return url;
  },
  getEfficiencyIconUrl: (attributes: TAttributes) => {
    const { energy_efficiency_class } = attributes || {};

    if (!energy_efficiency_class) {
      return undefined;
    }

    return `/productpage/efficiency/${energy_efficiency_class}_right.png`;
  },
  getTrackingProduct: (product, currency, breadcrumbsToDisplay, quantity) => ({
    item_id: product?.key ?? '',
    currency,
    index: 0,
    ...breadcrumbsToDisplay,
    item_color: product?.attributes?.[Attributes.COLOR] ?? '',
    item_group: product?.attributes?.[Attributes.WGR] ?? '',
    item_ekb: product?.attributes?.[Attributes.EKB] ?? '',
    item_name: product?.name ?? '',
    item_product: product?.key ?? '', // product key
    price: product?.price?.final,
    quantity,
  }),

  getTrackingItem: (product, price, locale) => ({
    item_id: product?.productKey ?? '',
    currency: product?.price.value.currencyCode ?? '',
    index: 0,
    item_color: product?.variant?.attributesRaw?.find((attribute) => attribute.name === Attributes.COLOR)?.value.label[locale] ?? '',
    item_group: product?.variant?.attributesRaw?.find((attribute) => attribute.name === Attributes.WGR).value ?? '',
    item_ekb: product?.variant?.attributesRaw?.find((attribute) => attribute.name === Attributes.EKB).value ?? '',
    item_name: product?.name ?? '',
    item_product: product?.productKey ?? '', // product key
    item_variant: product?.variant.sku ?? '',
    price,
    quantity: product?.quantity,
  }),
};
