<template>
  <transition :name="transitionName">
    <div
      v-show="visible && !closeMenu"
      class="sf-mega-menu"
      :class="[{ 'is-active': active }, { 'is-absolute': isAbsolute }]"
    >
      <SfBar
        :title="active || title"
        :back="showBack"
        :close="showClose"
        class="sf-mega-menu__bar"
        @click:back="back()"
        @click:close="close()"
      />
      <div class="sf-mega-menu__content">
        <div class="sf-mega-menu__menu">
          <!-- @slot Slot for menu column -->
          <slot />
        </div>
        <div
          v-if="$slots.aside"
          class="sf-mega-menu__aside"
        >
          <!-- @slot @deprecated will be removed in 1.0.0 -->
          <SfMenuItem
            :label="asideTitle"
            class="sf-mega-menu__aside-header desktop-only"
          />
          <slot name="aside" />
        </div>
        <slot name="gallery" />
      </div>
    </div>
  </transition>
</template>
<script>
import Vue from 'vue';
import { SfBar } from '@storefront-ui/vue';
import SfMegaMenuColumn from './_internal/SfMegaMenuColumn.vue';
import SfMenuItem from '../../molecules/SfMenuItem/SfMenuItem';
import {
  mapMobileObserver,
  unMapMobileObserver,
} from '../../../utilities/mobile-observer';

Vue.component('SfMegaMenuColumn', SfMegaMenuColumn);
export default {
  name: 'SfMegaMenu',
  components: {
    SfBar,
    SfMenuItem,
  },
  provide() {
    const megaMenu = {};
    Object.defineProperty(megaMenu, 'active', {
      get: () => this.active,
    });
    Object.defineProperty(megaMenu, 'changeActive', {
      value: this.changeActive,
    });
    return { megaMenu };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    isAbsolute: {
      type: Boolean,
      default: false,
    },
    transitionName: {
      type: String,
      default: 'sf-fade',
    },
    showBack: {
      type:Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    /**
     * @deprecated will be removed in 1.0.0
     */
    asideTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeHierarchy: [],
      active: "",
      closeMenu: false
    };
  },
  computed: {
    ...mapMobileObserver(),
  },
  watch: {
    isMobile: {
      handler() {
        this.active = "";
        this.activeHierarchy = [];
      },
      immediate: true,
    },
  },
  beforeUnmount() {
    unMapMobileObserver();
  },
  methods: {
    changeActive(payload) {
      this.activeHierarchy.push(this.active);
      this.active = payload;
      this.$emit('change', payload);
    },
    back() {
      this.active = this.activeHierarchy.length ? this.activeHierarchy.slice(-1)[0] : "";
      this.$emit("back", { level: this.activeHierarchy.length });
      this.activeHierarchy.pop();
    },
    close() {
      this.active = "";
      this.activeHierarchy = [];
      this.$emit("close");
    }
  },
};
</script>
<style lang="scss">
@import "storefrontUI/styles/styles/components/organisms/SfMegaMenu.scss";
</style>
