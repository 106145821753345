const getSrcSetImages = (image, mgnlImagesPath) => {
  if (!image?.renditions) {
    return [];
  }

  const { large, medium, small } = image.renditions;

  return [{ src: `${mgnlImagesPath}${small.link}`, width: '480w' }, { src: `${mgnlImagesPath}${medium.link}`, width: '720w' }, { src: `${mgnlImagesPath}${large.link}`, width: '1366w' }];
};

export default {
  getLink: (url: string | { '@path': string }) => {
    const path = typeof url === 'string' ? url : url?.['@path'];

    return path?.replace(/^\/[A-Z]{2,}\/(Home)/, '') || '/';
  },
  getImageByDevice: (image, device, defaultImg: string, mgnlImagesPath: string) => {
    if (!image?.renditions) {
      return defaultImg;
    }

    const { large, small } = image.renditions;

    if (device.isMobile) {
      return `${mgnlImagesPath}${small.link}`;
    }

    return `${mgnlImagesPath}${large.link}`;
  },
  getDefaultSrcAsset: (asset, mgnlImagesPath: string): string => {
    if (!asset?.['@link']) {
      // Can't resort to '/' since nuxt-img will try to load the full document
      // eslint-disable-next-line global-require
      return require('~/assets/img/error/error.svg');
    }

    return `${mgnlImagesPath}${asset['@link']}`;
  },
  getSrcSetImages,
  getSrcSetImagesAsString: (image, mgnlImagesPath: string): string => {
    if (!image?.metadata) {
      return '';
    }
    const images = getSrcSetImages(image, mgnlImagesPath);

    if (!images.length) {
      return '';
    }

    return images?.map(({ src, width }) => `${src} ${width}`).join(',');
  },
  getAltImage: (image, alt: string) => {
    if (alt) {
      return alt;
    }
    return image?.metadata?.caption ? image?.metadata?.caption : '';
  },
  getImageDimensions: (image) => ({
    width: image?.metadata?.width,
    height: image?.metadata?.height,
  }),
  getTitleImage: (image, title: string) => {
    if (title) {
      return title;
    }
    return image?.metadata?.title ?? '';
  },
  getControlsList: (mgnlProps: any) => {
    let list = '';
    if (!mgnlProps.enableFullscreen) {
      list += 'nofullscreen ';
    }
    if (!mgnlProps.enableDownload) {
      list += 'nodownload ';
    }
    return list;
  },
  getLinkAndName: (link, mgnlPath: string) => {
    let name;
    let url;
    let type;
    const switchable = link?.switchable;
    if (switchable) {
      const {
        page, external, download, field,
      } = switchable;

      type = field;

      switch (field) {
        case 'page':
          url = page['@path'];
          name = link.title ?? page.navigationTitle;
          break;
        case 'download':
          url = `${mgnlPath}${download['@link']}`;
          name = link.title;
          break;
        default:
          url = external;
          name = link.title ?? external;
      }
    }

    if (link.showCtaAsPdf && link.pdfLinkInDam) {
      url = `${mgnlPath}${link.pdfLinkInDam['@link']}`;
      type = 'external';
    }

    return {
      url: url?.replace(/^\/[A-Z]{2,}\/(Home)/, '') || '/',
      name,
      type,
      target: link?.linkTargetMode ?? '_blank',
      emphasis: link?.emphasis ?? undefined,
      showLinkKiosk: link?.showLinkKiosk ?? true,
      showLinkCatalog: link?.showLinkCatalog ?? true,
    };
  },
  getExtendedLinkAndName: (link, mgnlPath: string) => {
    let mode;
    let url;
    let type;
    const switchable = link?.switchable;
    const switchableMode = link?.switchableMode;
    if (switchable) {
      const {
        page, external, download, field,
      } = switchable;

      type = field;

      switch (field) {
        case 'page':
          url = page['@path'];
          break;
        case 'download':
          url = `${mgnlPath}${download['@link']}`;
          break;
        default:
          url = external;
      }
    }

    if (switchableMode) {
      const {
        image, title, field,
      } = switchableMode;

      switch (field) {
        case 'title':
          mode = {
            type: 'title',
            title,
          };
          break;
        case 'image':
          mode = {
            type: 'image',
            src: image?.['@link'] ? `${mgnlPath}${image['@link']}` : undefined,
            title: switchableMode?.imageTitle ?? '',
            alt: switchableMode?.alt ?? '',
          };
          break;
        default:
          mode = {
            type: 'title',
            title: '',
          };
      }
    }

    return {
      url: url?.replace(/^\/[A-Z]{2,}\/(Home)/, '') || '/',
      mode,
      type,
      target: link?.linkTargetMode ?? '_blank',
    };
  },
  showKioskOrCatalog: ($flags, kioskProp: boolean, catalogProp: boolean) => ((($flags.hasnt('enabledKiosk') || ($flags.has('enabledKiosk') && kioskProp))) && ($flags.hasnt('shop.catalog.enabled') || ($flags.has('shop.catalog.enabled') && catalogProp))),
  // Workaround necessary, Magnolia has no OOB solution to resolve RTE links with multisite module, for more info check the helpdesk support history: https://jira.magnolia-cms.com/servicedesk/customer/portal/6/HELPDESK-5461
  resolveRichTextContentAssetLinks: (content, host = '') => content?.replaceAll('="/author/', `="${host}/author/`).replaceAll('="/dam/', '="/cms/dam/'),
  getHexColor: (color: number) => (color ? (`#${(16777216 + color)?.toString(16)?.padStart(6, '0')}`) : '#222'),
  migratePageLinkToCtaLink: (mgnlProps, props?: any) => {
    if (props) {
      // eslint-disable-next-line no-param-reassign
      mgnlProps.ctaLink = [{
        pdfLinkInDam: props.pdfLinkInDam ?? undefined,
        showCtaAsPdf: props.showCtaAsPdf,
        linkTargetMode: props.ctaTargetMode,
        ctaText: props.ctaText,
        switchable: {
          field: props.pageLink?.['@path'] ? 'page' : 'external',
          external: props.pageLink,
          page: {
            '@path': props.pageLink?.['@path'],
          },
        },
      }];
    } else {
      mgnlProps.slides?.forEach((slide) => {
        if (slide.pageLink && !slide.ctaLink) {
          // eslint-disable-next-line no-param-reassign
          slide.ctaLink = [{
            pdfLinkInDam: slide.pdfLinkInDam ?? undefined,
            showCtaAsPdf: slide.showCtaAsPdf,
            linkTargetMode: slide.linkTargetMode,
            ctaText: slide.ctaText ?? '',
            switchable: {
              field: slide.pageLink?.['@path'] ? 'page' : 'external',
              external: slide.pageLink,
              page: {
                '@path': slide.pageLink?.['@path'],
              },
            },
          }];
        }
      });
    }
  },
};
