
import Vue from 'vue';
import { ParagraphBuilder, WrapperProps } from './Paragraph.model';
import Paragraph from './Paragraph.vue';

export default Vue.extend({
  name: 'ParagraphWrapper',

  components: {
    Paragraph,
  },
  props: WrapperProps,

  data(): Object {
    return {
      mgnlProps: ParagraphBuilder.build(this.$props),
    };
  },
});
