import { sharedRef } from '@vue-storefront/core';
import { useUser } from '@vue-storefront/commercetools';
import { computed } from '@nuxtjs/composition-api';
import { v4 as uuidV4 } from 'uuid';

import { useCart } from '~/composables/custom/useCart/useCart';
import useUiNotification from '~/composables/useUiNotification';
import { useAppLocale } from '~/composables/custom/useAppLocale';
import { useTracking } from '~/composables/custom/useTracking';

import { useBFF } from '~/composables/useBFF/useBFF';

const { addressGetters } = require('~/composables/getters/addressGetters');

export const useCheckout = () => {
  const errors = sharedRef(null, 'errors-in-checkout');
  const updating = sharedRef(false, 'updating-user-data-in-checkout');

  const { user, isAuthenticated, setUser } = useUser();
  const { cart, setCart, refresh } = useCart();
  const { $ct } = useBFF();
  const { send } = useUiNotification();
  const { country } = useAppLocale();
  const { trackError } = useTracking();

  async function setData(data) {
    if (!data.cart) {
      await refresh();
    }

    if (data?.cart) {
      await setCart({
        ...cart.value,
        ...data?.cart,
      });
    }

    if (data?.customer) {
      setUser({
        ...user.value,
        ...data.customer,
      });
    }
  }

  const getAddressWithDefault = (address) => {
    const toAddress = addressGetters.transformToFormAddress(address);

    return {
      ...toAddress,
      key: toAddress.key || uuidV4(),
      country: toAddress.country || country,
      email: toAddress.email || user.value.email,
      streetName: toAddress.streetName,
      streetNumber: toAddress.streetNumber,
      additionalStreetInfo: toAddress.additionalStreetInfo || '',
      // Packstation custom fields
      packstationNumber: toAddress.packstationNumber,
      packstationPostNumber: toAddress.packstationPostNumber,
      pickupLocationInformation: toAddress.pickupLocationInformation || '',
    };
  };

  const saveBillingStep = async ({ address, isShippingSame, isNewAddress }: { address: any, isShippingSame: boolean, isNewAddress: boolean }) => {
    updating.value = true;

    try {
      if (!address) {
        errors.value = 'cant continue. no billing address was defined.';
        return;
      }

      errors.value = undefined;

      const { errors: apiErrors, data } = await $ct.saveCheckoutBillingInfo({
        address: getAddressWithDefault(address),
        userVersion: user.value?.version,
        cartId: cart.value?.id,
        cartVersion: cart.value?.version,
        shipToBilling: isShippingSame,
        isNewAddress: isNewAddress && isAuthenticated.value,
      });

      if (apiErrors.length) {
        errors.value = 'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method or later.';

        trackError(errors.value);
        send({
          message: 'An error occured',
          type: 'danger',
        });
      }

      await setData(data);
    } finally {
      updating.value = false;
    }
  };

  const saveShippingStep = async ({ address, isNewAddress }: { address: any, isNewAddress: boolean }) => {
    updating.value = true;
    try {
      if (!address) {
        errors.value = 'cant continue. no shipping address was defined.';
        return;
      }

      errors.value = undefined;

      const { errors: apiErrors, data } = await $ct.saveCheckoutShippingInfo({
        address: getAddressWithDefault(address),
        userVersion: user.value?.version,
        cartId: cart.value?.id,
        cartVersion: cart.value?.version,
        isNewAddress: isNewAddress && isAuthenticated.value,
      });

      await setData(data);

      if (apiErrors?.length) {
        errors.value = 'There was some error while saving your shipping address. We are sorry, please try again later';
        trackError(errors.value);
        send({
          message: 'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method or later',
          type: 'danger',
        });
      }
    } finally {
      updating.value = false;
    }
  };

  return {
    errors: computed(() => errors.value),
    updating: computed(() => updating.value),
    resetErrors: () => { errors.value = undefined; },
    saveBillingStep,
    saveShippingStep,
  };
};
