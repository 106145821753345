import { orderGetters } from '@vue-storefront/commercetools';
import { Order } from '@vue-storefront/commercetools-api';
import productGetters from './productGetters';

export default {
  ...orderGetters,
  getDate: (order: Order) => order?.completedAt ?? order?.createdAt ?? '',
  getShippingTrackingURL: (order: Order) => (order?.shippingInfo?.deliveries?.[0]?.parcels?.[0] as any)?.custom?.customFieldsRaw?.find(({ name }) => name === 'trackingUrl')?.value,
  getShippingDescription: (order: Order) => order?.shippingInfo?.shippingMethod?.localizedDescription ?? order?.shippingInfo?.shippingMethodName,
  getFormattedPrice: productGetters.getFormattedPrice,
};
