import { defineNuxtPlugin } from '@nuxtjs/composition-api';

// This handles a page reload if the user has cleared cookies after initial page rendering
// else registration, login and add-to-cart will not work.
export default defineNuxtPlugin(({ app, $cookies }) => {
  app.router?.beforeEach((to, from, next) => {
    const hasNoCookies = Object.keys($cookies.getAll()).length === 0;
    const isNavigating = from.path !== to.path;
    if (hasNoCookies && isNavigating) {
      window.location.href = to.path;
    }

    next();
  });
});
