<template>
  <div
    :class="{ 'is-active': active === title }"
    class="sf-mega-menu-column"
  >
    <slot
      name="title"
      v-bind="{ title, changeActive: megaMenu.changeActive }"
    >
      <SfMenuItem
        :label="title"
        class="sf-mega-menu-column__header"
        @click="megaMenu.changeActive(title)"
      >
        <template #mobile-nav-icon>
          <img v-if="icon" :src="icon" :alt="title">
        </template>
      </SfMenuItem>
    </slot>
    <div class="sf-mega-menu-column__content">
      <slot />
      <slot name="gallery" />
    </div>
  </div>
</template>
<script>
import SfMenuItem from '../../../molecules/SfMenuItem/SfMenuItem.vue';

export default {
  name: 'SfMegaMenuColumn',
  components: {
    SfMenuItem,
  },
  inject: ['megaMenu'],
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
  computed: {
    active() {
      return this.megaMenu.active;
    },
  },
};
</script>
