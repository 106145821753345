import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import {
  VSF_COUNTRY_COOKIE,
  VSF_CURRENCY_COOKIE,
  VSF_LOCALE_COOKIE,
  VSF_STORE_COOKIE,
} from '@vue-storefront/core';

import { Logger } from '~/apiExtensions/utils';

export default defineNuxtPlugin(async ({
  $config, req, app, route, $cookies,
}, inject) => {
  try {
    const hostname = process.server ? req.headers.host : window.location.host;
    if (!hostname) {
      throw new Error('Was not possible to determine the request\'s host');
    }

    const domain = $config.countryCodes.find((element: { host: string }) => element.host.includes(hostname));

    if (!domain) {
      throw new Error(`Host "${hostname}" could not be mapped to a countryCode. Please check the countryCodes section in the nuxt config file.`);
    }

    $cookies.set(VSF_COUNTRY_COOKIE, domain.countryCode);
    $cookies.set(VSF_LOCALE_COOKIE, domain.locales[0]);
    $cookies.set(VSF_STORE_COOKIE, domain.store);
    $cookies.set(VSF_CURRENCY_COOKIE, domain.currency);

    const authoringMode = route.query.mgnlPreview !== undefined;

    if (!domain.locales.includes(app.i18n.locale) && !authoringMode) {
      await app.i18n.setLocale(domain.locales[0]);
    }

    inject('domain', domain);
  } catch (err) {
    Logger.error('Failed to check domain.', err);
  }
});
