import Vue from 'vue'
import { createImage} from '~image'
import NuxtImg from '~image/components/nuxt-img.vue'
import NuxtPicture from '~image/components/nuxt-picture.vue'

import * as staticRuntime$8b3b from '/builds/shop22/kikstorefront/node_modules/@nuxt/image/dist/runtime/providers/static.js'
import * as kikRuntime$1ff1 from '/builds/shop22/kikstorefront/providers/kik.ts'

const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "teaser": {
      "modifiers": {
        "format": "jpg",
        "width": 225,
        "height": 269
      }
    },
    "plp": {
      "modifiers": {
        "format": "jpg",
        "width": 297,
        "height": 355
      }
    },
    "pdp": {
      "modifiers": {
        "format": "jpg",
        "width": 617,
        "height": 738
      }
    },
    "pdpZoom": {
      "modifiers": {
        "format": "jpg",
        "width": 834,
        "height": 1024
      }
    },
    "pdpThumb": {
      "modifiers": {
        "format": "jpg",
        "width": 74,
        "height": 89
      }
    },
    "cartPreview": {
      "modifiers": {
        "format": "jpg",
        "width": 146,
        "height": 182
      }
    }
  },
  "provider": "kik",
  "domains": [],
  "alias": {}
}

imageOptions.providers = {
  ['static']: { provider: staticRuntime$8b3b, defaults: {} },
  ['kik']: { provider: kikRuntime$1ff1, defaults: {"imageFormat":{"from":["jpeg","jpg","png"],"to":"webp"}} }
}

Vue.component(NuxtImg.name, NuxtImg)
Vue.component(NuxtPicture.name, NuxtPicture)
Vue.component('NImg', NuxtImg)
Vue.component('NPicture', NuxtPicture)

export default function (nuxtContext, inject) {
  const $img = createImage(imageOptions, nuxtContext)

  if (process.static && process.server) {
    nuxtContext.beforeNuxtRender(({ nuxtState }) => {
      const ssrData = nuxtState.data[0] || {}
      ssrData._img = nuxtState._img || {}
    })
  }

  inject('img', $img)
}
